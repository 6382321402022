import React, {useEffect } from "react";
import moment from 'moment'

import { renderLotteryHTMLToJSX } from "../../../helpers";
import { handleOnclickStatisticalTableCell, showStatisticalByFilterNumberSelector } from "../helpers";



export function TableLotteryPairFrequency(props) {
    const {Statistical, filterParams, setFilterParamsPopup, handleOpenPopup, filterNumberData} = props


    useEffect(()=>{
        if(Statistical) {
            // Xử lý sự kiện onClick mở popup detail lottery:
            handleOnclickStatisticalTableCell();
        }
    },[Statistical])

    useEffect(()=>{
        if(filterNumberData) {
            // update table statistical on filter number
            showStatisticalByFilterNumberSelector(filterNumberData);

            // test
            // const rowElementArray = document.querySelectorAll('.box-statistical-lottery table tbody tr');
            // let array = []
            // if(rowElementArray && rowElementArray.length > 0) {
            //     rowElementArray.forEach(item => {
            //         array.push(item.firstChild.innerText)
            //     });
            // }
        }
    },[filterNumberData])
    
    
    return (
        <div className="scroll" className="box-statistical-lottery control-handle-click">
            {Statistical && renderLotteryHTMLToJSX(Statistical.content)}
        </div>
    );
}
