import React, { useState } from "react";
import { PROVINCE_OPTIONS } from "../../../helpers/constans";

/**
 * Mô tả lý do kiểm tra trường valueType === 'id':
 * Với 2 trang: Thống kê tần suất loto theo Đặc Biệt, Thống kê theo chu kỳ: select lấy giá trị "id" gán vào trường "location_id" của filterParams.
 * Các trang thống kê khác: select lấy giá trị "value" gán vào trường "code" của filterParams.
*/

export default function SelectProvince(props) {
    
	const {selectedValue, setSelectedValue, valueType} = props

	function handleChangeSelect(event){
		setSelectedValue(event.target.value)
	}

	// function handleChangeSelect(event){
	// 	try {
	// 		const value = event.target.value
	// 		// let filterParamsTemp = {...filterParams}
	// 		// if(valueType && valueType === 'id') {
	// 		// 	filterParamsTemp = {...filterParamsTemp, location_id: value}
	// 		// } else {
	// 		// 	filterParamsTemp = {...filterParamsTemp, code: value}
	// 		// }
	// 		// setFilterParams(filterParamsTemp)
	// 		setSelectedValue(value);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }
	
	
	return (
		<>
			<select name="code" id="code" className="form-control" value={selectedValue} onChange={(e)=>handleChangeSelect(e)}>
				{PROVINCE_OPTIONS&&PROVINCE_OPTIONS.map((item, i)=>{
					return (
						<option key={i} value={valueType&&valueType === 'id' ? item.id : item.value}>
							{item.name}
						</option>
					)
				})}
			</select>
		</>
	);
}

