import React from "react";
import LotteryTrial from "./LotteryTrial";
import LotteryRealTime from "./LotteryRealTime";
import Lottery from "./Lottery";

import { getStatistical } from "../helpers/statistical";
import Statistical from "./Statistical";
// import LotteryFrequency from "./statistics/LotteryFrequency";


export function LotteryRoute(props) {
    const {match} = props
    
    const renderLottery = () => {
        let result;
        const slug = match.params.id;

        // check & load page statistical
        const statistical = getStatistical(slug);
        if(statistical) {
            result = <Statistical currentStatistical={statistical} />
            // result = <LotteryFrequency />
            // result = statistical.pageComponent; // load page component tương ứng, vd: <LotteryFrequency /> ...
            
        } else {
            // check & load other page
            if(slug.indexOf('quay-thu') !== -1) {
                result = <LotteryTrial />
            } else if(slug.indexOf('truc-tiep') !== -1) {
                result = <LotteryRealTime />
            } else {
                result = <Lottery />
            }
        }
        
        return result;
    }
    return (
        renderLottery()
    )
}