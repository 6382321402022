import moment from 'moment';

import React, { useEffect, useState } from "react";
import { DatePickerField } from '../commons/DatePickerField';
import GroupNumberPlusMinus from '../commons/GroupNumberPlusMinus';
import SelectProvince from '../commons/SelectProvince';

// const toDay = moment(Date.now()).format('DD/MM/YYYY');
// const toDayFilter = moment(Date.now()).format('DD-MM-YYYY');



export default function FormFilterLotteryFrequency(props) {
    
	const {formValue, setFormValue} = props.props

	const [selectedValue, setSelectedValue] = useState(formValue.code);
	
	const [valuePlusMinus, setValuePlusMinus] = useState(formValue.count)
	const [date, setDate] = useState(Date.now()); // Biên độ ngày


	useEffect(()=>{
		if(selectedValue && valuePlusMinus && date) {
			setFormValue({
				...formValue, 
				code: selectedValue, 
				count: valuePlusMinus, 
				date: moment(date).format('DD-MM-YYYY') 
			});
		}
	},[selectedValue, valuePlusMinus, date])
	
	
	return (
		<>
			
			<div className="form-group col-sm-3 daudong">
				<label htmlFor="code">Tỉnh</label>
				<SelectProvince selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
			</div>
			<div className="form-group col-sm-3 daudong">
				<label htmlFor="date">Biên độ ngày</label>
				<DatePickerField date={date} setDate={setDate} />
			</div>
			<div className="form-group col-sm-3 daudong">
				<label htmlFor="count">Số ngày muốn xem (Max= 1000 ngày)</label>
				<GroupNumberPlusMinus valuePlusMinus={valuePlusMinus} setValuePlusMinus={setValuePlusMinus} />
			</div>
		</>
	);
}

