import moment from 'moment';

import React, { useEffect, useState } from "react";
import { DatePickerField } from '../commons/DatePickerField';
import SelectProvince from '../commons/SelectProvince';

// const toDay = moment(Date.now()).format('DD/MM/YYYY');
// const toDayFilter = moment(Date.now()).format('DD-MM-YYYY');



export default function FormFilterLotteryNear(props) {
	const {formValue, setFormValue} = props.props

	const [selectedValue, setSelectedValue] = useState(formValue.code);
	const [beginDate, setBeginDate] = useState(new Date(2020,0,1)); // default: 01-01-2020
	const [endDate, setEndDate] = useState(Date.now());
	const [ganValue, setGanValue] = useState(10);


	useEffect(()=>{
		if(selectedValue && beginDate && endDate && ganValue) {
			setFormValue({
				...formValue, 
				code: selectedValue, 
				begin_date: moment(beginDate).format('DD-MM-YYYY'), 
				end_date: moment(endDate).format('DD-MM-YYYY'), 
				day_count: parseInt(ganValue)
			});
		}
	},[selectedValue, beginDate, endDate, ganValue])
	
	
	return (
		<>
			
			<div className="form-group daudong">
				<label htmlFor="code">Tỉnh</label>
				<div>
					<SelectProvince selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
				</div>
			</div>
			<div className="form-group daudong">
				<label htmlFor="date">Từ ngày</label>
				<DatePickerField date={beginDate} setDate={setBeginDate} />
			</div>
			<div className="form-group daudong">
				<label htmlFor="date">Đến ngày</label>
				<DatePickerField date={endDate} setDate={setEndDate} />
			</div>
			<div className="form-group daudong">
				<label htmlFor="count">Biên độ gan</label>
				<div>
					<input type="number" placeholder="Biên độ gan" value={ganValue} onChange={(e)=> setGanValue(e.target.value)} />
				</div>
			</div>
		</>
	);
}