
import qs from 'query-string'
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory  } from "react-router";

import { fetchStatisticalByFilterParamsRequest } from '../../actions/Statistical'
// import { fetchProvinceRequest } from "../../actions/DataHelpers";
// import { CreateParamsforApi } from "../../helpers";
// import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../../helpers/check";
// import { getStatistical } from '../../helpers/statistical';
import { LotterySpecial } from '../../components/statistical/table-statistical/LotterySpecial';
import { PopupStatisticalDetail } from '../../components/statistical/popup/PopupStatisticalDetail';
import { fetchStatisticalDetailRequest } from '../../actions/StatisticalDetail';
// import FormFilter from '../../components/statistical/filter/form/FormFilter';
import SelectProvince from '../../components/statistical/filter/form/commons/SelectProvince';
import FilterNumberSet from '../../components/statistical/filter/form/FilterNumberSet';
import { DATA_FILTER_ALL_NUMBER_SET, PROVINCE_OPTIONS } from '../../components/statistical/helpers/constans';
import { fetchProvinceRequest } from '../../actions/DataHelpers';


const LotterySpecialContainer = ({
	DataProvince,
	GetProvince,
	Statistical,
	ShowStatistical,
	currentStatistical,

	StatisticalDetail,
	GetStatisticalDetail,
}) => {
	// const history = useHistory()
	// const searchQuery = useLocation()


	const [openPopup, setOpenPopup] = useState(false);
	// const [filterParamsPopup, setFilterParamsPopup] = useState({ code: 'mb', date: '31-08-2021' });
	const [filterParamsPopup, setFilterParamsPopup] = useState(null);

	
	
	// let searchObject = createFilterFormParams(searchQuery)
	// const searchLottery = CreateParamsforApi(filterParams)
	
	// const [searchLottery, setSearchLottery] = useState(()=>CreateParamsforApi(filterParams));

	// const locationIdDefault = getLocationIdDefault(filterParams.domain_id, today_weekday);
	// const locationId = findLocationIdFromPathName(searchQuery.pathname, filterProvince(DataProvince));

	const [filterNumberData, setFilterNumberData] = useState(DATA_FILTER_ALL_NUMBER_SET) // set data để filter bảng Statistical lottery


	// const filterParams = {lottery_crawl_type_id: 6, location_id: 5};
	const filterParamsInit = { lottery_crawl_type_id: currentStatistical.lottery_crawl_type_id, location_id: PROVINCE_OPTIONS[0].id };
	// const filterParamsInit = { lottery_crawl_type_id: currentStatistical.lottery_crawl_type_id, location_id: 5 }; // Gán tạm location_id: 5
	const [filterParams, setFilterParams] = useState(filterParamsInit);

	const [selectedValue, setSelectedValue] = useState(filterParamsInit.location_id);

	useEffect(()=>{
		GetProvince()
	},[])

	useEffect(()=>{
		if(selectedValue) {
			setFilterParams({...filterParams, location_id: parseInt(selectedValue)})
			setFilterNumberData(DATA_FILTER_ALL_NUMBER_SET)
		}
	},[selectedValue])

	useEffect(()=>{
		if(filterParams) {
			ShowStatistical(filterParams)
		}
	},[filterParams])
	
	useEffect(()=>{
		if(filterParamsPopup) {
			GetStatisticalDetail(filterParamsPopup)
		}
	},[filterParamsPopup])

	
	// control popup
	function handleOpenPopup(){
		setOpenPopup(true)
	}
	function handleClosePopup(){
		setOpenPopup(false)
	}

	return (
		<>
			<div className="kqbackground vien">
				<div className="panel panel-default">
					<div className="panel-heading center">
						<h4 className="right-menu-title">
							Thống kê Loto hay về theo ĐB
						</h4>
					</div>
					<div className="panel-body">
						<form className="form-horizontal" id="chuky_form">
							<div className="form-group">
								<label htmlFor="code" className="col-sm-2 control-label">Tỉnh</label>
								<div className="col-sm-3">
									<SelectProvince selectedValue={selectedValue} setSelectedValue={setSelectedValue} valueType={'id'} />
								</div>
								<div className="col-sm-3">
								{/* <button type="button" className="btn btn-primary daudong" onClick={handleSubmit}>
									<i className="fa fa-arrow-right" /> Chọn tỉnh
								</button> */}
								</div>
							</div>
							

							{/* filter Bộ số */}
							<FilterNumberSet
								title="Bộ số đặc biệt muốn xem"
								filterNumberData={filterNumberData}
								setFilterNumberData={setFilterNumberData}
								isReset={selectedValue}
							/>

							<p className="chu15 daudong vietnghieng" style={{ paddingTop: 5 }}>
								<span className="maudo"> Hướng dẫn</span>: B1 - Chọn tỉnh. =&gt; B2 -
								Chọn nhanh bộ số muốn xem (<span className="maudo">KHÔNG</span> cần bấm
								Enter).
							</p>
						</form>
					</div>
				</div>

				<h3>
					{/* <p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>Thống kê loto theo Đặc Biệt {PROVINCE_OPTIONS.find(item=>item.id === filterParams.location_id)?.name || ''}, bộ số 86</p> */}
					<p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>Thống kê loto theo Đặc Biệt {PROVINCE_OPTIONS.find(item=>item.id === filterParams.location_id)?.name || ''}</p>
				</h3>
				
				<LotterySpecial
					Statistical={Statistical} 
					filterParams={filterParams} 
					filterNumberData={filterNumberData}
					
					setFilterParamsPopup={setFilterParamsPopup} 
					handleOpenPopup={handleOpenPopup}
				/>

				{ openPopup && 
					<PopupStatisticalDetail 
						StatisticalDetail={StatisticalDetail} 
						filterParamsPopup={filterParamsPopup} 
						openPopup={openPopup} 
						handleClosePopup={handleClosePopup} 
					/> 
				}
			</div>
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
		Statistical: state.Statistical,
		StatisticalDetail: state.StatisticalDetail,
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:(filterParams) =>{
			dispatch(fetchStatisticalByFilterParamsRequest(filterParams))
		},
		GetStatisticalDetail:(filterParamsPopup) =>{
			dispatch(fetchStatisticalDetailRequest(filterParamsPopup))
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(LotterySpecialContainer);

