import qs, { parseUrl } from 'query-string'
import moment from 'moment'
import configureStore from '../redux/configureStore';
import { SCHEDULE } from '../constants/Schedule';

// get DataProvince from store
const store = configureStore();
const state = store.getState();
const DataProvince = filterProvince(state.DataProvince);




export const createFilterFormParams = (searchQuery) =>{    
    let result
    try {
        const searchObject = qs.parse(searchQuery.search)
        let todate = new Date()
        
        let pathNameParam;
        let weekday;

        if(searchObject.weekday) {
            weekday = searchObject.weekday;

        } else if(searchObject.days){
            const currentDate = new Date(searchObject.years,searchObject.months-1,searchObject.days);
            weekday = currentDate.getDay();

        } else {
            weekday = todate.getDay();
        }

        pathNameParam = splitPathName(searchQuery.pathname, weekday);
 
        if(searchObject.weekday){

            result = {
                // domain_id: pathNameParam.domain_id,
                // location_id: pathNameParam.location_id,
    
                // domain_id:searchObject.domain_id?searchObject.domain_id:'1',
                // location_id:searchObject.location_id?searchObject.location_id:'25',
                days:moment().day(searchObject.weekday)._d.getDate(),
                months:moment().day(searchObject.weekday)._d.getMonth()+1,
                years:moment().day(searchObject.weekday)._d.getFullYear(),
                pathName:searchQuery.pathname
            }
        } else {
            result = {
                // domain_id: pathNameParam.domain_id,
                // location_id: pathNameParam.location_id,
    
                // domain_id:searchObject.domain_id?searchObject.domain_id:'1',
                // location_id:searchObject.location_id?searchObject.location_id:'25',
                days:searchObject.days?Number(searchObject.days):Number(todate.getDate()),
                months:searchObject.months?Number(searchObject.months):Number(todate.getMonth()+1),
                years:searchObject.years?Number(searchObject.years):Number(todate.getFullYear()),
                pathName:searchQuery.pathname
            }
        }

        result = {
            ...result,
            domain_id: pathNameParam.domain_id,
            location_id: pathNameParam.location_id,
        }


    } catch (error) {
        console.log(error);
        
    }

    return result 
}

export const createUrlTo = (filterParams) =>{
    let result
    
    try {
        const urlDate = new Date(filterParams.years,filterParams.months,filterParams.days);
        const weekday = urlDate.getDay();

        let pathNameParam = splitPathName(filterParams.pathName, weekday)
        filterParams = {...filterParams, pathNameParam};


        let options = {
            // domain_id:'domain_id=' + filterParams.domain_id + '&',
            // location_id:'location_id='+ filterParams.location_id + '&',
            days:'days='+ filterParams.days + '&',
            months:'months='+ filterParams.months + '&',
            years:'years='+ filterParams.years,
            pathName:filterParams.pathName
            // pathName:'/'
        }
        // result = `${options.pathName}?${options.domain_id}${options.location_id}${options.days}${options.months}${options.years}` 
        result = `${options.pathName}?${options.days}${options.months}${options.years}` 
    } catch (error) {
        console.log(error);
    }
    
    return result
}


export const createNewFilterParams = (filterParams) =>{
    let result
    try {
        if(filterParams.days) {
            const currentDate = new Date(filterParams.years,filterParams.months-1,filterParams.days);
    
            const newDate = Number(currentDate.getDate())-1;
            const newMonth = Number(currentDate.getMonth());
            const newYear = Number(currentDate.getFullYear());
            const toDate = new Date(newYear, newMonth, newDate);
            
            const weekday = toDate.getDay();
        
            let pathNameParam = splitPathName(filterParams.pathName, weekday)
            filterParams = {...filterParams, pathNameParam};
        
            result = {
                ...filterParams,
                days:toDate.getDate(),
                months: toDate.getMonth()+1,
                years:toDate.getFullYear()
            }
        }

    } catch (error) {
        console.log(error);
    }
    
    return result
}

export const createNewFilterParamsLastWeek = (filterParams) =>{
    let result
    try {
        if(filterParams.days) {
            const currentDate = new Date(filterParams.years,filterParams.months-1,filterParams.days);
    
            const newDate = Number(currentDate.getDate())-7;
            const newMonth = Number(currentDate.getMonth());
            const newYear = Number(currentDate.getFullYear());
            const toDate = new Date(newYear, newMonth, newDate);
            
            const weekday = toDate.getDay();
        
            let pathNameParam = splitPathName(filterParams.pathName, weekday)
            filterParams = {...filterParams, pathNameParam};
        
            result = {
                ...filterParams,
                days:toDate.getDate(),
                months: toDate.getMonth()+1,
                years:toDate.getFullYear()
            }
        }

    } catch (error) {
        console.log(error);
    }
    
    return result
}


export function splitPathName(pathName, weekday) {
    let result = {domain_id: 1, location_id: 25};
    try {
        if(pathName.indexOf('truc-tiep') !== -1) {
            result = findParamsFromPathName(pathName, DataProvince, weekday);
        } else if(pathName.indexOf('quay-thu') === -1) { // if not page "quay-thu" -> default load page "xo-so"
            result = findParamsFromPathName(pathName, DataProvince, weekday);
        }

    } catch (error) {
        console.log(error);
    }

    return result;
}

// support for function splitPathName()
function findParamsFromPathName(pathName, DataProvince, weekday) {
    let result = {domain_id: 1, location_id: 25};
    let hasProvince = false;
    // let hasArea = false;
    try {
        // find province
        DataProvince.some(area=>{
            if(hasProvince) {
                return true
            } else {
                area.Province.some(e=>{
                    if(pathName.indexOf(e.slug) !== -1){
                        result = {domain_id: e.parent_id, location_id: e.id}
                        hasProvince = true
                        return true
                    }
                })
            }
        })
    
        // if not province, find area and set default province
        if(!hasProvince) {
            DataProvince.some(area=>{
                if(pathName.indexOf(area.slug) !== -1){
                    result = {domain_id: area.id}  

                    let areaSchedule = SCHEDULE.find(e=>e.domain_id === area.id);
                    if(areaSchedule) {
                        if(!weekday) {
                            const today = new Date();
                            weekday = today.getDay();
                        }
                        
                        areaSchedule.Schedule.some(e=>{
                            if(parseInt(e.weekday) === parseInt(weekday)) {
                                result = {...result, location_id: e.location_ids[0]};
                            }
                        })
                    }
                                  
                    return true
                }
            })
        }

        // if(!hasArea) {
        //     result = {domain_id: 1, location_id: SCHEDULE.find(e=>e.domain_id === 1).Schedule[0].location_ids[0]}
        // }

    } catch (error) {
        console.log(error);
    }

    return result;
}

export function filterProvince(DataProvince) {
    let DataProvinceTemp = [
        {id: 1, slug: 'mien-bac', location_id_default: 25, location_slug_default: 'ha-noi', Province: []},
        {id: 2, slug: 'mien-trung', location_id_default: 44, location_slug_default: 'khanh-hoa', Province: []},
        {id: 3, slug: 'mien-nam', location_id_default: 7, location_slug_default: 'thanh-pho-ho-chi-minh', Province: []},
    ]
    try {
        DataProvince.map(e=>{
            switch (e.parent_id) {
                case 1: DataProvinceTemp[0].Province.push(e);
                    break;
                case 2: DataProvinceTemp[1].Province.push(e);
                    break;
                case 3: DataProvinceTemp[2].Province.push(e);
                    break;
                default: 
                    break;
            }
        })
        
    } catch (error) {
        console.log(error);
    }
    
    return DataProvinceTemp;
}

export function findLocationIdFromPathName(pathName, DataProvince) {
    let location_id;
    try {
        // find province
        DataProvince.some(area=>{
            if(location_id) {
                return true
            } else {
                area.Province.some(e=>{
                    if(pathName.indexOf(e.slug) !== -1){
                        location_id = e.id;
                        return true
                    }
                })
            }
        })

    } catch (error) {
        console.log(error);
    }

    return location_id;
}

export function findProvinceFromPathName(pathName, DataProvince) {
    let province;
    try {
        // find province
        DataProvince.some(area=>{
            if(province) {
                return true
            } else {
                area.Province.some(e=>{
                    if(pathName.indexOf(e.slug) !== -1){
                        province = e;
                        return true
                    }
                })
            }
        })

    } catch (error) {
        console.log(error);
    }

    return province;
}