import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function TrialTab({DataProvince, filterParams, setFilterParams}) {

  const [activeTab, setActiveTab] = useState(1);
  
  function handleChangeArea(value) {
    setFilterParams({...filterParams, domain_id: value})
    setActiveTab(value);
  }
	return (
    <>
      <ul className="tab-panel tab-auto">
        {DataProvince&&DataProvince.map((e,index)=>{
          return (
            <li 
              key={index}
              className={activeTab === e.id ? "active" : ""} 
              onClick={()=>handleChangeArea(e.id)}
              >
              <Link to={filterParams.pathName + "?mien=" + e.id}>Quay thử {e.short_name}</Link>
            </li>
          )
        })}
      </ul>
      
      {/* <div className="tit-mien clearfix">
        <h2>Quay thử ... ngày {today}</h2>
      </div> */}
    </>
  )
}