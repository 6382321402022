import React, { useEffect, useState } from "react";




export default function LotteryWeekday({
	location
}) {
    let weekdays = [
        {'name':'Thứ 2','slug':'thu-2'},
        {'name':'Thứ 3','slug':'thu-3'},
        {'name':'Thứ 4','slug':'thu-4'},
        {'name':'Thứ 5','slug':'thu-5'},
        {'name':'Thứ 6','slug':'thu-6'},
        {'name':'Thứ 7','slug':'thu-7'},
        {'name':'Chủ Nhật','slug':'Chu-nhat'},
    ];
    let slugify = (str) =>{
        str = str.toLowerCase();     
 
        // xóa dấu
        str = str
            .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
            .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
     
        // Thay ký tự đĐ
        str = str.replace(/[đĐ]/g, 'd');
        
        // Xóa ký tự đặc biệt
        str = str.replace(/([^0-9a-z-\s])/g, '');
     
        // Xóa khoảng trắng thay bằng ký tự -
        str = str.replace(/(\s+)/g, '-');
        
        // Xóa ký tự - liên tiếp
        str = str.replace(/-+/g, '-');
     
        // xóa phần dư - ở đầu & cuối
        str = str.replace(/^-+|-+$/g, '');

        let result = str;
        return result;
    }
    let checkClassLoading = () =>{
		let result;
		if(weekdays.length>0){
            result = weekdays.map((weekday,index)=>{
                let slugWeekday = `/ket-qua-xo-so-${slugify(location)}/${weekday.slug}`;
                return (
                    <li>
                        <a href={slugWeekday}>
                            {weekday.name}
                        </a>
                    </li>
                )
            })
        }
		return result 
	}
	return (
        <>
        
        <div className="lottery-weekday">
            <ul>
                {checkClassLoading()}
            </ul>
        </div>
        </>
        
	);
}