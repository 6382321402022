import * as lotteryApi from '../apis/Lottery';
import * as lotteryConstants from './../constants/Lottery';
import * as loadingConstants from './../constants/Loading';

const API = 'lottery_crawl'; // + "&filter={"lottery_crawl_type_id": 6, "location_id": 5}"
// const API_ENDPOINT = 'fields=["name","lottery_crawl_type_id","content","location_id"]&limit=1'; // + "&filter={"lottery_crawl_type_id": 6, "location_id": 5}"
// const API_ENDPOINT = 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&limit=1'; // + "&filter={"lottery_crawl_type_id": 6, "location_id": 5}"



// Statistical
export const fetchStatisticalSuccess = (data, filterParams) => {
	return {
		type: lotteryConstants.FETCH_STATISTICAL_SUCCESS,
		filterParams: filterParams || null,
		payload: {
			data
		}
	}
}
export const fetchStatisticalFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_STATISTICAL_FAILED,
		payload: {
			error
		}
	}
}

export const fetchStatisticalRequest = () => {
	// let params = 'lottery_crawl/1' // 1. Thống kê trang chủ
	
	let params = 'lottery_crawl/1' 
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const { data } = resp;
			dispatch(fetchStatisticalSuccess(data));
		}).catch(error => {
			dispatch(fetchStatisticalFailed(error));
		});
	}
}


// fetchStatistical by lottery_crawl_type_id
export const fetchStatisticalByTypeIdRequest = (id, filterParams) => {
	// let params = 'lottery_crawl/1' // 1. Thống kê trang chủ
	// let params = 'lottery_crawl/3' // 3. Thống kê tần suất cặp loto
	
	let params = API + '/' + id;
	if(id === 3 && filterParams) {
		params += `?code=${filterParams.code}&count=${filterParams.count}&date=${filterParams.date}`
	}
	
    
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const { data } = resp;

			dispatch(fetchStatisticalSuccess(data));
		}).catch(error => {
			dispatch(fetchStatisticalFailed(error));
		});
	}
}


/*
* Tần suất loto LotteryFrequency: viết riêng request vì cấu trúc data trả về khác nhau.
* Api mẫu: 'lottery_crawl/tan-suat-loto?code=mb&count=55&date=11-8-2021' // 2. Tần xuất lotto
*/ 
export const fetchStatisticalLotteryFrequencyRequest = (filterParams) => {
	let paramString = `tan-suat-loto?code=${filterParams.code}&count=${filterParams.count}&date=${filterParams.date}`;
	let params = API + '/' + paramString;
    
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const data = resp;

			dispatch(fetchStatisticalSuccess(data));
		}).catch(error => {
			dispatch(fetchStatisticalFailed(error));
		});
	}
}

/*
* Thống kê loto gan LotteryNear
* Api mẫu: 'lottery_crawl/loto-gan?code=dlt&begin_date=11-1-2014&end_date=27-9-2021&day_count=77' // 6. Lô gan api trung gian Thống kê loto gan
*/
export const fetchStatisticalLotteryNearRequest = (filterParams) => {
	// let params = 'lottery_crawl/loto-gan?code=dlt&begin_date=11-1-2014&end_date=27-9-2021&day_count=77' // 6. Lô gan api trung gian Thống kê loto gan
	
	let paramString = `loto-gan?code=${filterParams.code}&begin_date=${filterParams.begin_date}&end_date=${filterParams.end_date}&day_count=${filterParams.day_count}`;
	let params = API + '/' + paramString;
    
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const { data } = resp;

			dispatch(fetchStatisticalSuccess(data));
		}).catch(error => {
			dispatch(fetchStatisticalFailed(error));
		});
	}
}



/*
* fetchStatistical by filterParams: { lottery_crawl_type_id: ..., location_id: ... }
* Api mẫu: 4. Thống kê tần suất loto theo Đặc Biệt: 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&filter={"lottery_crawl_type_id": 4, "location_id": 5}&limit=1'
*	       5. Chu kỳ lô tô: 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&filter={"lottery_crawl_type_id": 6, "location_id": 5}&limit=1'
*/
export const fetchStatisticalByFilterParamsRequest = (filterParams) => {
	// let params = 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&filter={"lottery_crawl_type_id": 4, "location_id": 5}&limit=1' // 4. Thống kê tần suất loto theo Đặc Biệt
	// let params = 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&filter={"lottery_crawl_type_id": 6, "location_id": 5}&limit=1' // 5. Chu kỳ lô tô
	
	const API_ENDPOINT = 'fields=["name","lottery_crawl_type_id","content","location_id"]&limit=1'; // + "&filter={"lottery_crawl_type_id": 6, "location_id": 5}"
	
	
	let paramString = '';
	if(filterParams) {
		paramString = `&filter={"lottery_crawl_type_id":${filterParams.lottery_crawl_type_id},"location_id":${filterParams.location_id}}`;
	}
	let params = API + '?' + API_ENDPOINT + paramString;
    
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const { data } = resp;

			dispatch(fetchStatisticalSuccess(data));
		}).catch(error => {
			dispatch(fetchStatisticalFailed(error));
		});
	}
}
