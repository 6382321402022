import { createUrlTo } from "../../../helpers/check";
import { PROVINCE_OPTIONS } from "./constans";

// api province
import * as dataHelpersApi from '../../../apis/Lottery';
import { toast } from "react-toastify";
const API_ENDPOINT = 'location?fields=["name","slug", "parent_id"]';



// Xử lý sự kiện onClick mở detail lottery:
// export function handleOnclickStatisticalTableCell(setFilterParamsPopup, handleOpenPopup){ // param mở popup detail lottery.
export function handleOnclickStatisticalTableCell(){
    try {
        const elements = document.querySelectorAll(".box-statistical-lottery.control-handle-click table tbody td");
        if(elements) {
            elements.forEach(ele => {
                ele.addEventListener("click", function(event) {
                    event.preventDefault();
                    let onClickFunctionContent = ele.getAttribute('onclick'); //===>result: show_kq_modal('#result_modal', 'mb', '2021-07-08', ['98']);
                    if(onClickFunctionContent) {
                        let params = onClickFunctionContent.replace(");","");
                        params = params.replaceAll("', '", ",");
                        params = params.replaceAll("', ", ",");
                        params = params.split(","); //===> result: ["show_kq_modal('#result_modal", 'mb', '2021-07-08', "['98']"]


                        // filterParams date format: "DD-MM-YYY" (vd: '31-08-2021')
                        
                        // Mở popup detail lottery:
                        // let dateString = `${params[2].substr(8,2)}-${params[2].substr(5,2)}-${params[2].substr(0,4)}`;
                        // const localStorageParamsPopup = localStorage.getItem('ParamsPopup');
                        // const localStorageParamsPopupNew = JSON.stringify({ code: params[1], date: dateString });
                        // if(localStorageParamsPopup !== localStorageParamsPopupNew){
                        //     setFilterParamsPopup({ code: params[1], date: dateString })
                        //     localStorage.setItem('ParamsPopup', localStorageParamsPopupNew)
                        // }
                        // handleOpenPopup();



                        // Mở trang kết quả xổ số:
                        let province = PROVINCE_OPTIONS.find(e=> e.value === params[1]);

                        // danh sách tỉnh api:
                        let DataProvinces = localStorage.getItem('DataHelpers');
                        if(DataProvinces) {
                            let Provinces = JSON.parse(DataProvinces);
                            const apiProvince = Provinces.find(e=> e.id === province.id);
                            if(apiProvince) {
                                province = {
                                    ...province,
                                    ...apiProvince,
                                }
                            } else {
                                // Nếu không tìm thấy tỉnh, lấy mặc định là Hà Nội: id = 25
                                province = {
                                    ...PROVINCE_OPTIONS.find(e=> e.id === 25),
                                    ...Provinces.find(e=> e.id === 25),
                                }
                            }
                        }

                        let options = {
                            location_id: province.id,
                            days: parseInt(params[2].substr(8,2)),
                            months: parseInt(params[2].substr(5,2)),
                            years: parseInt(params[2].substr(0,4)),
                            domain_id: province.parent_id,
                            pathName: province.slug
                        }
                        let url = createUrlTo(options);
                        // const localStorageParams = localStorage.getItem('Params');
                        // const localStorageParamsNew = JSON.stringify(url);
                        // if(localStorageParams !== localStorageParamsNew){
                        //     localStorage.setItem('Params', localStorageParamsNew)
                        // }
                        window.open(url, '_blank'); // mở trang kế quả xổ số trong tab mới.
                    }
                }, false);
            });
        }
    } catch (error) {
        console.log(error);
    }
}


// update table statistical on filter number seletor
export function showStatisticalByFilterNumberSelector(filterNumberData){
    const rowElementArray = document.querySelectorAll('.box-statistical-lottery table tbody tr');
    if(rowElementArray && rowElementArray.length > 0) {
        rowElementArray.forEach(item => {
            if(filterNumberData.find(e => e === item.firstChild.innerText)) {
                item.style.display = "table-row"
            } else {
                item.style.display = "none"
            }
        });
    }
}