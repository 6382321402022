

import moment from 'moment'



export const CreateParamsforApi = (paramsUrl) => {
    let result
    let filter = {
        location_id: paramsUrl.location_id?`"location_id":${paramsUrl.location_id},`:'',
        day:paramsUrl.days?`"day":${paramsUrl.days},`:'',
        month:paramsUrl.months?`"month":${paramsUrl.months},`:'',
        year:paramsUrl.years?`"year":${paramsUrl.years},`:''
    }
    result = `&filter={${filter.location_id}${filter.day}${filter.month}${filter.year} "xoso": { "ne": null}}`
    // result = `&filter={${filter.location_id}${filter.day}${filter.month}${filter.year}"lottery_type_id": ${typeData}}`


    return result
};

export const setDefaultDate = (options) =>{
    let result
    const toDate = new Date()
    result = {
        day:options.d?options.d:toDate.getDate(),
        month:options.m?options.m:toDate.getMonth(),
        year:options.y?options.y:toDate.getFullYear(),
        location_id:options.location_id?options.location_id:'44'
    }
    return result
}


export const MB = [
    {
        name: "ĐB",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 1",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 2",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 3",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 4",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 5",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 6",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 7",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Mã đặc biệt",
        LotteryResultItems:[
            {value: ""},
        ]
    },
]


export const MN = [
    {
        name: "Giải 8",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 7",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 6",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 5",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 4",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 3",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "Giải 2",
        LotteryResultItems:[
            {value: ""},
            {value: ""},
        ]
    },
    {
        name: "Giải 1",
        LotteryResultItems:[
            {value: ""},
        ]
    },
    {
        name: "ĐB",
        LotteryResultItems:[
            {value: ""},
        ]
    },
]


export const addMB = (data) => {
    let result = [
        {
            name: data[0].name,
            LotteryResultItems:[
                {value: data[0].LotteryResultItems[0].value},
            ]
        },
        {
            name: data[2].name,
            LotteryResultItems:[
                {value: data[2].LotteryResultItems[0].value},
                {value: data[2].LotteryResultItems[1].value},
                {value: data[2].LotteryResultItems[2].value},
                {value: data[2].LotteryResultItems[3].value},
            ]
        },
       
        {
            name: data[3].name,
            LotteryResultItems:[
                {value: data[3].LotteryResultItems[0].value},
            ]
        },
        {
            name: data[4].name,
            LotteryResultItems:[
                {value: data[4].LotteryResultItems[0].value},
                {value: data[4].LotteryResultItems[1].value},
            ]
        },
        {
            name: data[5].name,
            LotteryResultItems:[
                {value: data[5].LotteryResultItems[0].value},
                {value: data[5].LotteryResultItems[1].value},
                {value: data[5].LotteryResultItems[2].value},
                {value: data[5].LotteryResultItems[3].value},
                {value: data[5].LotteryResultItems[4].value},
                {value: data[5].LotteryResultItems[5].value},
            ]
        },
        {
            name: data[6].name,
            LotteryResultItems:[
                {value: data[6].LotteryResultItems[0].value},
                {value: data[6].LotteryResultItems[1].value},
                {value: data[6].LotteryResultItems[2].value},
                {value: data[6].LotteryResultItems[3].value},
            ]
        },
        {
            name: data[7].name,
            LotteryResultItems:[
                {value: data[7].LotteryResultItems[0].value},
                {value: data[7].LotteryResultItems[1].value},
                {value: data[7].LotteryResultItems[2].value},
                {value: data[7].LotteryResultItems[3].value},
                {value: data[7].LotteryResultItems[4].value},
                {value: data[7].LotteryResultItems[5].value},
            ]
        },
        {
            name: data[8].name,
            LotteryResultItems:[
                {value: data[8].LotteryResultItems[0].value},
                {value: data[8].LotteryResultItems[1].value},
                {value: data[8].LotteryResultItems[2].value},
            ]
        },
        {
            name: data[1].name,
            LotteryResultItems:[
                {value: data[1].LotteryResultItems[0].value},
            ]
        }
      
    ]
    return result
}

export const addMN = (data) => {
    let result = [
        {
            name: "Giải 8",
            LotteryResultItems:[
                {value: data[0].LotteryResultItems[0].value},
            ]
        },
        {
            name: "Giải 7",
            LotteryResultItems:[
                {value: data[1].LotteryResultItems[0].value},
            ]
        },
        {
            name: "Giải 6",
            LotteryResultItems:[
                {value: data[2].LotteryResultItems[0].value},
                {value: data[2].LotteryResultItems[1].value},
                {value: data[2].LotteryResultItems[2].value},
            ]
        },
        {
            name: "Giải 5",
            LotteryResultItems:[
                {value: data[3].LotteryResultItems[0].value},
            ]
        },
        {
            name: "Giải 4",
            LotteryResultItems:[
                {value: data[4].LotteryResultItems[0].value},
                {value: data[4].LotteryResultItems[1].value},
                {value: data[4].LotteryResultItems[2].value},
                {value: data[4].LotteryResultItems[3].value},
                {value: data[4].LotteryResultItems[4].value},
                {value: data[4].LotteryResultItems[5].value},
                {value: data[4].LotteryResultItems[6].value},
            ]
        },
        {
            name: "Giải 3",
            LotteryResultItems:[
                {value: data[5].LotteryResultItems[0].value},
                {value: data[5].LotteryResultItems[1].value}
            ]
        },
        {
            name: "Giải 2",
            LotteryResultItems:[
                {value: data[6].LotteryResultItems[0].value},
            ]
        },
        {
            name: "Giải 1",
            LotteryResultItems:[
                {value: data[7].LotteryResultItems[0].value},
            ]
        },
        {
            name: "ĐB",
            LotteryResultItems:[
                {value: data[8].LotteryResultItems[0].value},
            ]
        }
    ]
    return result
}





export const checkDataLocationLoading = (rows,filterParams) =>{
    let result
    if(rows.length>0){
        result = filterParams.domain_id === '1' ? addMB(rows) : MN
    }
    else {
        result = filterParams.domain_id === '1' ? MB : MN
    }
    return result
}

export const dayInCurrentWeek = () =>{
    let week = [
        
        {
            d:moment().day(1)._d.getDate(),
            m:moment().day(1)._d.getMonth(),
            y:moment().day(1)._d.getFullYear(),
        },
        {
            d:moment().day(2)._d.getDate(),
            m:moment().day(2)._d.getMonth(),
            y:moment().day(2)._d.getFullYear(),
        },
        {
            d:moment().day(3)._d.getDate(),
            m:moment().day(3)._d.getMonth(),
            y:moment().day(3)._d.getFullYear(),
        },
        {
            d:moment().day(4)._d.getDate(),
            m:moment().day(4)._d.getMonth(),
            y:moment().day(4)._d.getFullYear(),
        },
        {
            d:moment().day(5)._d.getDate(),
            m:moment().day(5)._d.getMonth(),
            y:moment().day(5)._d.getFullYear(),
        },
        {
            d:moment().day(6)._d.getDate(),
            m:moment().day(6)._d.getMonth(),
            y:moment().day(6)._d.getFullYear(),
        },
        {
            d:moment().day(0)._d.getDate(),
            m:moment().day(0)._d.getMonth(),
            y:moment().day(0)._d.getFullYear(),
        },
       
    ]
    return week
}

export function renderLotteryHTMLToJSX(htmlData) {
    return <div dangerouslySetInnerHTML={{__html: htmlData || '' }} ></div>
}