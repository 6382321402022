
import qs from 'query-string'
import moment from 'moment';

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory  } from "react-router";

import { fetchStatisticalByFilterParamsRequest, fetchStatisticalLotteryFrequencyRequest } from '../../actions/Statistical'
import { fetchProvinceRequest } from "../../actions/DataHelpers";
import { CreateParamsforApi } from "../../helpers";
import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../../helpers/check";
import { getStatistical } from '../../helpers/statistical';
// import TableLotteryFrequency from '../../components/statistical/table-statistical/TableLotteryFrequency';
// import PopupStatisticalDetail from '../../components/statistical/popup/PopupStatisticalDetail';
import {TableLotteryFrequency} from '../../components/statistical/table-statistical/TableLotteryFrequency';
import {PopupStatisticalDetail} from '../../components/statistical/popup/PopupStatisticalDetail';
import { fetchStatisticalDetailRequest } from '../../actions/StatisticalDetail';
import FilterNumberSelectorFirstEnd from '../../components/statistical/filter/number-selector/FilterNumberSelectorFirstEnd';
import { DATA_FILTER_ALL_NUMBER_FIRST_END, PROVINCE_OPTIONS } from '../../components/statistical/helpers/constans';
import FormFilter from '../../components/statistical/filter/form/FormFilter';

const toDay = moment(Date.now()).format('DD/MM/YYYY');
const toDayFilter = moment(Date.now()).format('DD-MM-YYYY');

const LotteryFrequencyContainer = (props) => {
	// console.log('LotteryFrequencyContainer props: ', props);
	const {
		DataProvince,
		GetProvince,
		Statistical,
		ShowStatistical,
		currentStatistical,
		StatisticalDetail,
		GetStatisticalDetail,
	} = props;

	const history = useHistory()
	const searchQuery = useLocation()

    const [filterNumberData, setFilterNumberData] = useState(DATA_FILTER_ALL_NUMBER_FIRST_END) // set data để filter bảng Statistical lottery


	// const filterParamsInit = { code: 'mb', count: 50, date: '11-8-2021' };
	const filterParamsInit = { code: PROVINCE_OPTIONS[0].value, count: 20, date: toDayFilter.toString() };
	const [filterParams, setFilterParams] = useState(filterParamsInit);

	const [openPopup, setOpenPopup] = useState(false);
	// const [filterParamsPopup, setFilterParamsPopup] = useState({ code: 'mb', date: '31-08-2021' });
	const [filterParamsPopup, setFilterParamsPopup] = useState(null);

	useEffect(()=>{
		GetProvince()
	},[])

	useEffect(()=>{
		ShowStatistical(filterParams)
	},[filterParams])

	useEffect(()=>{
		if(filterParamsPopup) {
			GetStatisticalDetail(filterParamsPopup)
		}
	},[filterParamsPopup])

	
	// useEffect(()=>{
	// 	if(currentStatistical) {
	// 		// console.log('currentStatistical: ', currentStatistical);
	// 	}

	// },[currentStatistical])

	// control popup
	function handleOpenPopup(){
		setOpenPopup(true)
	}
	function handleClosePopup(){
		setOpenPopup(false)
	}

	// submit form filter
	// function handleSubmit(){}

	return (
		<>
			<div className="kqbackground vien">
				<div className="panel panel-default">
					<div className="panel-heading center">
						<h4 className="right-menu-title">Thống kê tần suất loto</h4>
					</div>
					<div className="panel-body">
						<FormFilter 
							filterParams={filterParams} 
							setFilterParams={setFilterParams} 
							// handleSubmit={handleSubmit} 
							currentStatistical={currentStatistical}
						/>
					</div>
				</div>


				<FilterNumberSelectorFirstEnd 
					filterNumberData={filterNumberData}
					setFilterNumberData={setFilterNumberData}
				/>

				<h3>
					<p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>
						Thống kê tần suất loto {PROVINCE_OPTIONS.find(e=>e.value === filterParams.code)?.name} trong vòng {filterParams.count || '...'} ngày trước {filterParams.date || toDay}
					</p>
				</h3>
				
				<TableLotteryFrequency 
					Statistical={Statistical} 
					filterParams={filterParams} 
					
					setFilterParamsPopup={setFilterParamsPopup} 
					handleOpenPopup={handleOpenPopup}

					filterNumberData={filterNumberData}
				/>


				{ openPopup &&
					<PopupStatisticalDetail 
						StatisticalDetail={StatisticalDetail} 
						filterParamsPopup={filterParamsPopup} 
						openPopup={openPopup} 
						handleClosePopup={handleClosePopup} 
					/> 
				}
			</div>
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
		Statistical: state.Statistical,
		StatisticalDetail: state.StatisticalDetail
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:(filterParams) =>{
			dispatch(fetchStatisticalLotteryFrequencyRequest(filterParams))
		},
		GetStatisticalDetail:(filterParamsPopup) =>{
			dispatch(fetchStatisticalDetailRequest(filterParamsPopup))
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(LotteryFrequencyContainer);

