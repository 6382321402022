import React, { Component } from "react";
class TableLoadingMN extends Component {
	render() {
		return (
			<table className="kqmb extendable kqtinh">
                <tbody>
                    <tr className="g7">
                        <td className="txt-giai">Giải 8</td>
                        <td className="v-giai number"><span className="v-g8-0 imgloadig" /></td>
                    </tr>
                    <tr className="bg_ef">
                        <td className="txt-giai">Giải 7</td>
                        <td className="v-giai number"><span className="v-g7-0 imgloadig" /></td>
                    </tr>
                    <tr>
                        <td className="txt-giai">Giải 6</td>
                        <td className="v-giai number"><span className="v-g6-0 imgloadig" /><span
                                className="v-g6-1 imgloadig" /><span className="v-g6-2 imgloadig" /></td>
                    </tr>
                    <tr className="bg_ef">
                        <td className="txt-giai">Giải 5</td>
                        <td className="v-giai number"><span className="v-g5-0 imgloadig" /></td>
                    </tr>
                    <tr>
                        <td className="txt-giai">Giải 4</td>
                        <td className="v-giai number"><span className="v-g4-0 imgloadig" /><span
                                className="v-g4-1 imgloadig" /><span className="v-g4-2 imgloadig" /><span
                                className="v-g4-3 imgloadig" /><span className="v-g4-4 imgloadig" /><span
                                className="v-g4-5 imgloadig" /><span className="v-g4-6 imgloadig" /></td>
                    </tr>
                    <tr className="bg_ef">
                        <td className="txt-giai">Giải 3</td>
                        <td className="v-giai number">
                            <span className="v-g3-0 imgloadig" />
                            <span className="v-g3-1 imgloadig" />
                        </td>
                    </tr>
                    <tr>
                        <td className="txt-giai">Giải 2</td>
                        <td className="v-giai number">
                            <span className="v-g2-1 imgloadig" />
                        </td>
                    </tr>
                    <tr className="bg_ef">
                        <td className="txt-giai">Giải 1</td>
                        <td className="v-giai number"><span className="v-g1-0 imgloadig" /></td>
                    </tr>
                    <tr className="db">
                        <td className="txt-giai">ĐB</td>
                        <td className="v-giai number"><span className="v-g0-0 imgloadig" /></td>
                    </tr>
                </tbody>
            </table>
		);
	}
}



export default TableLoadingMN;
