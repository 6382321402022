import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route,Switch } from 'react-router-dom';
import routes from './routes';
import Home from "./pages/Home";
import Lottery from "./pages/Lottery";
import LotteryRealTime from "./pages/LotteryRealTime";
import LotteryTrial from "./pages/LotteryTrial";
import { LotteryRoute } from './pages/LotteryRoute';

function App() {
	const showContentMenus = (routes) =>{
		var result = null;
		if(routes.length>0){
			result = routes.map((route,index)=>{
				return (
					<Route key={index} path={route.path} exact={routes.exact} component={route.main} />
				)
			})
		}
		return result
	}
	return (
		<Router >
			{/* {showContentMenus(routes)} */}

			<Route path="/" component={Home} exact />
			
			<Route path="/:id" component={LotteryRoute} />
			{/* <Route path="/xo-so" component={Lottery}  />
			<Route path="/truc-tiep" component={LotteryRealTime}  />
			<Route path="/quay-thu" component={LotteryTrial}  /> */}
		</Router>
	);
	
}

export default App;
