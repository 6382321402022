import React, { useEffect, useState } from "react";


export function PopupTableLotteryMN(props) {
	const { StatisticalDetail, dateObj } = props
	const [data, setData] = useState(StatisticalDetail)

	useEffect(() => {
		if (StatisticalDetail) {
			setData(StatisticalDetail)
		}
	}, [StatisticalDetail])

	return (
		<>
			<table
				className="table table-condensed kqcenter kqvertimarginw table-kq-border table-kq-hover-div table-bordered kqbackground table-kq-bold-border tb-phoi-border watermark table-striped"
				id="result_tab_mb"
			>
				<thead>
					<tr className="title_row">
						<td className="color333" colSpan={2}>
							<div className="col-sm-10">
								<span className=" chu15" id="result_date">
									{dateObj.weekday} ngày {dateObj.date}
								</span>
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16, color: "red" }}
						>
							Giải tám
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_7_0"
									style={{ width: "100%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam border-right hover chu30 maudo"
									rs_len={2}
									data-pattern="[0-9]{2}"
									// data-sofar={16}
								>
									{ (data&&data[8][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải bảy
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_7_0"
									style={{ width: "100%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={3}
									data-pattern="[0-9]{3}"
									// data-sofar={16}
								>
									{ (data&&data[7][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải sáu
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_6_0"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={354}
								>
									{ (data&&data[6][0]) || '' }
								</div>
								<div
									id="rs_6_1"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={960}
								>
									{ (data&&data[6][1]) || '' }
								</div>
								<div
									id="rs_6_2"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={277}
								>
									{ (data&&data[6][2]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải năm
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_5_0"
									style={{
										width: "100%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={6065}
								>
									{ (data&&data[5][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải tư
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_4_0"
									style={{ width: "25%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={2292}
								>
									{ (data&&data[4][0]) || '' }
								</div>
								<div
									id="rs_4_1"
									style={{ width: "25%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={6414}
								>
									{ (data&&data[4][1]) || '' }
								</div>
								<div
									id="rs_4_2"
									style={{ width: "25%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={0880}
								>
									{ (data&&data[4][2]) || '' }
								</div>
								<div
									id="rs_4_3"
									style={{ width: "25%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={9850}
								>
									{ (data&&data[4][3]) || '' }
								</div>
								<div
									id="rs_4_3"
									style={{ width: "33.333333333333%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={9850}
								>
									{ (data&&data[4][4]) || '' }
								</div>
								<div
									id="rs_4_3"
									style={{ width: "33.333333333333%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={9850}
								>
									{ (data&&data[4][5]) || '' }
								</div>
								<div
									id="rs_4_3"
									style={{ width: "33.333333333333%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={9850}
								>
									{ (data&&data[4][6]) || '' }
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải ba
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_3_0"
									style={{
										width: "50%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={47349}
								>
									{ (data&&data[3][0]) || '' }
								</div>
								<div
									id="rs_3_1"
									style={{
										width: "50%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={79435}
								>
									{ (data&&data[3][1]) || '' }
								</div>
							</div>
						</td>
					</tr>
					
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải nhì
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_1_0"
									style={{ width: "100%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={56052}
								>
									{ (data&&data[2][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{ width: "22%", verticalAlign: "middle", fontSize: 16 }}
						>
							Giải nhất
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_1_0"
									style={{ width: "100%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={56052}
								>
									{ (data&&data[1][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16,
								color: "red"
							}}
						>
							Đặc biệt
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_0_0"
									style={{ width: "100%", position: "relative", float: "left" }}
									className="phoi-size chu22 gray need_blank vietdam phoi-size chu30 maudo stop-reload hover"
									rs_len={6}
									data-pattern="[0-9]{6}"
									// data-sofar={17948}
								>
									{ (data&&data[0][0]) || '' }
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table >

		</>
	);
}
