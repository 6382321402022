import React, { Component, useState } from "react";
import { FIRST_END_ARRAY, DATA_CHECKBOX_FIRST_END } from "../../helpers/constans";


export default function FilterNumberSelectorFirstEnd(props) {
    
	const {filterNumberData, setFilterNumberData} = props

	const [dataCheckbox, setDataCheckbox] = useState(DATA_CHECKBOX_FIRST_END)
	

	function handleClickButton(value){
		try {
			let dataCheckboxTemp = [...dataCheckbox]; // data bảng checkbox
			let filterNumberDataTemp = []; // data để filter bảng Statistical lottery

			if(value.indexOf('first') >=0) { // value: "first_0", "first_1", ... , "first_9"
				let firstNum = parseInt(value.substr(-1,1)); // get the last character
				dataCheckboxTemp.map(item=>{
					if(item.first === firstNum) {
						filterNumberDataTemp.push(item.value);
						return item.checked = true;
					} else {
						return item.checked = false;
					}
				})
	
			} else if(value.indexOf('end') >= 0) { // value: "end_0", "end_1", ... , "end_9"
				let endNum = parseInt(value.substr(-1,1)); // get the last character
				dataCheckboxTemp.map(item=>{
					if(item.end === endNum) {
						filterNumberDataTemp.push(item.value);
						return item.checked = true;
					} else {
						return item.checked = false;
					}
				})
	
			} else {
				switch (value) {
					case 'no':
							filterNumberDataTemp = [];
	
							dataCheckboxTemp.map(item=>{
								return item.checked = false;
							})
						break;
					case 'even': // chọn số chẵn
						dataCheckboxTemp.map(item=>{
							if((parseInt(item.value.substr(1,1)) %2) === 0) {
								filterNumberDataTemp.push(item.value);
								return item.checked = true;
							} else {
								return item.checked = false;
							}
						})
						break;
					case 'odd': // chọn số lẻ
							dataCheckboxTemp.map(item=>{
								if((parseInt(item.value.substr(1,1)) %2) === 1) {
									filterNumberDataTemp.push(item.value);
									return item.checked = true;
								} else {
									return item.checked = false;
								}
							})
							break;
				
					default: // "all": all checked true
						dataCheckboxTemp.map(item=>{
							filterNumberDataTemp.push(item.value);
							return item.checked = true;
						})
						break;
				}
			}
	
			setDataCheckbox(dataCheckboxTemp);
			setFilterNumberData(filterNumberDataTemp)

		} catch (error) {
			console.log(error);
		}
	}


	function handleChangeCheckbox(event, index){
		try {
			// set data bảng checkbox
			let dataCheckboxTemp = [...dataCheckbox];
			dataCheckboxTemp[index].checked = event.target.checked;
			setDataCheckbox(dataCheckboxTemp)

			// set data để filter bảng Statistical lottery
			let filterNumberDataTemp = [...filterNumberData];
			if(event.target.checked) {
				filterNumberDataTemp.push(event.target.value)
			} else {
				filterNumberDataTemp = filterNumberDataTemp.filter(e => e !== event.target.value); // xóa phần tử bị bỏ chọn
			}
			setFilterNumberData(filterNumberDataTemp)
		} catch (error) {
			console.log(error);
		}
	}
	
	
	return (
		<>
			<table
				id="number_selector"
				className="kq-table-hover table table-condensed kqcenter kqbackground border"
			>
				<thead>
					<tr className="info">
						<th colSpan={11}>
							<button className="btn btn-default col-sm-2 col-sm-offset-1" value='all' onClick={()=>handleClickButton('all')}>Tất cả các số</button>
							<button className="btn btn-default col-sm-2" value="no" onClick={()=>handleClickButton('no')}>Không số nào</button>
							<button className="btn btn-default col-sm-2" onClick={()=>handleClickButton('even')}>Chỉ chọn số chẵn</button>
							<button className="btn btn-default col-sm-2" onClick={()=>handleClickButton('odd')}>Chỉ chọn số lẻ</button>
						</th>
					</tr>
				</thead>

				<tbody>
					{
						// Duyệt đầu 0->9
						FIRST_END_ARRAY.map(numFirst => {
							return (
								<tr key={`lottery-${numFirst}`}>
									{
										// Duyệt đuôi 0->9
										FIRST_END_ARRAY.map(numEnd => {
											let indexString = `${numFirst}${numEnd}`;
											let index = parseInt(indexString);

											return (
												<td key={`duoi-${numEnd}`}>
													<label id={`lb_${numEnd}`}>
													<input
														type="checkbox"
														className="number_indi"
														id={`cb_${dataCheckbox[index].value}`}
														value={dataCheckbox[index].value}
														checked={dataCheckbox[index].checked}
														onChange={(e)=>handleChangeCheckbox(e, index)}
													/>
														{` ${dataCheckbox[index].value}`}
													</label>
												</td>
											)
										})
									}
									
									<td className="info">
										<button
											className="btn btn-xs btn-default"
											onClick={()=>handleClickButton(`first_${numFirst}`)}
										>
											Đầu {numFirst}
										</button>
									</td>
								</tr>
							)
						})
					}
					

					<tr  className="info">
					{
						FIRST_END_ARRAY.map(numEnd => {
							return (
								<td key={`dau-${numEnd}`}>
									<button className="btn btn-default" onClick={()=>handleClickButton(`end_${numEnd}`)}>Đuôi {numEnd}</button>
								</td>
							)
						})
					}
					</tr>

				</tbody>
			</table>
		</>
	);
}

