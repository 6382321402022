
import qs from 'query-string'
import moment from 'moment';

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory  } from "react-router";

// import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
// import TableBreadCrumbs from "../components/BreadCrumbs/TableBreadCrumbs";
import { TableLotteryPairFrequency } from "../../components/statistical/table-statistical/TableLotteryPairFrequency";
// import TableFilter from "../components/TableFilter";
import { fetchStatisticalByTypeIdRequest } from '../../actions/Statistical'
import { fetchProvinceRequest } from "../../actions/DataHelpers";
import { PopupStatisticalDetail } from '../../components/statistical/popup/PopupStatisticalDetail';
import { fetchStatisticalDetailRequest } from '../../actions/StatisticalDetail';
import FilterNumberSelectorLotteryPair from '../../components/statistical/filter/number-selector/FilterNumberSelectorLotteryPair';
import FormFilter from '../../components/statistical/filter/form/FormFilter';
import { DATA_FILTER_ALL_NUMBER_LOTTERY_PAIR, PROVINCE_OPTIONS } from '../../components/statistical/helpers/constans';
// import { CreateParamsforApi } from "../../helpers";
// import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../../helpers/check";
// import { getStatistical } from '../../helpers/statistical';

const toDay = moment(Date.now()).format('DD/MM/YYYY');
const toDayFilter = moment(Date.now()).format('DD-MM-YYYY');

const LotteryPairFrequencyContainer = ({
	DataProvince,
	ShowTable,
	GetProvince,
	Statistical,
	ShowStatistical,
	currentStatistical,

	StatisticalDetail,
	GetStatisticalDetail,
}) => {
	const history = useHistory()
	const searchQuery = useLocation()

    // const toDay = moment(Date.now()).format('DD/MM/YYYY')

    const [filterNumberData, setFilterNumberData] = useState(DATA_FILTER_ALL_NUMBER_LOTTERY_PAIR) // set data để filter bảng Statistical lottery


	// const [filterParams, setFilterParams] = useState({ code: 'mb' });
	const filterParamsInit = { code: PROVINCE_OPTIONS[0].value, count: 20, date: toDayFilter.toString() };
	const [filterParams, setFilterParams] = useState(filterParamsInit);

	const [openPopup, setOpenPopup] = useState(false);
	// const [filterParamsPopup, setFilterParamsPopup] = useState({ code: 'mb', date: '31-08-2021' });
	const [filterParamsPopup, setFilterParamsPopup] = useState(null);

	
	// let searchObject = createFilterFormParams(searchQuery)
	// const searchLottery = CreateParamsforApi(filterParams)
	
	// const [searchLottery, setSearchLottery] = useState(()=>CreateParamsforApi(filterParams));

	// const locationIdDefault = getLocationIdDefault(filterParams.domain_id, today_weekday);
	// const locationId = findLocationIdFromPathName(searchQuery.pathname, filterProvince(DataProvince));

	useEffect(()=>{
		GetProvince()
	},[])

	useEffect(()=>{
		// GetProvince()
		ShowStatistical(filterParams)
	},[filterParams])
	
	useEffect(()=>{
		if(filterParamsPopup) {
			GetStatisticalDetail(filterParamsPopup)
		}
	},[filterParamsPopup])

	// control popup
	function handleOpenPopup(){
		setOpenPopup(true)
	}
	function handleClosePopup(){
		setOpenPopup(false)
	}

	return (
		<>
			<FormFilter 
				filterParams={filterParams} 
				setFilterParams={setFilterParams} 
				// handleSubmit={handleSubmit} 
				currentStatistical={currentStatistical}
			/>

			<div className="kqbackground vien">
				<h3>
					<p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>
						Thống kê tần suất cặp loto {PROVINCE_OPTIONS.find(e=>e.value === filterParams.code)?.name || ""} trong vòng {filterParams.count || '...'} ngày trước {filterParams.date || toDay}
					</p>
				</h3>
					
				{/* <h3>
					<p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>
						Thống kê tần suất cặp loto {PROVINCE_OPTIONS.find(e=>e.value === filterParams.code)?.name} trong vòng {filterParams.count || '...'} ngày trước {filterParams.date || toDay}
					</p>
				</h3> */}

				<FilterNumberSelectorLotteryPair 
					filterNumberData={filterNumberData}
					setFilterNumberData={setFilterNumberData}
				/>

				<TableLotteryPairFrequency 
					Statistical={Statistical} 
					filterParams={filterParams} 
					
					setFilterParamsPopup={setFilterParamsPopup} 
					handleOpenPopup={handleOpenPopup}

					filterNumberData={filterNumberData}
				/>
				
				{ openPopup && 
					<PopupStatisticalDetail 
						StatisticalDetail={StatisticalDetail} 
						filterParamsPopup={filterParamsPopup} 
						openPopup={openPopup} 
						handleClosePopup={handleClosePopup} 
					/> 
				}
			</div>
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
		Statistical: state.Statistical,
		StatisticalDetail: state.StatisticalDetail,
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		// ShowTable: (params, filterParams) => {
		// 	dispatch(fetchListLotteryRequest(params, filterParams));
		// },
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:(filterParams) =>{
			dispatch(fetchStatisticalByTypeIdRequest(3, filterParams))
		},
		GetStatisticalDetail:(filterParamsPopup) =>{
			dispatch(fetchStatisticalDetailRequest(filterParamsPopup))
		}
	}
}

export default connect(mapStateToProp, mapDispatchToProps)(LotteryPairFrequencyContainer);

