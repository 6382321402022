export const SCHEDULE = [
  {
    domain_id: 1, 
    Schedule: [
      {weekday: 1, name: 'Thứ Hai', location_ids: [25]},
      {weekday: 2, name: 'Thứ Ba', location_ids: [25]},
      {weekday: 3, name: 'Thứ Tư', location_ids: [25]},
      {weekday: 4, name: 'Thứ Năm', location_ids: [25]},
      {weekday: 5, name: 'Thứ Sáu', location_ids: [25]},
      {weekday: 6, name: 'Thứ Bảy', location_ids: [25]},
      {weekday: 0, name: 'Chủ nhật', location_ids: [25]},
    ]
  },
  {
    domain_id: 2, 
    Schedule: [
      {weekday: 1, name: 'Thứ Hai', location_ids: [35,34]},
      {weekday: 2, name: 'Thứ Ba', location_ids: [37,36]},
      {weekday: 3, name: 'Thứ Tư', location_ids: [33,44]},
      {weekday: 4, name: 'Thứ Năm', location_ids: [38,39,40]},
      {weekday: 5, name: 'Thứ Sáu', location_ids: [41,42]},
      {weekday: 6, name: 'Thứ Bảy', location_ids: [33,32,31]},
      {weekday: 0, name: 'Chủ nhật', location_ids: [44,43]},
    ]
  },
  {
    domain_id: 3, 
    Schedule: [
      {weekday: 1, name: 'Thứ Hai', location_ids: [7,9,8]},
      {weekday: 2, name: 'Thứ Ba', location_ids: [11,12,10]},
      {weekday: 3, name: 'Thứ Tư', location_ids: [14,13,15]},
      {weekday: 4, name: 'Thứ Năm', location_ids: [18,16,17]},
      {weekday: 5, name: 'Thứ Sáu', location_ids: [21,19,20]},
      {weekday: 6, name: 'Thứ Bảy', location_ids: [7,6,5,4]},
      {weekday: 0, name: 'Chủ nhật', location_ids: [24,23,22]},
    ]
  },
]