import React, { useEffect, useState } from "react";
import { renderLotteryHTMLToJSX } from "../../../helpers";
import { handleOnclickStatisticalTableCell, showStatisticalByFilterNumberSelector } from "../helpers";
import { PROVINCE_OPTIONS } from "../helpers/constans";



export function LotterySpecial(props) {
    const {Statistical, filterParams, filterNumberData, setFilterParamsPopup, handleOpenPopup} = props

    // const [province, setProvince] = useState(null)
    // set title
    // useEffect(()=>{
    //     if(filterParams) {
    //         let provinceSelected = PROVINCE_OPTIONS.find(item=>item.id === filterParams.location_id)
    //         setProvince(provinceSelected)
    //     }
    // },[filterParams])

    useEffect(()=>{
        if(filterNumberData) {
            // update table statistical on filter number
            showStatisticalByFilterNumberSelector(filterNumberData);
        }
    },[filterNumberData])

    useEffect(()=>{
        if(Statistical) {
            // Xử lý sự kiện onClick mở popup detail lottery:
            handleOnclickStatisticalTableCell();
        }
    },[Statistical])
    
    return (
        <>
            {/* <h3>
                <p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>Thống kê loto theo Đặc Biệt {province?.name || ''}, bộ số 86</p>
            </h3> */}

            <div className="scroll" className="box-statistical-lottery control-handle-click">
                {Statistical && Statistical.rows && renderLotteryHTMLToJSX(Statistical.rows[0]?.content || '')}
            </div>
        </>
    );
}
