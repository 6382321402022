import { combineReducers } from 'redux';
import DataLottery from './DataLottery'
import LoadingTable from './LoadingTable';
import DataLotteryRealtime from './DataLotteryRealtime';
import DataProvince from './DataProvince';
import Statistical from './Statistical';
import StatisticalDetail from './StatisticalDetail';
const rootReducers = combineReducers({
    DataLottery,
    DataLotteryRealtime,
    LoadingTable,
    DataProvince,
    Statistical,
    StatisticalDetail,
});
export default rootReducers;
