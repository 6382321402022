import React, { useState } from "react";

export default function FormFilter(props) {
    
	const {filterParams, setFilterParams, valueType, currentStatistical} = props
	const [formValue, setFormValue] = useState({...filterParams});
	
	function handleSubmit(){
		try {
			let filterParamsTemp = {...filterParams, ...formValue}
			setFilterParams(filterParamsTemp)
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			
			<form id="main_form">

				{
					currentStatistical?.getFormFilterComponent({formValue, setFormValue}) || null
				}
				
				<div className="form-group col-sm-offset-4 col-sm-2">
					<button type="button" className="btn btn-primary" onClick={handleSubmit}>
						<i className="fa fa-eye" /> Xem kết quả
					</button>
				</div>
			</form>
		</>
	);
}

