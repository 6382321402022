
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import TrialTab from "../components/TrialControl/TrialTab";
import TrialBox from "../components/TrialControl/TrialBox";
import TableMB from "../components/TableLottery/TableMB";
import TableMN from "../components/TableLottery/TableMN";
import TableFirstLastLotteryTrial from "../components/TableLottery/TableFirstLastLotteryTrial";

import { fetchProvinceRequest } from "../actions/DataHelpers";
import { getRandomLotteryArray, getRandomSpecialPrizeCode } from "../helpers/random";
import { renderStartEndValue } from "../components/TrialControl/helper";
import { filterTableLottery } from "../helpers/filterLottery";
import TableFilter from "../components/TableFilter";

function TableLotteryTrialContainer ({ DataProvince }) {
	const [dataStartEndLottery, setDataStartEndLottery] = useState(null);
	const [lotteryTrial, setLotteryTrial] = useState({status: false, time: 0});
	const [filterParams, setFilterParams] = useState({domain_id: 1, pathName: "/quay-thu/"}); 
	const [dataProvince, setDataProvince] = useState(null);

	const [showDigits, setShowDigits] = useState('all')
	const [dataLotteryFilter, setDataLotteryFilter] = useState(null);

	useEffect(()=>{
		try {
			if(DataProvince) {
				let DataProvinceTemp = [
					{id: 1, name: "Miền Bắc", short_name: "MB", slug: "mien-bac", Province: []},
					{id: 2, name: "Miền Trung", short_name: "MT", slug: "mien-trung", Province: []},
					{id: 3, name: "Miền Nam", short_name: "MN", slug: "mien-nam", Province: []},
				];
				DataProvince.map(e=>{
					switch (e.parent_id) {
						case 1: DataProvinceTemp[0].Province.push(e);
							break;
						case 2: DataProvinceTemp[1].Province.push(e);
							break;
						case 3: DataProvinceTemp[2].Province.push(e);
							break;
						default: 
							break;
					}
				})
				setDataProvince(DataProvinceTemp);
			}

		} catch(error){console.log(error)}
	},[DataProvince])
	
	const ArrayLotteryRandom = filterParams.domain_id === 1 
    ? ( // MB
      getRandomLotteryArray(1, 5)
      .concat(getRandomLotteryArray(2, 5))
      .concat(getRandomLotteryArray(6, 5))
      .concat(getRandomLotteryArray(4, 4))
      .concat(getRandomLotteryArray(6, 4))
      .concat(getRandomLotteryArray(3, 3))
      .concat(getRandomLotteryArray(4, 2))
      .concat(getRandomSpecialPrizeCode(6,2))
      .concat(getRandomLotteryArray(1, 5))) 
    : ( // MT,MN
        getRandomLotteryArray(1, 2)
      .concat(getRandomLotteryArray(1, 3))
      .concat(getRandomLotteryArray(3, 4))
      .concat(getRandomLotteryArray(1, 4))
      .concat(getRandomLotteryArray(7, 5))
      .concat(getRandomLotteryArray(2, 5))
      .concat(getRandomLotteryArray(1, 5))
      .concat(getRandomLotteryArray(1, 5))
			.concat(getRandomLotteryArray(1, 6)));

	const [arrayLotteryRandom, setArrayLotteryRandom] = useState(ArrayLotteryRandom);
	
	useEffect(()=>{
		// rerandom value
		lotteryTrial && setArrayLotteryRandom(ArrayLotteryRandom);
	},[lotteryTrial])

	useEffect(()=>{
		// refresh random
		try {
			if(filterParams) {
				setLotteryTrial({status: false, time: 0});
				setArrayLotteryRandom([]);
				setDataStartEndLottery({DataLottery: [], DataStartLottery: null, DataEndLottery: null});
			}
		} catch(error){console.log(error)}
	},[filterParams])
    
  useEffect(()=>{
		try {
			// random
			if(arrayLotteryRandom && lotteryTrial.status) {
				if (arrayLotteryRandom.length === 0) {
					setLotteryTrial({status: false, time: 0});
					setArrayLotteryRandom([...ArrayLotteryRandom]);
					return;
        } 
        
				const times = setInterval(()=>{
          let temp1 = dataStartEndLottery.DataLottery;
					temp1.push(arrayLotteryRandom[0]);
          
          // set data StartEnd table
          let dataStartEndLotteryTemp = renderStartEndValue(temp1);
					setDataStartEndLottery({...dataStartEndLotteryTemp, DataLottery: temp1});
					
					let temp2 = [...arrayLotteryRandom];
					temp2.splice(0,1);
					setArrayLotteryRandom(temp2);

					
				}, lotteryTrial.time);
				return () => clearInterval(times);
			}
		} catch(error){console.log(error)}
	},[arrayLotteryRandom, lotteryTrial])

	useEffect(()=>{
		// table filter by digits
		try {
			if(dataStartEndLottery && dataStartEndLottery.DataLottery) {
				let filterData = filterTableLottery(dataStartEndLottery.DataLottery, showDigits);
				setDataLotteryFilter(filterData);
			}

		} catch (error) {
			console.log(error);
		}
	},[showDigits, dataStartEndLottery])

	return (
		<div className="col-l" >
			<div className="box quay-thu" >
				<TrialTab 
					DataProvince = {dataProvince}
					filterParams = {filterParams}
					setFilterParams = {setFilterParams} 
					setDataStartEndLottery = {setDataStartEndLottery}
				/>
				<div className="box" >
					<TrialBox 
						lotteryTrial={lotteryTrial} 
						setLotteryTrial={setLotteryTrial} 
						dataStartEndLottery = {dataStartEndLottery} 
						setDataStartEndLottery = {setDataStartEndLottery}
						filterParams = {filterParams}
						setFilterParams = {setFilterParams}
						DataProvince = {dataProvince}
					/>

					{filterParams&&filterParams.domain_id === 1 
						? <TableMB dataStartEndLottery = {dataStartEndLottery} dataLotteryFilter = {dataLotteryFilter} />
						: <TableMN dataStartEndLottery = {dataStartEndLottery} dataLotteryFilter = {dataLotteryFilter} />
					}
					<TableFilter showDigits = {showDigits} setShowDigits={setShowDigits}/>
					<TableFirstLastLotteryTrial dataStartEndLottery = {dataStartEndLottery} />
				</div>
			</div>
		</div>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(TableLotteryTrialContainer);