import React, { Component } from "react";
import { renderLotteryHTMLToJSX } from "../../../helpers";
import { PROVINCE_OPTIONS } from "../helpers/constans";

class LotteryNear extends Component {
	render() {
        const {Statistical, filterParams} = this.props
        
		return (
			<div className="kqbackground vien">
                <div className="panel panel-default">
                    <div className="panel-heading center">
                        <h4 className="right-menu-title">Thống kê loto Gan {PROVINCE_OPTIONS.find(item=>item.value === filterParams.code)?.name || ''}</h4>
                    </div>

                    <div className="panel-body">
                        <div className="kqbackground logo-gan-list">
                            <h4>Các bộ số chưa ra theo biên độ <span className="maudo">{filterParams?.day_count || '...'}</span> ngày trở lên</h4>
                            {Statistical && renderLotteryHTMLToJSX(Statistical.list || '')}
                        </div>
			            <br />
                        {Statistical && renderLotteryHTMLToJSX(Statistical.table || '')}
                    </div>
                </div>
            </div>
		);
	}

}



export default LotteryNear;
