import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';


class TableFirstLastLottery extends Component {
    showDataStartLottery = (rows) =>{
        let result
        if(rows.length>0){
            result = rows.map((row,index)=>{
                return (<tr key={index}>
                    <td className="clnote">{row.name}</td>
                    <td className="v-loto-dau-0">{row.LotteryResultItems[0].value}</td>
                </tr>)
            })
        }
        return result
    }
    showDataEndLottery = (rows) =>{
        let result
        if(rows.length>0){
            result = rows.map((row,index)=>{
                return (<tr key={index}>
                    <td className="v-loto-dau-0">{row.LotteryResultItems[0].value}</td>
                    <td className="clnote">{row.name}</td>
                </tr>)
            })
        }

        return result
    }
    showData=(DataLottery)=>{
        let result 
        if(DataLottery[0]){
            result = (
                <>
                    {ReactHtmlParser(DataLottery[0].dauso)}
                    {ReactHtmlParser(DataLottery[0].duoiso)}
                </>
            )
        }
        return result
    }
    render () {
        const {DataLottery} = this.props
        
        return (
            <>
                <div className="col-firstlast">
                    {this.showData(DataLottery)}
                    {/* <table className="firstlast-mb fl">
                        <tbody>
                            <tr className="header">
                                <th>Đầu</th>
                                <th>Đuôi</th>
                            </tr>
                            
                            {this.showDataStartLottery(DataStartLottery)}
                        </tbody>
                    </table>
                    <table className="firstlast-mb fr">
                        <tbody>
                            <tr className="header">
                                <th>Đầu</th>
                                <th>Đuôi</th>
                            </tr>
                            

                            {this.showDataEndLottery(DataEndLottery)}
                        </tbody>
                    </table> */}
                </div>
                <div className="clearfix"></div>
            </>
        );
    }   
   
}
  
  

export default TableFirstLastLottery;
 