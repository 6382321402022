import * as Types from '../constants/Loading';
var data = JSON.parse(localStorage.getItem('LoadingTable'));
var initialState = data ? data : {
    isLoading:true
};

const LoadingTable = (state = initialState, action) => {
    // console.log(action)
	switch (action.type) {
		case Types.TOGGLE_LOADING_TABLE : {
			const { isLoading } = action.payload;
            state.isLoading = isLoading
			localStorage.setItem('LoadingTable', JSON.stringify(state));
			return {...state}
		}
	
		default: return {...state}
	}
}

export default LoadingTable;