// LotteryDigits đã được sắp xếp theo thứ tự hiển thị trên giao diện của miền (Không phải theo thứ tự xổ số).
export const LOTTERY_DIGITS = [
  {domain_id: 1, LotteryDigits: [
    { prize: 'mdb', count: 4, digit: 4 },
    { prize: 'gdb', count: 1, digit: 5 },
    { prize: 'g1', count: 1, digit: 5 },
    { prize: 'g2', count: 2, digit: 5 },
    { prize: 'g3', count: 6, digit: 5 },
    { prize: 'g4', count: 4, digit: 4 },
    { prize: 'g5', count: 6, digit: 4 },
    { prize: 'g6', count: 3, digit: 3 },
    { prize: 'g7', count: 4, digit: 2 },
  ]},
  {domain_id: 2, LotteryDigits: [
    { prize: 'g8', count: 1, digit: 2 },
    { prize: 'g7', count: 1, digit: 3 },
    { prize: 'g6', count: 3, digit: 4 },
    { prize: 'g5', count: 1, digit: 4 },
    { prize: 'g4', count: 7, digit: 5 },
    { prize: 'g3', count: 2, digit: 5 },
    { prize: 'g2', count: 1, digit: 5 },
    { prize: 'g1', count: 1, digit: 5 },
    { prize: 'gdb', count: 1, digit: 6 },
  ]},
  {domain_id: 3, LotteryDigits: [
    { prize: 'g8', count: 1, digit: 2 },
    { prize: 'g7', count: 1, digit: 3 },
    { prize: 'g6', count: 3, digit: 4 },
    { prize: 'g5', count: 1, digit: 4 },
    { prize: 'g4', count: 7, digit: 5 },
    { prize: 'g3', count: 2, digit: 5 },
    { prize: 'g2', count: 1, digit: 5 },
    { prize: 'g1', count: 1, digit: 5 },
    { prize: 'gdb', count: 1, digit: 6 },
  ]}
]

// thứ tự xổ số của các miền
export const LOTTERY_ORDER = [
  { domain_id: 1, LotteryOrder: ['gdb', 'g1', 'g2', 'g3', 'g4', 'g5', 'g6', 'g7', 'mdb'] },
  { domain_id: 2, LotteryOrder: [ 'g8', 'g7', 'g6', 'g5','g4', 'g3', 'g2', 'g1', 'gdb'] },
  { domain_id: 3, LotteryOrder: [ 'g8', 'g7', 'g6', 'g5','g4', 'g3', 'g2', 'g1', 'gdb'] },
]