import React, {useEffect, useState } from "react";

export default function TableFirstLastLotteryTrial({ dataStartEndLottery }) {
    const [dataStartLottery, setDataStartLottery] = useState(null);
    const [dataEndLottery, setDataEndLottery] = useState(null);

    useEffect(()=>{
        try{
            if(dataStartEndLottery) {
                setDataStartLottery(dataStartEndLottery.DataStartLottery);
                setDataEndLottery(dataStartEndLottery.DataEndLottery);
            } else {
                setDataStartLottery(null);
                setDataEndLottery(null);
            }
        } catch(error){console.log(error)}
    },[dataStartEndLottery])

    return (
        <>
            <div className="col-firstlast">
                <table className="firstlast-mb fl">
                    <tbody>
                        <tr className="header">
                            <th>Đầu</th>
                            <th>Đuôi</th>
                        </tr>
                        <tr>
                            <td className="clnote">0</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[0].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">1</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[1].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">2</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[2].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">3</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[3].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">4</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[4].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">5</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[5].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">6</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[6].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">7</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[7].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">8</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[8].Values.join(',')}</td>
                        </tr>
                        <tr>
                            <td className="clnote">9</td>
                            <td className="v-loto-dau-0">{dataStartLottery&&dataStartLottery[9].Values.join(',')}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="firstlast-mb fr">
                    <tbody>
                        <tr className="header">
                            <th>Đầu</th>
                            <th>Đuôi</th>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[0].Values.join(',')}</td>
                            <td className="clnote">0</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[1].Values.join(',')}</td>
                            <td className="clnote">1</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[2].Values.join(',')}</td>
                            <td className="clnote">2</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[3].Values.join(',')}</td>
                            <td className="clnote">3</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[4].Values.join(',')}</td>
                            <td className="clnote">4</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[5].Values.join(',')}</td>
                            <td className="clnote">5</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[6].Values.join(',')}</td>
                            <td className="clnote">6</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[7].Values.join(',')}</td>
                            <td className="clnote">7</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[8].Values.join(',')}</td>
                            <td className="clnote">8</td>
                        </tr>
                        <tr>
                            <td className="v-loto-dau-0">{dataEndLottery&&dataEndLottery[9].Values.join(',')}</td>
                            <td className="clnote">9</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="clearfix"></div>
        </>
    );
}
 