import { toast } from 'react-toastify';
import * as Types from '../constants/Lottery';

// var data = localStorage.getItem('StatisticalDetail');
// data && (data = JSON.parse(data));
// var initialState = data || '';

var initialState = '';

const StatisticalDetail = (state = initialState, action) => {
	switch (action.type) {
		case Types.FETCH_STATISTICAL_DETAIL: {
			return state
		}
		case Types.FETCH_STATISTICAL_DETAIL_SUCCESS: {
			// const { data } = action.payload; // { err: 0, msg: '', result: {...} } || { err: 2, msg: 'Ngày bạn chọn không đúng' }
			// state = {...data}
			// localStorage.setItem('StatisticalDetail', JSON.stringify(state));

			const { result } = action.payload.data;
			state = {...result}
			return state
		}
		case Types.FETCH_STATISTICAL_DETAIL_FAILED: {
			const { error } = action.payload;
			console.log(error)
            state = ''
			toast.error('Không thể lấy dữ liệu!');
			// toast.error(error.msg || error.message);
			return state
		}
		default: return state
	}
}

export default StatisticalDetail;