import React, { useEffect, useState } from "react";

const MIN = 1, MAX = 1000;
export default function GroupNumberPlusMinus(props) {
    
	const {valuePlusMinus, setValuePlusMinus} = props


	function handleChangeDown() {
		let value = parseInt(valuePlusMinus);
		if(value && value > 1) {
			value --
		} else {
			value = 1
		}
		setValuePlusMinus(value)
	}

	function handleChangeUp() {
		let value = parseInt(valuePlusMinus);
		if(value >= 1) {
			if(value < MAX) {
				value ++
			}
		} else {
			value = 1
		}
		setValuePlusMinus(value)
	}

	function handleChangeInput(event) {
		let value = parseInt(event.target.value);
		if(!value) {
			value = ''
		} else if(value >= MAX) {
			value = MAX
		}
		setValuePlusMinus(value)
	}
	
	return (
		<>
			<div className="input-group bootstrap-touchspin">
				<span className="input-group-btn">
					<button
						className="btn btn-default bootstrap-touchspin-down"
						type="button"
						disabled={valuePlusMinus <= MIN}
						onClick={handleChangeDown}
					>
					-
					</button>
				</span>
				<span
					className="input-group-addon bootstrap-touchspin-prefix"
					style={{ display: "none" }}
				/>
				<input
					type="value"
					id="count"
					className="form-control"
					name="count"
					// defaultValue={20}
					value={valuePlusMinus}
					style={{ display: "block" }}
					onChange={(e)=>handleChangeInput(e)}
				/>
				<span className="input-group-addon bootstrap-touchspin-postfix">
					{" "}
					ngày
				</span>
				<span className="input-group-btn">
					<button
						className="btn btn-default bootstrap-touchspin-up"
						type="button"
						disabled={valuePlusMinus === MAX}
						onClick={handleChangeUp}
					>
					+
					</button>
				</span>
			</div>
		</>
	);
}

