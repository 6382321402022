import React, { Component, useEffect, useState } from "react";
import moment from 'moment'

import { renderLotteryHTMLToJSX } from "../../../helpers";
import { handleOnclickStatisticalTableCell, showStatisticalByFilterNumberSelector } from "../helpers";


export function TableLotteryFrequency(props) {
    const { Statistical, filterParams, setFilterParamsPopup, handleOpenPopup, filterNumberData } = props



    // function in HTML element: show_kq_modal('#result_modal', 'mb', '2021-10-19', "['01']")
    // const modalElementId = '#result_modal',
    //     code = 'mb',
    //     date = '31-08-2021',
    //     array = ['01'];
    // function show_kq_modal(modalElementId, code, date, array ){
    //     setFilterParamsPopup({ code: 'mb', date: '31-08-2021' })
    // }

    useEffect(()=>{
        if(Statistical) {
            // Xử lý sự kiện onClick mở popup detail lottery:
            handleOnclickStatisticalTableCell();
        }
    },[Statistical])

    useEffect(()=>{
        if(filterNumberData) {
            // update table statistical on filter number
            showStatisticalByFilterNumberSelector(filterNumberData);

            // const rowElementArray = document.querySelectorAll('.box-statistical-lottery table tbody tr');
            // if(rowElementArray && rowElementArray.length > 0) {
            //     rowElementArray.forEach(item => {
            //         if(filterNumberData.find(e => e === item.firstChild.innerText)) {
            //             item.style.display = "table-row"
            //         } else {
            //             item.style.display = "none"
            //         }
            //     });
            // }
        }
    },[filterNumberData])

	
    return (
        <>
            {/* <h3>
                <p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>
                    Thống kê tần suất loto Truyền Thống trong vòng {filterPara                                                                                                                                                                                                                       ms.count || '...'} ngày trước {toDay}
                </p>
            </h3> */}
            
            <div className="scroll" className="box-statistical-lottery control-handle-click">
                {Statistical && renderLotteryHTMLToJSX(Statistical)}
            </div>

        </>
    );
}




// class TableLotteryFrequency extends Component {
// 	render() {
//         const {Statistical, filterParams} = this.props
//         const toDay = moment(Date.now()).format('DD/MM/YYYY');
        
// 		return (
// 			<div className="kqbackground vien">
//                 <h3>
//                     <p className="kqcenter kqbackground nendosam mautrang chu16 viethoa" style={{padding:'2px'}}>
//                         Thống kê tần suất loto Truyền Thống trong vòng {filterParams.count || '...'} ngày trước {toDay}
//                     </p>
//                 </h3>
                
//                 <div className="scroll">
//                     {Statistical && renderLotteryHTMLToJSX(Statistical)}
//                 </div>

//             </div>
// 		);
// 	}

// }



// export default TableLotteryFrequency;
