import React, { useState } from "react";
import { DATA_CHECKBOX_LOTTERY_PAIR, CHAM_ARRAY } from "../../helpers/constans";


export default function FilterNumberSelectorLotteryPair(props) {
    
	const {filterNumberData, setFilterNumberData} = props

	const [dataCheckbox, setDataCheckbox] = useState(DATA_CHECKBOX_LOTTERY_PAIR)
	

	function handleClickButton(value){
		try {
			let dataCheckboxTemp = [...dataCheckbox]; // data bảng checkbox
			let filterNumberDataTemp = []; // data để filter bảng Statistical lottery

			/**
			 * logic "Chạm":
			 * vd: chạm 3 = các cặp số có chứa chữ số 3
			 */ 
			if(value.indexOf('cham') >= 0) { // value: "cham_0", "cham_1", ... , "cham_9"
				let chamNum = value.substr(-1,1); // get the last character
				dataCheckboxTemp.map(item=>{
					if(item.value.indexOf(chamNum) >= 0) {
						filterNumberDataTemp.push(item.value);
						return item.checked = true;
					} else {
						return item.checked = false;
					}
				})
	
			} else {
				switch (value) {
					case 'no':
							filterNumberDataTemp = [];

							dataCheckboxTemp.map(item=>{
								return item.checked = false;
							})
						break;

					default: // "all": all checked true
						dataCheckboxTemp.map(item=>{
							filterNumberDataTemp.push(item.value);
							return item.checked = true;
						})
						break;
				}
			}
	
			setDataCheckbox(dataCheckboxTemp);
			setFilterNumberData(filterNumberDataTemp)

		} catch (error) {
			console.log(error);
		}
	}


	function handleChangeCheckbox(event, index){
		try {
			// set data bảng checkbox
			let dataCheckboxTemp = [...dataCheckbox];
			dataCheckboxTemp[index].checked = event.target.checked;
			setDataCheckbox(dataCheckboxTemp)

			// set data để filter bảng Statistical lottery
			let filterNumberDataTemp = [...filterNumberData];
			if(event.target.checked) {
				filterNumberDataTemp.push(event.target.value)
			} else {
				filterNumberDataTemp = filterNumberDataTemp.filter(e => e !== event.target.value); // xóa phần tử bị bỏ chọn
			}
			setFilterNumberData(filterNumberDataTemp)
		} catch (error) {
			console.log(error);
		}
	}

	function renderCheckboxTable(dataCheckbox) {
		try {
			/**
			 * Mô tả mảng data render:
			 * Có 50 cặp loto
			 * Mỗi dòng 10 cặp loto
			 */ 
			let dataCheckboxTemp = [
				dataCheckbox.slice(0, 10),
				dataCheckbox.slice(10, 20),
				dataCheckbox.slice(20, 30),
				dataCheckbox.slice(30, 40),
				dataCheckbox.slice(40, 50),
			];
			
			return dataCheckboxTemp.map((data, index) =>{
				return (
					<tr key={index}>
						{
							data.map(item => {
								return (
									<td key={item.id}>
										<label id={`lb_${item.value}`}>
										<input
											type="checkbox"
											className="number_indi"
											id={`cb_${item.value}`}
											value={item.value}
											checked={item.checked}
											onChange={(e)=>handleChangeCheckbox(e, item.id)}
										/>
											{` ${item.value}`}
										</label>
									</td>
								)
							})
						}
					</tr>
				)
			})
		} catch (error) {
			console.log(error);
		}
	}
	
	
	return (
		<>
			<table
				id="number_selector"
				className="kq-table-hover table table-condensed kqcenter kqbackground border"
			>
				<thead>
					<tr className="info">
						<th colSpan={10}>
							<button className="btn btn-default col-sm-2 col-sm-offset-2" value='all' onClick={()=>handleClickButton('all')}>Tất cả các cặp số</button>
							<button className="btn btn-default col-sm-2 col-sm-offset-1" value="no" onClick={()=>handleClickButton('no')}>Không cặp nào</button>
						</th>
					</tr>
				</thead>

				<tbody> 
					{dataCheckbox && dataCheckbox.length > 0 && renderCheckboxTable(dataCheckbox)}
					
					<tr  className="info">
						{
							CHAM_ARRAY.map(number => {
								return (
									<td key={`cham-${number}`}>
										<button className="btn btn-default" onClick={()=>handleClickButton(`cham_${number}`)}>Chạm {number}</button>
									</td>
								)
							})
						}
					</tr>

				</tbody>
			</table>
		</>
	);
}

