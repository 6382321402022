import React, { Component } from "react";
// import ReactHtmlParser from 'react-html-parser';
import { renderLotteryHTMLToJSX } from "../../../helpers";



class TableStatisticalHomePage extends Component {
    // showData = (data) =>{
    //     let result
    //     if(data){
    //         result = ReactHtmlParser(data)
    //     }
    //     return result
    // }
	render() {
        const {Statistical} = this.props
		return (
			<div className="kqbackground vien">
                <div className="kqbackground">
                    <p style={{textAlign: 'center' , color: '#990000' }}>
                        <span style={{fontSize: '21px' }}>
                            Thống kê nhanh Truyền Thống
                        </span>
                    </p>
                </div>
                {/* {this.showData(Statistical.content)} */}
                {Statistical && renderLotteryHTMLToJSX(Statistical.content)}
            </div>
		);
	}
}



export default TableStatisticalHomePage;
