export const LayoutTableLoadingMB = `
<div data-id="kq" class="one-city" data-region={1}>
<table class="kqmb extendable">
  <tbody>
    <tr>
      <td colSpan={13} class="v-giai madb">
        <span class="v-madb imgloadig"></span>
      </td>
    </tr>
    <tr class="db">
      <td class="txt-giai">ĐB</td>
      <td class="v-giai number "><span data-nc={5} class="v-gdb imgloadig"></span></td>
    </tr>
    <tr>
      <td class="txt-giai">Giải 1</td>
      <td class="v-giai number">
        <span data-nc={5} class="v-g1 imgloadig"></span>
      </td>
    </tr>
    <tr class="bg_ef">
      <td class="txt-giai">Giải 2</td>
      <td class="v-giai number">
        <span data-nc={5} class="v-g2-0 imgloadig"></span>
        <span data-nc={5} class="v-g2-1 imgloadig"></span>
      </td>
    </tr>
    <tr>
      <td class="txt-giai">Giải 3</td>
      <td class="v-giai number">
        <span data-nc={5} class="v-g3-0 imgloadig"></span>
        <span data-nc={5} class="v-g3-1 imgloadig"></span>
        <span data-nc={5} class="v-g3-2 imgloadig"></span>
        <span data-nc={5} class="v-g3-3 imgloadig"></span>
        <span data-nc={5} class="v-g3-4 imgloadig"></span>
        <span data-nc={5} class="v-g3-5 imgloadig"></span>
        
      </td>
    </tr>
    <tr class="bg_ef">
      <td class="txt-giai">Giải 4</td>
      <td class="v-giai number">
        <span data-nc={4} class="v-g4-0 imgloadig"></span>
        <span data-nc={4} class="v-g4-1 imgloadig"></span>
        <span data-nc={4} class="v-g4-2 imgloadig"></span>
        <span data-nc={4} class="v-g4-3 imgloadig"></span>
        
      </td>
    </tr>
    <tr>
      <td class="txt-giai">Giải 5</td>
      <td class="v-giai number">
        <span data-nc={4} class="v-g5-0 imgloadig"></span>
        <span data-nc={4} class="v-g5-1 imgloadig"></span>
        <span data-nc={4} class="v-g5-2 imgloadig"></span>
        <span data-nc={4} class="v-g5-3 imgloadig"></span>
        <span data-nc={4} class="v-g5-4 imgloadig"></span>
        <span data-nc={4} class="v-g5-5 imgloadig"></span>
        
      </td>
    </tr>
    <tr class="bg_ef">
      <td class="txt-giai">Giải 6</td>
      <td class="v-giai number">
        <span data-nc={3} class="v-g6-0 imgloadig"></span>
        <span data-nc={3} class="v-g6-1 imgloadig"></span>
        <span data-nc={3} class="v-g6-2 imgloadig"></span>
        
      </td>
    </tr>
    <tr class="g7">
      <td class="txt-giai">Giải 7</td>
      <td class="v-giai number"><span data-nc={2} class="v-g7-0 imgloadig"></span>
        <span data-nc={2} class="v-g7-1 imgloadig"></span>
        <span data-nc={2} class="v-g7-2 imgloadig"></span>
        <span data-nc={2} class="v-g7-3 imgloadig"></span>
      </td>
    </tr>
  </tbody>
</table>
</div>
  `;

export const LayoutTableLoadingMN = `
<table class="kqmb extendable kqtinh"><tbody><tr class="g8"><td class="txt-giai">Giải 8</td><td class="v-giai number"><span data-nc="2" class="v-g8 imgloadig"></span></td></tr><tr class="bg_ef"><td class="txt-giai">Giải 7</td><td class="v-giai number"><span data-nc="3" class="v-g7 imgloadig"></span></td></tr><tr><td class="txt-giai">Giải 6</td><td class="v-giai number"><span data-nc="4" class="v-g6-0 imgloadig"></span><span data-nc="4" class="v-g6-1 imgloadig"></span><span data-nc="4" class="v-g6-2 imgloadig"></span></td></tr><tr class="bg_ef"><td class="txt-giai">Giải 5</td><td class="v-giai number"><span data-nc="4" class="v-g5 imgloadig"></span></td></tr><tr class="g4"><td class="titgiai">Giải 4</td><td class="v-giai number"><span data-nc="5" class="v-g4-0 imgloadig"></span><span data-nc="5" class="v-g4-1 imgloadig"></span><span data-nc="5" class="v-g4-2 imgloadig"></span><span data-nc="5" class="v-g4-3 imgloadig"></span><span data-nc="5" class="v-g4-4 imgloadig"></span><span data-nc="5" class="v-g4-5 imgloadig"></span><span data-nc="5" class="v-g4-6 imgloadig"></span></td></tr><tr class="bg_ef"><td class="txt-giai">Giải 3</td><td class="v-giai number"><span data-nc="5" class="v-g3-0 imgloadig"></span><span data-nc="5" class="v-g3-1 imgloadig"></span></td></tr><tr><td class="txt-giai">Giải 2</td><td class="v-giai number"><span data-nc="5" class="v-g2 imgloadig"></span></td></tr><tr class="bg_ef"><td class="txt-giai">Giải 1</td><td class="v-giai number"><span data-nc="5" class="v-g1 imgloadig"></span></td></tr><tr class="gdb db"><td class="txt-giai">ĐB</td><td class="v-giai number"><span data-nc="6" class="v-gdb imgloadig"></span></td></tr></tbody></table>
  `;