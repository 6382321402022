import * as lotteryApi from '../apis/Lottery';
import * as lotteryConstants from './../constants/Lottery';
import * as loadingConstants from './../constants/Loading';
import { createNewFilterParams ,createUrlTo } from "../helpers/check";
import { CreateParamsforApi  } from "../helpers/index";



// const API_ENDPOINT = 'lottery_prize?fields=["LotteryResultItem.LotteryResult.Location.name","LotteryResultItem.LotteryResult.Location.Location.name","LotteryResultItem.LotteryResult.day", "LotteryResultItem.LotteryResult.month", "LotteryResultItem.LotteryResult.year", "LotteryResultItem.LotteryResult.weekday", "LotteryResultItem.LotteryResult.Location.name","LotteryResultItem.value", "name", "lottery_type_id", "LotteryResultItem.LotteryResult.location_id"]';
const API_ENDPOINT = 'lottery_result?fields=["id","day", "month", "year","Location.name","xoso", "dauso","duoiso" , "Location.Weekday.name", "created_time"]'
export const toggleLoading = (toggle) => {
	return {
		type: loadingConstants.TOGGLE_LOADING_TABLE,
		payload: {
			isLoading: toggle
		}
	}
}


export const fetchEmptyListLottery = (filterParams) => {
	const newFilterParams = createNewFilterParams(filterParams);
	const searchLottery = CreateParamsforApi(filterParams)
	
	return dispatch =>{
		dispatch(fetchListLotteryRequest(searchLottery, newFilterParams))
	}
	// let url = createUrlTo(newFilterParams)
	// window.location=url;
}

// lottery

export const fetchListLottery = () => {
	return {
		type: lotteryConstants.FETCH_TABLE,
	}
}
// let countCallData = 0
// export const fetchListLotterySuccess = (data, filterParams) => {
// 	countCallData++
// 	if (data.data.rows.length === 0 && countCallData < 7) {
// 		return dispatch =>{
// 			dispatch(fetchEmptyListLottery(filterParams))
// 		}
		
// 	} else {
// 		return {
// 			type: lotteryConstants.FETCH_TABLE_SUCCESS,
// 			filterParams: filterParams,
// 			payload: {
// 				data
// 			}
// 		}
// 	}

// }
export const fetchListLotterySuccess = (data, filterParams) => {
	return {
		type: lotteryConstants.FETCH_TABLE_SUCCESS,
		filterParams: filterParams,
		payload: {
			data
		}
	}
}
export const fetchListLotteryFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_FAILED,
		payload: {
			error
		}
	}
}
export const fetchListLotteryRequest = (id, filterParams) => {
	let params = id ? id : ''
	return dispatch => {
		dispatch(fetchListLottery())
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;	
			dispatch(fetchListLotterySuccess(data, filterParams));
		}).catch(error => {
			console.log(error)
			dispatch(fetchListLotteryFailed(error));
		});
	}
}



// lottery RealTime
export const fetchListLotteryRealtime = () => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME,
	}
}
// export const fetchListLotteryRealtimeSuccess = (data, filterParams) => {
// 	countCallData++
// 	if (data.data.rows.length === 0 && countCallData < 7) {
// 		return dispatch =>{
// 			dispatch(fetchEmptyListLottery(filterParams))
// 		}
		
// 	} else {
// 		return {
// 			type: lotteryConstants.FETCH_TABLE_SUCCESS,
// 			filterParams: filterParams,
// 			payload: {
// 				data
// 			}
// 		}
// 	}

// }
export const fetchListLotteryRealtimeSuccess = (data, filterParams) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_SUCCESS,
		filterParams,
		payload: {
			data
		}
	}
}
export const fetchListLotteryRealtimeFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_FAILED,
		payload: {
			error
		}
	}
}
export const fetchListLotteryRealtimeRequest = (id, filterParams) => {
	let params = id ? id : ''
	return dispatch => {
		dispatch(fetchListLotteryRealtime())
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;
			// dispatch(toggleLoading(false))
			dispatch(fetchListLotteryRealtimeSuccess(data, filterParams));
			// console.log(data)
		}).catch(error => {
			// dispatch(toggleLoading(true))
			console.log(error)

			dispatch(fetchListLotteryRealtimeFailed(error));
		});
	}
}


// Statistical


// export const fetchStatisticalSuccess = (data) => {
// 	return {
// 		type: lotteryConstants.FETCH_STATISTICAL_SUCCESS,
// 		payload: {
// 			data
// 		}
// 	}
// }
// export const fetchStatisticalFailed = (error) => {
// 	return {
// 		type: lotteryConstants.FETCH_STATISTICAL_FAILED,
// 		payload: {
// 			error
// 		}
// 	}
// }
// export const fetchStatisticalRequest = () => {
// 	// let params = 'lottery_crawl/1' // 1. Thống kê trang chủ
// 	// let params = 'lottery_crawl/tan-suat-loto?code=mb&count=55&date=11-8-2021' // 2. Thống kê tần suất lô tô
// 	// let params = 'lottery_crawl/3' // 3. Thống kê tần suất cặp loto
// 	let params = 'lottery_crawl?fields=["name","lottery_crawl_type_id","content","location_id"]&filter={"lottery_crawl_type_id": 4, "location_id": 5}&limit=1' // 4. Thống kê tần suất loto theo Đặc Biệt
// 	return dispatch => {
// 		lotteryApi.getList(params).then(resp => {
// 			console.log('resp: ',resp);
// 			const { data } = resp;
// 			console.log('data: ',data);

// 			dispatch(fetchStatisticalSuccess(data));
// 		}).catch(error => {
// 			dispatch(fetchStatisticalFailed(error));
// 		});
// 	}
// }




// Start lottery


export const fetchStartListLotterySuccess = (data) => {
	return {
		type: lotteryConstants.FETCH_TABLE_START_SUCCESS,
		payload: {
			data
		}
	}
}
export const fetchStartListLotteryFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_START_FAILED,
		payload: {
			error
		}
	}
}
export const fetchStartListLotteryRequest = (id) => {
	let params = id ? id : ''
	return dispatch => {
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;
			dispatch(fetchStartListLotterySuccess(data));
		}).catch(error => {
			dispatch(fetchStartListLotteryFailed(error));
		});
	}
}




// End lottery


export const fetchEndListLotterySuccess = (data) => {
	return {
		type: lotteryConstants.FETCH_TABLE_END_SUCCESS,
		payload: {
			data
		}
	}
}
export const fetchEndListLotteryFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_END_FAILED,
		payload: {
			error
		}
	}
}
export const fetchEndListLotteryRequest = (id) => {
	let params = id ? id : ''
	return dispatch => {
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;
			dispatch(fetchEndListLotterySuccess(data));
		}).catch(error => {
			dispatch(fetchEndListLotteryFailed(error));
		});
	}
}




// Start lottery realtime


export const fetchStartListLotteryRealtimeSuccess = (data) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_START_SUCCESS,
		payload: {
			data
		}
	}
}
export const fetchStartListLotteryRealtimeFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_START_FAILED,
		payload: {
			error
		}
	}
}
export const fetchStartListLotteryRealtimeRequest = (id) => {
	let params = id ? id : ''
	return dispatch => {
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;
			dispatch(fetchStartListLotteryRealtimeSuccess(data));
		}).catch(error => {
			dispatch(fetchStartListLotteryRealtimeFailed(error));
		});
	}
}




// End lottery realtime


export const fetchEndListLotteryRealtimeSuccess = (data) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_END_SUCCESS,
		payload: {
			data
		}
	}
}
export const fetchEndListLotteryRealtimeFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_TABLE_REALTIME_END_FAILED,
		payload: {
			error
		}
	}
}
export const fetchEndListLotteryRealtimeRequest = (id) => {
	let params = id ? id : ''
	return dispatch => {
		lotteryApi.getList(API_ENDPOINT + params).then(resp => {
			const { data } = resp;
			dispatch(fetchEndListLotteryRealtimeSuccess(data));
		}).catch(error => {
			dispatch(fetchEndListLotteryRealtimeFailed(error));
		});
	}
}