import LotteryPairFrequencyContainer from "../containers/statistical-container/LotteryPairFrequencyContainer";
import LotteryFrequencyContainer from "../containers/statistical-container/LotteryFrequencyContainer";
import CycleContainer from "../containers/statistical-container/CycleContainer";
import LotteryNearContainer from "../containers/statistical-container/LotteryNearContainer";
import LotterySpecialContainer from "../containers/statistical-container/LotterySpecialContainer";

import FormFilterLotteryFrequency from "../components/statistical/filter/form/components/FormFilterLotteryFrequency";
import FormFilterLotteryNear from "../components/statistical/filter/form/components/FormFilterLotteryNear";

// Statistical constant
export const HOME_PAGE = {  // Thống kê nhanh TruyềnThống: thống kê trên trang chủ, gán trực tiếp vào trang chủ, ko xét slug
    id: 1, 
    slug: "", 
    title: "Thống kê nhanh TruyềnThống",
    lottery_crawl_type_id: 1,
};

export const TAN_SUAT_LOTO = {
    id: 2, 
    slug: "tan-suat-loto", 
    title: "Thống kê tần suất lô tô",
    lottery_crawl_type_id: '',
    getStatisticalComponent: function(statistical){ return <LotteryFrequencyContainer currentStatistical={statistical} />},
    getFormFilterComponent: function(props){ return <FormFilterLotteryFrequency props={props} />},
}; 

export const TAN_SUAT_CAP_LOTO = { 
    id: 3, 
    slug: "tan-suat-cap-loto", 
    title: "Thống kê tần suất cặp loto",
    lottery_crawl_type_id: 3,
    getStatisticalComponent: function(statistical){ return <LotteryPairFrequencyContainer currentStatistical={statistical} />},
    getFormFilterComponent: function(props){ return <FormFilterLotteryFrequency props={props} />},
};

export const TAN_SUAT_LOTO_DB = {
    id: 4, 
    slug: "loto-theo-db", 
    title: "Thống kê tần suất loto theo Đặc Biệt", 
    lottery_crawl_type_id: 4,
    getStatisticalComponent: function(statistical){ return <LotterySpecialContainer currentStatistical={statistical} />},
}; 

export const CHU_KY = {
    id: 5, 
    slug: "chu-ky", 
    title: "Chu kỳ lô tô", 
    lottery_crawl_type_id: 6,
    getStatisticalComponent: function(statistical){ return <CycleContainer currentStatistical={statistical} />},
};

export const LOTO_GAN = {
    id: 6, 
    slug: "loto-gan", 
    title: "Thống kê loto gan", 
    lottery_crawl_type_id: '',
    getStatisticalComponent: function(statistical){ return <LotteryNearContainer currentStatistical={statistical} />},
    getFormFilterComponent: function(props){ return <FormFilterLotteryNear props={props} />},
};

export const POPUP = { 
    id: 7,
    is_popup: true,
    slug: "", 
    title: "Xem kết quả cụ thể", 
    pageComponent: null,
    Api: { 
        url: "lottery_crawl/popup-xoso", 
        param: { code: 'mb', date: '31-08-2021' }, 
        filter: false,
    }
};



export const STATISTICAL_ARRAY = [
    TAN_SUAT_LOTO,
    TAN_SUAT_CAP_LOTO,
    TAN_SUAT_LOTO_DB,
    CHU_KY,
    LOTO_GAN,
    // POPUP,
]

export const STATISTICAL_OBJECT = {
    TAN_SUAT_LOTO: TAN_SUAT_LOTO,
    TAN_SUAT_CAP_LOTO: TAN_SUAT_CAP_LOTO,
    TAN_SUAT_LOTO_DB: TAN_SUAT_LOTO_DB,
    CHU_KY: CHU_KY,
    LOTO_GAN: LOTO_GAN,
    // POPUP: POPUP,
}