export const InitDataStartEndLottery = [
    {name: 0, Values: []},
    {name: 1, Values: []},
    {name: 2, Values: []},
    {name: 3, Values: []},
    {name: 4, Values: []},
    {name: 5, Values: []},
    {name: 6, Values: []},
    {name: 7, Values: []},
    {name: 8, Values: []},
    {name: 9, Values: []},
]

export function renderStartEndValue(DataLottery){
    try {
        let DataLotterySort2 = [];
        DataLottery.map(item=> {
            if(typeof item === 'number') {
                DataLotterySort2.push(item.toString().substr(item.toString().length-2,2));
            }
        });

        let tempStart = [
            {name: 0, Values: []},
            {name: 1, Values: []},
            {name: 2, Values: []},
            {name: 3, Values: []},
            {name: 4, Values: []},
            {name: 5, Values: []},
            {name: 6, Values: []},
            {name: 7, Values: []},
            {name: 8, Values: []},
            {name: 9, Values: []},
        ];
        let tempEnd = [
                {name: 0, Values: []},
                {name: 1, Values: []},
                {name: 2, Values: []},
                {name: 3, Values: []},
                {name: 4, Values: []},
                {name: 5, Values: []},
                {name: 6, Values: []},
                {name: 7, Values: []},
                {name: 8, Values: []},
                {name: 9, Values: []},
            ];

        DataLotterySort2.map(item=>{
            let start = item.substr(0,1);
            let end = item.substr(1,1);
            tempStart.some((e,i)=>{
                if(e.name.toString() === start) {
                    tempStart[i].Values.push(end);
                    return true;
                }
            })
            tempEnd.some((e,i)=>{
                if(e.name.toString() === end) {
                    tempEnd[i].Values.push(start);
                    return true;
                }
            })
        })

        // sort increase
        tempStart.map(item=> {
            if(item.Values.length>1) {
                item.Values = item.Values.sort((a, b)=>{return a - b})
            }
        })
        tempEnd.map(item=> {
            if(item.Values.length>1) {
                item.Values = item.Values.sort((a, b)=>{return a - b})
            }
        })

        return {DataStartLottery: tempStart, DataEndLottery: tempEnd};
    } catch(error){console.log(error)}
}