import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from 'moment'


/* Đài đề xuất quay thử (tham khảo xoso.me ngày 25/09/021):
** Miền Bắc: Nam Định.
** Miền Trung: ...
** Miền Nam: ...
*/
const PopularProvince = [
  {id: 1, ProvinceId: [29]},
  {id: 2, ProvinceId: [33,32,31]},
  {id: 3, ProvinceId: [7,6,4,5]},
]

export default function TrialBox({
  setLotteryTrial, 
  setDataStartEndLottery,
  filterParams, setFilterParams,
  DataProvince
}) {
  const history = useHistory();
  const today = moment(Date.now()).format('DD-MM-YYYY');
  const [area, setArea] = useState(null);
  const [options, setOptions] = useState(null);
  const [province, setProvince] = useState('');
  const [popularProvince, setPopularProvince] = useState(null);

  useEffect(()=>{
		try {
      if(DataProvince){
        if(!area || (filterParams.domain_id !== area.id)) {
          setArea(DataProvince.find(e=>e.id === filterParams.domain_id))
          setProvince('')
          setPopularProvince(null)
        }
      }
			
		} catch(error){console.log(error)}
  },[DataProvince, filterParams])

  useEffect(()=>{
		try {
      if(area){
        setOptions(area.Province)
        setProvince('')

        let popularProvinceId = PopularProvince.find(e=>e.id === area.id);
        let popularProvinceTemp = [];
        area.Province.filter(province=>{
          popularProvinceId.ProvinceId.map(id=>{
            (id === province.id) && popularProvinceTemp.push(province);
          })
        })
        setPopularProvince(popularProvinceTemp);
      }
		} catch(error){console.log(error)}
  },[area])

  function handleChangeProvince(id) {
    let province_id = parseInt(id);
    let area = DataProvince.find(e=>e.id === filterParams.domain_id);
    let province = area.Province.find(e=>e.id === province_id);
    
    setProvince(province);
    setFilterParams({...filterParams, province: province_id})
    history.push(filterParams.pathName + "?mien=" + area.id + "&tinh=" + province.id)
  }

	return (
    <>
      <div className="tit-mien clearfix">
        <h2>Quay thử {(province&&province.name) || (area&&area.name)} ngày {today}</h2>
      </div>

      <div className="txt-center  bg-orange">
        <div id="trial_form" className="form-horizontal">
          {/* <input type="hidden" name="_csrf"/> */}
          <div className="form-group">
            <select id="province_name" name="province_name" value={province&&province.id} onChange={(e)=>handleChangeProvince(e.target.value)}>
            <option value="" disabled>Chọn đài quay thử</option>
              {options&&options.map((item, i)=>{
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="form-group txt-center">
            <button 
              type="button" 
              className="btn btn-danger trial-button"
              // disabled={lotteryTrial.status}
              onClick={()=> {
                setDataStartEndLottery({DataLottery: [], DataStartLottery: null, DataEndLottery: null});
                setLotteryTrial({status: true, time: 3000});
              }}
              >Bắt đầu quay</button>
            <button 
              type="button" 
              className="btn btn-light trial-button"
              // disabled={lotteryTrial.status}
              onClick={()=> {
                setDataStartEndLottery({DataLottery: [], DataStartLottery: null, DataEndLottery: null});
                setLotteryTrial({status: true, time: 100});
              }}
              style={{marginLeft: "4px"}}
              >Quay nhanh</button>
          </div>
          <div className="form-group txt-center">Quay thử đài: 
              {popularProvince&&popularProvince.map((item,i)=>{
                return (
                  <a
                    key={i}
                    className="item_sublink mag-l5" 
                    to={filterParams.pathName + "?mien=" + area.id + "&tinh=" + item.id}
                    onClick={()=>handleChangeProvince(item.id)}
                  >
                    {item.name}
                  </a>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}