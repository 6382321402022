import React, { useEffect, useState } from "react";

export default function TableMN({ dataStartEndLottery, dataLotteryFilter }) {

    const [dataLottery, setDataLottery] = useState(null);

    useEffect(()=>{
		try{
			if(dataLotteryFilter) {
				setDataLottery(dataLotteryFilter);
			} else {
				setDataLottery([]);
			}
		} catch(error){console.log(error)}
	},[dataLotteryFilter])

	// useEffect(()=>{
	// 	try{
    //         if(dataStartEndLottery) {
    //             setDataLottery(dataStartEndLottery.DataLottery);
    //         } else {
    //             setDataLottery([]);
    //         }
            
    //     } catch(error){console.log(error)}
    // },[dataStartEndLottery])

    return (
        <table className="kqmb extendable kqtinh">
            <tbody>
                <tr className="g7">
                    <td className="txt-giai">Giải 8</td>
                    <td className="v-giai number"><span className={"v-g8-0" + (dataLottery&&dataLottery[0] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[0]) ||''}</span></td>
                </tr>
                <tr className="bg_ef">
                    <td className="txt-giai">Giải 7</td>
                    <td className="v-giai number"><span className={"v-g7-0" + (dataLottery&&dataLottery[1] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[1]) ||''}</span></td>
                </tr>
                <tr>
                    <td className="txt-giai">Giải 6</td>
                    <td className="v-giai number">
                        <span className={"v-g6-0" + (dataLottery&&dataLottery[2] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[2]) ||''}</span>
                        <span className={"v-g6-1" + (dataLottery&&dataLottery[3] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[3]) ||''}</span>
                        <span className={"v-g6-2" + (dataLottery&&dataLottery[4] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[4]) ||''}</span>
                    </td>
                </tr>
                <tr className="bg_ef">
                    <td className="txt-giai">Giải 5</td>
                    <td className="v-giai number"><span className={"v-g5" + (dataLottery&&dataLottery[5] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[5]) ||''}</span></td>
                </tr>
                <tr>
                    <td className="txt-giai">Giải 4</td>
                    <td className="v-giai number">
                        <span className={"v-g4-0" + (dataLottery&&dataLottery[6] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[6]) ||''}</span>
                        <span className={"v-g4-1" + (dataLottery&&dataLottery[7] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[7]) ||''}</span>
                        <span className={"v-g4-2" + (dataLottery&&dataLottery[8] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[8]) ||''}</span>
                        <span className={"v-g4-3" + (dataLottery&&dataLottery[9] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[9]) ||''}</span>
                        <span className={"v-g4-4" + (dataLottery&&dataLottery[10] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[10]) ||''}</span>
                        <span className={"v-g4-5" + (dataLottery&&dataLottery[11] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[11]) ||''}</span>
                        <span className={"v-g4-6" + (dataLottery&&dataLottery[12] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[12]) ||''}</span>
                        </td>
                </tr>
                <tr className="bg_ef">
                    <td className="txt-giai">Giải 3</td>
                    <td className="v-giai number">
                        <span className={"v-g3-0" + (dataLottery&&dataLottery[13] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[13]) ||''}</span>
                        <span className={"v-g3-1" + (dataLottery&&dataLottery[14] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[14]) ||''}</span>
                    </td>
                </tr>
                <tr>
                    <td className="txt-giai">Giải 2</td>
                    <td className="v-giai number"><span className={"v-g2" + (dataLottery&&dataLottery[15] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[15]) ||''}</span></td>
                </tr>
                <tr className="bg_ef">
                    <td className="txt-giai">Giải 1</td>
                    <td className="v-giai number"><span className={"v-g1-0" + (dataLottery&&dataLottery[16] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[16]) ||''}</span></td>
                </tr>
                <tr className="db">
                    <td className="txt-giai">ĐB</td>
                    <td className="v-giai number"><span className={"v-g0-0" + (dataLottery&&dataLottery[17] ? '' : ' imgloadig')} >{(dataLottery&&dataLottery[17]) ||''}</span></td>
                </tr>
            </tbody>
        </table>
    
    );
}