
import React, { Component } from "react";
import { ToastBar } from "../components/commons/ToastBar";
// import FormFilter from "../components/statistical/filter/form/FormFilter";


class Statistical extends Component {
    render () {
        const {match, currentStatistical} = this.props

        return (
            <>
                {/* <FormFilter currentStatistical={currentStatistical} /> */}
                { currentStatistical && currentStatistical.getStatisticalComponent(currentStatistical) }
                {/* <PopupStatisticalDetail /> */}
                <ToastBar />
            </>
        );
    }
}


export default Statistical

