import React, { Component, useEffect, useState } from "react";
import { DATA_FILTER_ALL_NUMBER_SET } from "../../helpers/constans";

// filter Bộ số
export default function FilterNumberSet(props) {
    
	const {title, isReset, filterNumberData, setFilterNumberData} = props

	const [inputValue, setInputValue] = useState('')

	useEffect(()=>{
		setInputValue('')
	}, [isReset])

	function handleChangeInput(event){
		try {
			const value = event.target.value.trim();
			setInputValue(event.target.value)

			// set data để filter bảng Statistical lottery
			let filterNumberDataTemp = [];

			if(value.length === 0) { // input trống => filter all
				filterNumberDataTemp = DATA_FILTER_ALL_NUMBER_SET;

			} else if(value.length > 0) {
				let arrayValuesSplitBySpace = value.split(" ");
				// arrayValues = value.split(",");
	
				/**
				 * Xóa phần tử rỗng nếu có (do nhập nhiều dấu cách).
				 * split(",") từng phần tử trong mảng arrayValuesSplitBySpace nếu có.
				 * Xóa phần tử rỗng phát sinh sau khi split(",") nếu có.
				 * Lưu kết quả vào mảng arrayValuesSplitByComma.
				 */ 
				const arrayValuesSplitByComma = []
				if(arrayValuesSplitBySpace && arrayValuesSplitBySpace.length >0) {
					arrayValuesSplitBySpace.map(item => {
						if(item) {
							if(item.indexOf(",") !== -1) {
								const arrayResult = item.split(",");
								arrayResult.map(itemChild => {
									itemChild && arrayValuesSplitByComma.push(itemChild)
								})
							} else {
								arrayValuesSplitByComma.push(item)
							}
						}
					})
				}
	
				/**
				 * Nếu phần tử có 1 ký tự -> thêm "0" ở trước
				 * Nếu phần tử có 2 ký tự -> giữ nguyên
				 * Nếu phần tử dài hơn 2 ký tự -> Lọc lấy 2 ký tự cuối
				 */ 
				filterNumberDataTemp = arrayValuesSplitByComma.map(item=>{ 
					if(item.length === 1) {
						return `0${item}`
					} else {
						return item.substr(-2)
					}
				})
			}
			
			setFilterNumberData(filterNumberDataTemp);

		} catch (error) {
			console.log(error);
		}
	}
	
	
	return (
		<>
			<div className="form-group">
				<label htmlFor="numbers" className="col-sm-2 control-label">{title || "Bộ số"}</label>
				<div className="col-sm-7">
					<textarea
						className="form-control"
						rows={2}
						id="numbers"
						name="numbers"
						type="Number"
						placeholder="Điền các bộ số bạn muốn xem (ngăn cách bằng dấu phẩy hoặc cách). Để trống để xem mọi bộ số"
						// defaultValue={""}
						onChange={(e)=>handleChangeInput(e)}
						value={inputValue}
					/>
				</div>
			</div>
		</>
	);
}

