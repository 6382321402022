import React, { useEffect, useState } from "react";
import moment from 'moment'

import { PopupTableLotteryMB } from "./table-lottery/PopupTableLotteryMB";
import { PopupTableLotteryMN } from "./table-lottery/PopupTableLotteryMN";
import { PopupTableStart } from "./table-lottery/PopupTableStart";

// Data test bảng Miền Nam, Miền Trung
// const DataMNTemp = {"0":["237535"],"1":["68867"],"2":["95423"],"3":["71228","28559"],"4":["40027","45074","01001","04378","57360","67865","31887"],"5":["3014"],"6":["7739","4321","2753"],"7":["087"],"8":["00"],"a":""}

const WEEKDAYS = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy']

export function PopupStatisticalDetail(props) {
	const { StatisticalDetail, filterParamsPopup, openPopup, handleClosePopup } = props
	const [data, setData] = useState(StatisticalDetail)
	const [dateObj, setDateObj] = useState({date: '...', weekday: '...'})


	useEffect(() => {
		if (StatisticalDetail) {
			setData(StatisticalDetail)
		}
	}, [StatisticalDetail])

	useEffect(() => {
		if (filterParamsPopup) {
			try {
				const dateArray = filterParamsPopup.date.split("-");
				let date = new Date(parseInt(dateArray[2]), parseInt(dateArray[1]-1), parseInt(dateArray[0]));
				let today = '...', weekday = '...';
				if(date) {
					today = moment(date).format('DD/MM/YYYY');
					weekday = WEEKDAYS[date.getDay()];
				}

				setDateObj({date: today, weekday: weekday})
			} catch (error) {
				console.log(error);
			}
		}
	}, [filterParamsPopup])

	function showTableDetail(filterParamsPopup) {
		const codeMB = ['mb', 'tth']; // danh sách các mã code thuộc miền Bắc: 'mb', 'tth',... => load mẫu bảng xổ số miền Bắc.

		// return <PopupTableLotteryMN StatisticalDetail={DataMNTemp} dateObj={dateObj} /> // test bảng Miền Nam

		if(filterParamsPopup && codeMB.find(e=> e === filterParamsPopup.code)) {
			return <PopupTableLotteryMB StatisticalDetail={data} dateObj={dateObj} />
		} else {
			return <PopupTableLotteryMN StatisticalDetail={data} dateObj={dateObj} />
		}
	}

	return (
		<>
		{openPopup && data &&
			<div
				id="result_modal"
				className="modal"
				tabIndex={-1}
				role="dialog"
				// style={{ display: "block", paddingRight: 17 }}
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title text-center">Xem kết quả cụ thể</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={handleClosePopup}
							>
								<span aria-hidden="true" style={{fontSize: '1.2rem'}}>&#10006;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="kqbackground vien tb-phoi">
								<div id="outer_result_mb">
									<div className="result_div " id="result_mb">
										<div className="row">
											<div className="col-sm-6 tb-phoi-6">
												<div className="color333">
													{ showTableDetail(filterParamsPopup) }
												</div>
											</div>
											<div className="col-sm-4 tb-phoi-4">
												<PopupTableStart StatisticalDetail={data} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}
		</>
	);
}
