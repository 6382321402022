import * as Types from '../constants/DataHelpers';
var data = JSON.parse(localStorage.getItem('DataHelpers'));


var initialState = data ? data : [];

const DataProvince = (state = initialState, action) => {

	switch (action.type) {
		
		case Types.FETCH_PROVINCE_SUCCESS: {
			const { data } = action.payload.data;
            state = [...data.rows]
			localStorage.setItem('DataHelpers', JSON.stringify(data.rows));
			return [...state]
		}
		case Types.FETCH_PROVINCE_FAILED: {
			const { error } = action.payload;
			console.log(error)
            state = []
			return state
		}
		default: return state
	}
}

export default DataProvince;