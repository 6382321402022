
import qs from 'query-string'
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory  } from "react-router";

import { fetchStatisticalByFilterParamsRequest, fetchStatisticalLotteryFrequencyRequest } from '../../actions/Statistical'
import { fetchProvinceRequest } from "../../actions/DataHelpers";
import { CreateParamsforApi } from "../../helpers";
import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../../helpers/check";
import { getStatistical } from '../../helpers/statistical';
import Cycle from '../../components/statistical/table-statistical/Cycle';
import FormFilter from '../../components/statistical/filter/form/FormFilter';
import { DATA_FILTER_ALL_NUMBER_SET, PROVINCE_OPTIONS } from '../../components/statistical/helpers/constans';
import FilterNumberSet from '../../components/statistical/filter/form/FilterNumberSet';
import SelectProvince from '../../components/statistical/filter/form/commons/SelectProvince';
import { handleSubmitFormFilter } from '../../components/statistical/helpers';


const CycleContainer = ({
	DataProvince,
	GetProvince,
	Statistical,
	ShowStatistical,
	currentStatistical,
}) => {
	const history = useHistory()
	const searchQuery = useLocation()

    const [filterNumberData, setFilterNumberData] = useState(DATA_FILTER_ALL_NUMBER_SET) // set data để filter bảng Statistical lottery


	// const filterParams = {lottery_crawl_type_id: 6, location_id: 5};
	const filterParamsInit = { lottery_crawl_type_id: currentStatistical.lottery_crawl_type_id, location_id: PROVINCE_OPTIONS[0].id };
	// const filterParamsInit = { lottery_crawl_type_id: currentStatistical.lottery_crawl_type_id, location_id: 25 }; // Gán tạm location_id: 5
	const [filterParams, setFilterParams] = useState(filterParamsInit);

	const [selectedValue, setSelectedValue] = useState(filterParamsInit.location_id);

	useEffect(()=>{
		if(selectedValue) {
			setFilterParams({...filterParams, location_id: parseInt(selectedValue)})
		}
	},[selectedValue])

	useEffect(()=>{
		if(filterParams) {
			ShowStatistical(filterParams)
		}
	},[filterParams])

	useEffect(()=>{
		GetProvince()
	},[])
	
	// useEffect(()=>{
	// 	if(currentStatistical) {
	// 		// console.log('currentStatistical: ', currentStatistical);
	// 		setFilterParams({lottery_crawl_type_id: currentStatistical.lottery_crawl_type_id, location_id: 5})

	// 		// let elements = document.querySelectorAll('#code option');
	// 		// let elements = document.querySelectorAll('#code1 option');
	// 		// let array = [];
	// 		// if(elements && elements.length > 0) {
	// 		// 	// console.log('elements: ', elements);
	// 		// 	elements.forEach((ele, index) => {
	// 		// 		// console.log(ele.value, ele.innerText);
	// 		// 		// console.log('___________');
	// 		// 		array.push({ value: ele.value, name: ele.innerText})
	// 		// 	})
	// 		// }
	// 		// console.log(array);
	// 	}
	// },[currentStatistical])

	function handleSubmit(){
		setFilterParams({ ...filterParams, location_id: parseInt(selectedValue) })
	}

	return (
		<>
			<div className="panel panel-default">
				<div className="panel-heading center">
					<h4 className="right-menu-title">
						Thống kê chu kỳ dài nhất bộ số không ra
					</h4>
				</div>
				<div className="panel-body">
					<form className="form-horizontal" id="chuky_form">
						<div className="form-group">
							<label htmlFor="code" className="col-sm-2 control-label">Tỉnh</label>
							<div className="col-sm-3">
								<SelectProvince selectedValue={selectedValue} setSelectedValue={setSelectedValue} valueType={'id'} />
							</div>
							<div className="col-sm-3">
							{/* <button type="button" className="btn btn-primary daudong" onClick={handleSubmit}>
								<i className="fa fa-arrow-right" /> Chọn tỉnh
							</button> */}
							</div>
						</div>
						

						{/* filter Bộ số */}
						<FilterNumberSet
							filterNumberData={filterNumberData}
							setFilterNumberData={setFilterNumberData}
							isReset={selectedValue}
						/>
						
						<p className="chu15 daudong vietnghieng" style={{ paddingTop: 5 }}>
							<span className="maudo"> Hướng dẫn</span>: B1 - Chọn tỉnh. =&gt; B2 -
							Chọn nhanh bộ số muốn xem (<span className="maudo">KHÔNG</span> cần bấm
							Enter). {/* Bấm TRỢ GIÚP để xem thêm hướng dẫn. */}
						</p>
					</form>
				</div>
			</div>

			<Cycle Statistical={Statistical} filterParams={filterParams} filterNumberData={filterNumberData} />
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
		Statistical: state.Statistical
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:(filterParams) =>{
			dispatch(fetchStatisticalByFilterParamsRequest(filterParams))
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(CycleContainer);

