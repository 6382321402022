import React, { Component, useEffect, useState } from "react";

function renderStartLottery(arrLottery){
    let dataStart = [
        {name: 0, Values: []},
        {name: 1, Values: []},
        {name: 2, Values: []},
        {name: 3, Values: []},
        {name: 4, Values: []},
        {name: 5, Values: []},
        {name: 6, Values: []},
        {name: 7, Values: []},
        {name: 8, Values: []},
        {name: 9, Values: []},
    ];

    let reg = /[a-zA-Z]/;

    try {
        arrLottery.map(item=>{
            if(!reg.test(item)){ // loại bỏ mã ký tự
                let start = item.substr(0,1);
                dataStart.some((e,i)=>{
                    if(e.name.toString() === start) {
                        dataStart[i].Values.push(item.substr(0,2));
                        return true;
                    }
                })
            }
        })
    
        // sort increase
        dataStart.map(item=> {
            if(item.Values.length>1) {
                item.Values = item.Values.sort((a, b)=>{return a - b})
            }
        })
    } catch (error) {
        console.log(error);
    }

    return dataStart;
}

export function PopupTableStart(props) {
	const { StatisticalDetail } = props
	const [data, setData] = useState(null)

	useEffect(() => {
		try {
            if (StatisticalDetail) {
                let arrLottery = [];
                for (var key in StatisticalDetail) {
                    if (StatisticalDetail.hasOwnProperty(key)) {
                        arrLottery = arrLottery.concat(StatisticalDetail[key]);
                    }
                }
                
                let dataStart = renderStartLottery(arrLottery);
    
                setData(dataStart)
            }
        } catch (error) {
            console.log(error);
        }
	}, [StatisticalDetail])

	return (
		<>
			<table
                className="table table-hover table-bordered table-condensed table-kq-north-west table-kq-border table-kq-bold-border kqbackground table-kq-hover"
                style={{ marginLeft: 3 }}
                id="dau_mb"
            >
                <thead>
                    <tr>
                        <td style={{ width: "22%" }}>Đầu</td>
                        <td className="bang_kqnhanh_bold_bottomw">Lô tô</td>
                    </tr>
                </thead>
                <tbody style={{ fontWeight: "bold" }}>
                    {data && data.length>0 && data.map((item, index)=>{
                        return (
                            <tr key={index} >
                                <td className="dosam chu15 kqcenter">{item.name}</td>
                                <td id="begin_with_0" className="chu17 need_blank">
                                    {item.Values.join(',')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
		</>
	);
}
