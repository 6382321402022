import React, { Component } from "react";
class TableLoadingMB extends Component {
	render() {
		return (
			<div data-id="kq" className="one-city" data-region={1}>
				<table className="kqmb extendable">
					<tbody>
						<tr>
							<td colSpan={13} className="v-giai madb">
								<span className="v-madb imgloadig"></span>
							</td>
						</tr>
						<tr className="db">
							<td className="txt-giai">ĐB</td>
							<td className="v-giai number "><span data-nc={5} className="v-gdb imgloadig"></span></td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 1</td>
							<td className="v-giai number">
								<span data-nc={5} className="v-g1 imgloadig"></span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 2</td>
							<td className="v-giai number">
								<span data-nc={5} className="v-g2-0 imgloadig"></span>
								<span data-nc={5} className="v-g2-1 imgloadig"></span>
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 3</td>
							<td className="v-giai number">
								<span data-nc={5} className="v-g3-0 imgloadig"></span>
								<span data-nc={5} className="v-g3-1 imgloadig"></span>
								<span data-nc={5} className="v-g3-2 imgloadig"></span>
								<span data-nc={5} className="v-g3-3 imgloadig"></span>
								<span data-nc={5} className="v-g3-4 imgloadig"></span>
								<span data-nc={5} className="v-g3-5 imgloadig"></span>
								
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 4</td>
							<td className="v-giai number">
								<span data-nc={4} className="v-g4-0 imgloadig"></span>
								<span data-nc={4} className="v-g4-1 imgloadig"></span>
								<span data-nc={4} className="v-g4-2 imgloadig"></span>
								<span data-nc={4} className="v-g4-3 imgloadig"></span>
								
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 5</td>
							<td className="v-giai number">
								<span data-nc={4} className="v-g5-0 imgloadig"></span>
								<span data-nc={4} className="v-g5-1 imgloadig"></span>
								<span data-nc={4} className="v-g5-2 imgloadig"></span>
								<span data-nc={4} className="v-g5-3 imgloadig"></span>
								<span data-nc={4} className="v-g5-4 imgloadig"></span>
								<span data-nc={4} className="v-g5-5 imgloadig"></span>
								
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 6</td>
							<td className="v-giai number">
								<span data-nc={3} className="v-g6-0 imgloadig"></span>
								<span data-nc={3} className="v-g6-1 imgloadig"></span>
								<span data-nc={3} className="v-g6-2 imgloadig"></span>
								
							</td>
						</tr>
						<tr className="g7">
							<td className="txt-giai">Giải 7</td>
							<td className="v-giai number"><span data-nc={2} className="v-g7-0 imgloadig"></span>
								<span data-nc={2} className="v-g7-1 imgloadig"></span>
								<span data-nc={2} className="v-g7-2 imgloadig"></span>
								<span data-nc={2} className="v-g7-3 imgloadig"></span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}



export default TableLoadingMB;
