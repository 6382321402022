import * as lotteryApi from '../apis/Lottery';
import * as lotteryConstants from './../constants/Lottery';
import * as loadingConstants from './../constants/Loading';

const API = 'lottery_crawl';


// Statistical
export const fetchStatisticalDetailSuccess = (data, filterParams) => {
	return {
		type: lotteryConstants.FETCH_STATISTICAL_DETAIL_SUCCESS,
		filterParams: filterParams || null,
		payload: {
			data
		}
	}
}
export const fetchStatisticalDetailFailed = (error) => {
	return {
		type: lotteryConstants.FETCH_STATISTICAL_DETAIL_FAILED,
		payload: {
			error
		}
	}
}

/*
* Get data Statistical detail
* Api mẫu: 'https://core.xosoonline.net/lottery_crawl/popup-xoso?code=mb&date=31-08-2021' // 7. API POpup trung gian
*/
export const fetchStatisticalDetailRequest = (filterParams) => {
	// let params = 'lottery_crawl/popup-xoso?code=mb&date=31-08-2021' // 7. API POpup trung gian
	
	let paramString = `popup-xoso?code=${filterParams.code}&date=${filterParams.date}`;
	let params = API + '/' + paramString;
    
    return dispatch => {
		lotteryApi.getList(params).then(resp => {
			const { data } = resp;
			// data success: { err: 0, msg: '', result: {...} } || { err: 2, msg: 'Ngày bạn chọn không đúng' }
			if(data.result) {
				dispatch(fetchStatisticalDetailSuccess(data, filterParams));
			} else {
				dispatch(fetchStatisticalDetailFailed(data));
			}

			// dispatch(fetchStatisticalDetailSuccess(data, filterParams));
		}).catch(error => {
			dispatch(fetchStatisticalDetailFailed(error));
		});
	}
}
