import * as dataHelpersApi from '../apis/Lottery';
import * as dataHelpersConstants from './../constants/DataHelpers';



const API_ENDPOINT = 'location?fields=["name","slug", "parent_id"]';

export const fetchProvinceSuccess = (data) => {
    return {
        type: dataHelpersConstants.FETCH_PROVINCE_SUCCESS,
        payload: {
            data
        }
    }
}
export const fetchProvinceFailed = (error) => {
    return {
        type: dataHelpersConstants.FETCH_PROVINCE_FAILED,
        payload: {
            error
        }
    }
}
export const fetchProvinceRequest = (id) => {
    let params = id ? id : ''
    return dispatch => {
        dataHelpersApi.getList(API_ENDPOINT + params).then(resp => {
            const { data } = resp;
            dispatch(fetchProvinceSuccess(data));
        }).catch(error => {
            dispatch(fetchProvinceFailed(error));
        });
    }
}