import * as Types from '../constants/Lottery';
import { checkDataLocationLoading } from '../helpers';

var data = JSON.parse(localStorage.getItem('LotteryRealTime'));


var initialState = data ? data : [];

const DataLotteryRealTime = (state = initialState, action) => {
   
	switch (action.type) {
        case Types.FETCH_TABLE_REALTIME: {
			return state
		}
		case Types.FETCH_TABLE_REALTIME_SUCCESS: {
			const { data } = action.payload.data;
            state = data.rows
			localStorage.setItem('LotteryRealTime', JSON.stringify(data.rows));
			return state
		}
		case Types.FETCH_TABLE_REALTIME_FAILED: {
			const { error } = action.payload;
			console.log(error)
			return state
		}
		default: return state
	} 
    
}

export default DataLotteryRealTime;