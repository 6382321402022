
import qs from 'query-string'
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory  } from "react-router";

import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import TableBreadCrumbs from "../components/BreadCrumbs/TableBreadCrumbs";
// import NavTabsDate from "../components/NavTabsDate";
import TableLottery from "../components/TableLottery";
import TableFirstLastLottery from "../components/TableFirstLastLottery";
import TableFilter from "../components/TableFilter";
import TableStatisticalHomePage from '../components/statistical/table-statistical/TableStatisticalHomePage';

import { fetchStatisticalRequest } from '../actions/Statistical';
import { fetchListLotteryRequest } from '../actions/Lottery';
import { fetchProvinceRequest } from "../actions/DataHelpers";
import { CreateParamsforApi } from "../helpers";
import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../helpers/check";
import { getLotteryFromHtmlString, setLotteryInnerHtml } from "../helpers/filterLottery";
import { SCHEDULE } from "../constants/Schedule";
import { getLocationIdDefault } from "../helpers/schedule";


// const today = new Date();
// const today_weekday = today.getDay();
let MAX_TIMES_CALL_DATA = 7; // max times call data with date-- while data empty.


const TableLotteryContainer = ({
	DataLottery,
	DataProvince,
	ShowTable,
	GetProvince,
	Statistical,
	ShowStatistical
}) => {
	const history = useHistory()
	const searchQuery = useLocation()
	let searchObject = createFilterFormParams(searchQuery)
	const [filterParams, setFilterParams] = useState(searchObject)
	// const searchLottery = CreateParamsforApi(filterParams)
	
	const [searchLottery, setSearchLottery] = useState(()=>CreateParamsforApi(filterParams));
	const [isLoadingDataSchedule, setIsLoadingDataSchedule] = useState(true)
	const [countCallData, setCountCallData] = useState(1);
	const [stopCountCallData, setStopCountCallData] = useState(false);

	// const locationIdDefault = getLocationIdDefault(filterParams.domain_id, today_weekday);
	const locationId = findLocationIdFromPathName(searchQuery.pathname, filterProvince(DataProvince));

	const searchObjectClone = qs.parse(searchQuery.search);
	const weekdayFromUrl = parseInt(searchObjectClone.weekday) || null;
	if(weekdayFromUrl) {
		MAX_TIMES_CALL_DATA = 2;
	}

	const [showDigits, setShowDigits] = useState('all')
	const [arrayLottery, setArrayLottery] = useState(null)    

	const redirectUrl = (options) => {
		let url = createUrlTo(options)
		window.location.replace(url);
		// history.push(url);
		setFilterParams(options)
	}
	useEffect(()=>{
		GetProvince()
		ShowStatistical()
	},[])
	const getData = () => {
		ShowTable(searchLottery, filterParams)
	}
	
	useEffect(()=>{
		getData();
	},[filterParams])

	useEffect(()=>{
		// pathName has province & DataLottery empty: recall api with date --
		try {
			if(DataLottery && DataLottery.length === 0 && locationId) {	
                console.log(filterParams);
				let filterParamsTamp;

				if(countCallData < MAX_TIMES_CALL_DATA) {
					filterParamsTamp = createNewFilterParams({...filterParams, location_id: locationId});
					setCountCallData(prev => prev + 1)
				}
				
				if(filterParamsTamp){
					setFilterParams(filterParamsTamp);
					setSearchLottery(CreateParamsforApi(filterParamsTamp))
				}
			}

		} catch (error) {
			console.log(error);
		}
	},[DataLottery])


	useEffect(()=>{
		/*
		* while data empty
		* -> reload data follow Weekday schedule: change location_id in filterParams.
		*/
		try {
			if(DataLottery && DataLottery.length === 0 && !locationId && isLoadingDataSchedule) {
				let location_id;
				let filterParamsTamp = {...filterParams};
					
                let areaSchedule = SCHEDULE.find(e=>e.domain_id === filterParamsTamp.domain_id);
                
                const currentDate = new Date(filterParamsTamp.years,filterParamsTamp.months-1,filterParamsTamp.days);
                let weekday = currentDate.getDay();
                
				if(areaSchedule) {
					areaSchedule.Schedule.some(e=>{
						if(parseInt(e.weekday) === parseInt(weekday)) {
							e.location_ids.some((id,index)=>{
								if(id === filterParamsTamp.location_id) {
									if(e.location_ids.length > (index + 1)) {
                                        location_id = e.location_ids[index + 1];
										return true
									}  else {
										// all data with location_ids empty:
                                        setIsLoadingDataSchedule(false);
                                        return true
                                    }
								}
							})
							return true
						}
					})
                }
                
				if(location_id){
                    filterParamsTamp = {...filterParamsTamp, location_id: location_id}
                    
					setFilterParams(filterParamsTamp);
					setSearchLottery(CreateParamsforApi(filterParamsTamp))
					
				}
			}

		} catch (error) {
			console.log(error);
		}
    },[DataLottery])

    useEffect(()=>{
		// all data follow Weekday schedule empty -> date--
		try {
			if(DataLottery && DataLottery.length === 0 && !isLoadingDataSchedule && !stopCountCallData) {	
                let filterParamsNew;

                // get weekday of date--
                const currentDate = new Date(filterParams.years,filterParams.months-1,filterParams.days);
				let newDate;
				// get last date
				newDate = Number(currentDate.getDate())-1;
                const newMonth = Number(currentDate.getMonth());
                const newYear = Number(currentDate.getFullYear());
                const toDate = new Date(newYear, newMonth, newDate);
                let weekday = toDate.getDay();

				if(countCallData < MAX_TIMES_CALL_DATA) {
					if(weekdayFromUrl) {
						filterParamsNew = createNewFilterParamsLastWeek({
							...filterParams, 
							location_id: getLocationIdDefault(filterParams.domain_id, weekdayFromUrl) || filterParams.location_id
						});
					} else {
						filterParamsNew = createNewFilterParams({
							...filterParams, 
							location_id: getLocationIdDefault(filterParams.domain_id, weekday) || filterParams.location_id
						});
					}

					setCountCallData(prev => prev + 1)
					setIsLoadingDataSchedule(true);
				} else {
                    setStopCountCallData(true)
                }
				
				if(filterParamsNew){
					setFilterParams(filterParamsNew);
					setSearchLottery(CreateParamsforApi(filterParamsNew))
				}
			}

		} catch (error) {
			console.log(error);
		}
	},[isLoadingDataSchedule, DataLottery])

	
	useEffect(()=>{
		// set root array lottery value
		try {
			if(DataLottery && DataLottery[0]) {
				setArrayLottery(getLotteryFromHtmlString(DataLottery[0].xoso));
			}

		} catch (error) {
			console.log(error);
		}
	},[DataLottery])

	useEffect(()=>{
		// table filter by digits
		try {
			if(arrayLottery) {
				setLotteryInnerHtml(arrayLottery, showDigits);
			}

		} catch (error) {
			console.log(error);
		}
	},[showDigits, arrayLottery])

	const showTableLottery = () => {
		return <TableLottery DataLottery={DataLottery} filterParams={filterParams} />
	}
	return (
		<>
			<BreadCrumbs DataLottery={DataLottery} BreadCrumbsDate={filterParams} redirectUrl={redirectUrl} />
			{/* <NavTabsDate BreadCrumbsDate={filterParams}  /> */}
			<div className="box" >
				<TableBreadCrumbs DataLottery={DataLottery} BreadCrumbsDate={filterParams} DataProvince={DataProvince} />
				<div id="load_kq_mb_0" >
					{showTableLottery()}
					<TableFilter showDigits = {showDigits} setShowDigits = {setShowDigits}/>
					<TableFirstLastLottery DataLottery={DataLottery}  />
				</div>
				<TableStatisticalHomePage Statistical={Statistical} />
			</div>
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataLottery: state.DataLottery,
		DataProvince: state.DataProvince,
		Statistical: state.Statistical
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		ShowTable: (params, filterParams) => {
			dispatch(fetchListLotteryRequest(params, filterParams));
		},
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:() =>{
			dispatch(fetchStatisticalRequest())
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(TableLotteryContainer);

