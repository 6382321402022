import React from "react";

const RADIO_GROUP = [
    {value: 'all', lable: 'Đầy đủ'},
    {value: '2', lable: '2 số'},
    {value: '3', lable: '3 số'},
]

export default function TableFilter({showDigits, setShowDigits}) {

	function handelChangeShowDigits(value) {
		setShowDigits(value);
    }
    
    return (
        <div className="control-panel table-filter">
            <div className="digits-form">
                {
                    RADIO_GROUP.map((item, index)=>{
                        return (
                            <span key={index} >
                                <input 
                                    type="radio" 
                                    id={"showDigits" + index} 
                                    name="showed-digits" 
                                    value={item.value} 
                                    checked={showDigits===item.value} 
                                    onChange={()=>handelChangeShowDigits(item.value)} 
                                />
                                <label htmlFor={"showDigits" + index}>{item.lable}</label>
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}