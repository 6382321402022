
import React, { Component } from "react";
import TableLotteryContainer from "../containers/TableLotteryContainer";






class Lottery extends Component {
    render () {
        const {match} = this.props
        return (
            <TableLotteryContainer match={match} />
        );
    }
}


export default Lottery

