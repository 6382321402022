import { LOTTERY_ORDER, LOTTERY_DIGITS } from "../constants/LotteryRandom";

export function getRandomInt(min,max) {
  return Math.floor(Math.random() * (max - min) + min); // min<= valueRandom <max
}

export function getRandomOneNumberLottery(digit) {
  let result;
  try {
    let min = Math.pow(10, digit-1);
    let max = Math.pow(10, digit);
    result = getRandomInt(min,max);
  } catch(error){console.log(error)}
  return result;
}

export function getRandomLotteryArray(n,digit) {
  let result = [];
  try {
    let min = Math.pow(10, digit-1);
    let max = Math.pow(10, digit);
    result = [getRandomInt(min,max)];
    if(n>1) {
      for(let i=1; i<n; i++) {
        let tamp = getRandomInt(min,max);
        result.find(e=>e !== tamp) ? result.push(tamp) : i--;
      }
    }
  } catch(error){console.log(error)}
  return result;
}

export function getRandomCharacter(digit){
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, digit);
}

export function getRandomSpecialPrizeCode(n, digit){
  // format: AAYY (AA: character a-z, YY: number 0-99)
  let result = [];
    try {
    result = [getRandomCharacter(digit)+""+getRandomInt(0,99)];
    if(n>1) {
      for(let i=1; i<n; i++) {
        let tamp = getRandomCharacter(digit)+""+getRandomInt(0,99);
        result.find(e=>e !== tamp) ? result.push(tamp) : i--;
      }
    }
  } catch(error){console.log(error)}
  return result;
}

// export function getRandomLotteryArray(domain_id){}

// getRandomLotteryDigitArray() không bao gồm các Mã giải đb của MB.
export function getRandomLotteryDigitArray(domain_id){
  let array = [];
  try {
    let LotteryOrder = LOTTERY_ORDER.find(e=>e.domain_id === domain_id);
    let LotteryDigits = LOTTERY_DIGITS.find(e=>e.domain_id === domain_id);
    if(LotteryOrder && LotteryDigits) {
      LotteryOrder = LotteryOrder.LotteryOrder;
      LotteryDigits = LotteryDigits.LotteryDigits;
  
      LotteryOrder.map((key, index) =>{
        if(key !== 'mdb') {
          let itemPrize = LotteryDigits.find(e=>e.prize === key);
          if(itemPrize) {
            for(let i = 0; i < itemPrize.count; i++) {
              array.push({...itemPrize, id: key+index+i})
            }
          }
        }
      })
    }
    
  } catch (error) {
    console.log(error);
  }
  
  return array;
}