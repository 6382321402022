import configureStore from '../redux/configureStore';
import { SCHEDULE } from "../constants/Schedule";

// get DataProvince from store
const store = configureStore();
const state = store.getState();
const DataProvince = state.DataProvince;

export function getLocationIdDefault(domain_id, weekday){
  let result;
  try {
    if(domain_id){
      let areaSchedule = SCHEDULE.find(e=>e.domain_id === domain_id);
      if(areaSchedule) {
        areaSchedule.Schedule.some(e=>{
          if(parseInt(e.weekday) === parseInt(weekday)) {
            result = e.location_ids[0];
            return true
          }
        })
      }
    }
  } catch (error) {
    console.log(error);
  }
  return result;
}

export function parseLocationIdToPathName(pathName, location_id_current) {
  let result = pathName;
  try {
    let slug_province_current;

    let province_current = DataProvince.find(province => province.parent_id && (province.id === location_id_current));
    
    if(province_current) {
      let slug_province_old;

      slug_province_current = province_current.slug;

      DataProvince.some(province=>{
        if(slug_province_old) {
          return true
        } else {
            if(province.parent_id && (pathName.indexOf(province.slug) !== -1)){
              slug_province_old = province.slug;
              return true
            }
        }
      })
  
      if(slug_province_old) {
        result = pathName.replace(slug_province_old, slug_province_current);
      }
      
    }

  } catch (error) {
    console.log(error);
  }
  return result;
}