import React, { Component } from "react";
import { setDefaultDate } from "../../helpers";
import moment from 'moment'
import LotteryWeekday from "../LotteryWeekday/LotteryWeekday";


class TableBreadCrumbs extends Component {
    
    findProvince = (provinceId,provinces) =>{
        let result ={}
        if(provinces.length>0){
            result = provinces.find((province)=>{
                if(province.id == provinceId){
                    return province
                }
            })
        }
        return result
    }
    
    
    render () {
        const {DataProvince,BreadCrumbsDate,DataLottery} = this.props
        let currentDateUpdate
        if(DataLottery[0]){
            currentDateUpdate = {
                days:DataLottery[0].day,
                months:DataLottery[0].month,
                years:DataLottery[0].year,
                location_id:DataLottery[0].Location.id
            }
        }else{
            currentDateUpdate = BreadCrumbsDate
        }

        const options = setDefaultDate(currentDateUpdate)
        let province = this.findProvince(options.location_id,DataProvince)
        if(BreadCrumbsDate.pathName === '/' && BreadCrumbsDate.location_id === '25'){
            province.name = 'Miền Bắc';
        }
        const todate = new Date()

        const weekdays = ['Chủ Nhật','Thứ Hai','Thứ Ba','Thứ Tư','Thứ Năm','Thứ Sáu','Thứ Bảy']
        const urlDate = new Date(currentDateUpdate.years,currentDateUpdate.months-1,currentDateUpdate.days)
        const toDay = currentDateUpdate.days? moment(urlDate).format('DD/MM/YYYY'): moment(todate).format('DD/MM/YYYY') 
        const weekday = currentDateUpdate.days ? weekdays[urlDate.getDay()] : weekdays[todate.getDay()]  
        return (
            <>
                <div className="head head-result">
                    <h3 className="tit-mien clearfix">
                        <strong>
                            <a className="title-a " href="#" title="XSBN 08-09-2021">
                                {` Xổ số ${province.name} ${toDay} `}
                            </a> 
                            {weekday}
                        </strong>
                    </h3>
                </div>
                <LotteryWeekday location={province.name} />
                
                <div className="mo-thuong-ngay">
                    <table className="table-fixed">
                        <tbody>
                            <tr>
                                <td><a href="/xsmb-kqmb-kqxsmb-ket-qua-xo-so-mien-bac/" title="XSMB">Miền Bắc</a></td>
                                <td><a href="/xsmn-xsmnhn-xsmtt-kqxsmn-ket-qua-xo-so-mien-nam/" title="XSMN">Miền Nam</a></td>
                                <td><a href="/xsmt-xsktmt-kqxsmt-kqsxmt-ket-qua-xo-so-mien-trung/" title="XSMT">Miền Trung</a></td>
                            </tr>
                            {/* <tr>
                                <td><a href="/xo-so/?domain_id=1&location_id=25" title="XSMB">Miền Bắc</a></td>
                                <td><a href="/so-so/?domain_id=2&location_id=7" title="XSMN">Miền Nam</a></td>
                                <td><a href="/so-xo/?domain_id=3&location_id=44" title="XSMT">Miền Trung</a></td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }   
   
}
  
  

export default TableBreadCrumbs;
 