import * as Types from '../constants/Lottery';
var data = JSON.parse(localStorage.getItem('Lottery'));


var initialState = data ? data : [];

const DataLottery = (state = initialState, action) => {
	switch (action.type) {
		case Types.FETCH_TABLE_REALTIME: {
			return state
		}
		case Types.FETCH_TABLE_SUCCESS: {
			const { data } = action.payload.data;

			state = data.rows
			localStorage.setItem('Lottery', JSON.stringify(state));
			return state
		}
		case Types.FETCH_TABLE_FAILED: {
			const { error } = action.payload;
			console.log(error)
			return state
		}
		default: return state
	}
}

export default DataLottery;