
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { useHistory, Redirect } from "react-router";

import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import TableBreadCrumbs from "../components/BreadCrumbs/TableBreadCrumbs";
import NavTabsDate from "../components/NavTabsDate";
import TableLottery from "../components/TableLottery";
import TableFirstLastLottery from "../components/TableFirstLastLottery";

import { fetchListLotteryRealtimeRequest, fetchStartListLotteryRealtimeRequest, fetchEndListLotteryRealtimeRequest } from '../actions/Lottery'
import { fetchProvinceRequest } from "../actions/DataHelpers";
import { CreateParamsforApi } from "../helpers";
import { createFilterFormParams, createUrlTo } from "../helpers/check";
import { filterTableLottery } from "../components/TrialControl/helper";
import TableFilter from "../components/TableFilter";
import { getLotteryFromHtmlString, setLotteryInnerHtml } from "../helpers/filterLottery";
import { getRandomOneNumberLottery, getRandomLotteryDigitArray } from "../helpers/random";
import { checkHourlyRealTimeLottery } from "../helpers/lotteryRealTime";

// const PageToDate = new Date()
const timeGetData = 5000
const timeRandomOneNumerLottery = 200;

const TableLotteryRealContainer = ({
    DataLotteryRealtime,
    DataProvince,
    ShowTableRealtime,
    GetProvince,
}) => {
    const history = useHistory()
    let searchQuery = useLocation()
    let searchObject = createFilterFormParams(searchQuery)
    const [filterParams, setFilterParams] = useState(searchObject)
    const searchLottery = CreateParamsforApi(filterParams)

    const [showDigits, setShowDigits] = useState('all')
    const [arrayLottery, setArrayLottery] = useState(null)   

    const [isHourlyRealTimeLotteryInterval, setIsHourlyRealTimeLotteryInterval] = useState(false)
    
    

    const redirectUrl = (options) => {
        let url = createUrlTo(options)
        window.location.replace(url);
        setFilterParams(options)
    }

    const getData = () => {
        ShowTableRealtime(searchLottery, filterParams)
    }

    useEffect(()=>{
        GetProvince()
    },[])
    useEffect(() => {
        try {
            getData()
            let interval;
            if(checkHourlyRealTimeLottery(filterParams.domain_id) && isHourlyRealTimeLotteryInterval) {
                interval = setInterval(() => {
                    getData()
                },
                timeGetData)
            }
            return () => clearInterval(interval);
        } catch (error) {
            console.log(error);
        }
    }, [filterParams, isHourlyRealTimeLotteryInterval])

    useEffect(()=>{
        // set root array lottery value
		try {
			if(DataLotteryRealtime && DataLotteryRealtime[0]) {
                setArrayLottery(getLotteryFromHtmlString(DataLotteryRealtime[0].xoso));
            }
		} catch (error) {
			console.log(error);
		}
	},[DataLotteryRealtime])


    /**
     * Khi component được gọi, kiểm tra thời gian đến thời điểm bắt đầu gọi api thì dừng.
     */
    useEffect(()=>{
        try {
            let interval;

            // Check "!arrayLottery": nếu đã có data kết quả xổ số -> Không cần gọi api lấy kết quả xổ số trực tiếp => không cần kiểm tra thời gian.
            if(!arrayLottery && !isHourlyRealTimeLotteryInterval) {
                interval = setInterval(() => {
                    if(checkHourlyRealTimeLottery(filterParams.domain_id)) {
                        setIsHourlyRealTimeLotteryInterval(true)
                    }
                }, 1000)
            } 
            else {
                interval && clearInterval(interval)
            }

            return () => {
                interval && clearInterval(interval)
            };

        } catch (error) {
            console.log(error);
        }
    }, [isHourlyRealTimeLotteryInterval])


	useEffect(()=>{
		try {
            // set table data & filter table by digits
			if(arrayLottery) {
                setLotteryInnerHtml(arrayLottery, showDigits);
            }

            // random waiting lottery
            let randomWaitingLottery;
            let arrayHTMLLottery = document.querySelectorAll('.v-giai.number span');
            let randomLotteryDigitArray = getRandomLotteryDigitArray(filterParams.domain_id);

            /**
             * isHourlyRealTimeLotteryInterval: check thời điểm bắt đầu gọi api.
             * checkHourlyRealTimeLottery(): check thời gian tại thời điểm gọi hàm (để biết khi nào kết thúc gọi api).
             * arrayHTMLLottery.length: check để đảm bảo layout html bảng đã được render -> dùng để lấy vị trí HTML của mỗi giải.
             */
            if(checkHourlyRealTimeLottery(filterParams.domain_id) && arrayHTMLLottery.length > 0 && isHourlyRealTimeLotteryInterval) {
                if(arrayLottery) {
                    let arrayLotteryTamp = [];
                    arrayLottery.map(item=> {
                        if(item) arrayLotteryTamp.push(item);
                    })

                    // chưa xổ hết giải -> random số đang chờ xổ.
                    if(arrayLotteryTamp.length < arrayHTMLLottery.length) {
                        
                        let index = arrayLotteryTamp.length;

                        // Giải ĐB Miền Bắc xổ cuối cùng: (Tổng số giải MB cần random: 27)
                        if(filterParams.domain_id === 1) {
                            if(index === 26 && !arrayLottery[0]) {
                                index = 0;
                                arrayHTMLLottery[26] && arrayHTMLLottery[26].classList.remove("number-random");
                            } else {
                                index += 1;
                                arrayHTMLLottery[index-1] && arrayHTMLLottery[index-1].classList.remove("number-random");
                            }
                        }
                        
                        arrayHTMLLottery[index] && arrayHTMLLottery[index].classList.remove("imgloadig");
                        arrayHTMLLottery[index] && arrayHTMLLottery[index].classList.add("number-random");

                        randomWaitingLottery = setInterval(() => {
                            arrayHTMLLottery[index].innerHTML = getRandomOneNumberLottery(randomLotteryDigitArray[index].digit);
                        }, timeRandomOneNumerLottery)
                        
                    }
                } else {
                    // Đến giờ xổ số && Chưa có data -> random giải đầu tiên.
                    let index = 0;

                    // Nếu là Miền Bắc, random giải 1.
                    if(filterParams.domain_id === 1) {
                        index = 1;
                    }

                    arrayHTMLLottery[index] && arrayHTMLLottery[index].classList.remove("imgloadig");
                    arrayHTMLLottery[index] && arrayHTMLLottery[index].classList.add("number-random");

                    randomWaitingLottery = setInterval(() => {
                        arrayHTMLLottery[index].innerHTML = getRandomOneNumberLottery(randomLotteryDigitArray[index].digit);
                    }, timeRandomOneNumerLottery)
                }
            }

            
            return () => {
                randomWaitingLottery && clearInterval(randomWaitingLottery)
            };
		} catch (error) {
			console.log(error);
		}
	},[showDigits, arrayLottery, isHourlyRealTimeLotteryInterval])


    const showTableLottery = () => {
        return <TableLottery DataLottery={DataLotteryRealtime} filterParams={filterParams} />
    }

    return (
        <>
            <BreadCrumbs DataLottery={DataLotteryRealtime} BreadCrumbsDate={filterParams} redirectUrl={redirectUrl} />
            {/* <NavTabsDate BreadCrumbsDate={filterParams} /> */}
            <div className="box" >
                <TableBreadCrumbs DataLottery={DataLotteryRealtime} BreadCrumbsDate={filterParams} DataProvince={DataProvince} />
                <div id="load_kq_mb_0" >
                    {showTableLottery()}
					<TableFilter showDigits = {showDigits} setShowDigits = {setShowDigits}/>
                    <TableFirstLastLottery DataLottery={DataLotteryRealtime} />
                </div>
            </div>
        </>
    );
}


const mapStateToProp = state => {
    return {
        DataLotteryRealtime: state.DataLotteryRealtime,
        DataProvince: state.DataProvince,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        ShowTableRealtime: (params, filterParams) => {
            dispatch(fetchListLotteryRealtimeRequest(params, filterParams));
        },
        GetProvince: (params) => {
            dispatch(fetchProvinceRequest(params));
        }

    }
}

export default connect(mapStateToProp, mapDispatchToProps)(TableLotteryRealContainer);

