import React, { useEffect, useState } from "react";

export default function TableMB({ dataStartEndLottery, dataLotteryFilter }) {

	const [dataLottery, setDataLottery] = useState(null);

	useEffect(()=>{
		try{
			if(dataLotteryFilter) {
				setDataLottery(dataLotteryFilter);
			} else {
				setDataLottery([]);
			}
		} catch(error){console.log(error)}
	},[dataLotteryFilter])

	// useEffect(()=>{
	// 	try{
	// 		if(dataStartEndLottery) {
	// 			setDataLottery(dataStartEndLottery.DataLottery);
	// 		} else {
	// 			setDataLottery([]);
	// 		}
	// 	} catch(error){console.log(error)}
	// },[dataStartEndLottery])
	
	return (
		// <div data-id="kq" className="one-city" data-region={1}>
			<table className="kqmb extendable">
				<tbody>
					<tr>
						<td colSpan={13} className="v-giai madb">
							<span className={"v-madb" + (dataLottery&&dataLottery[26] ? '' : ' imgloadig')} style={{textTransform: "uppercase "}}>
								<b> {(dataLottery&&dataLottery[26]) ||''} </b>- 
								<b> {(dataLottery&&dataLottery[27]) ||''} </b>- 
								<b> {(dataLottery&&dataLottery[28]) ||''} </b>- 
								<b> {(dataLottery&&dataLottery[29]) ||''} </b>- 
								<b> {(dataLottery&&dataLottery[30]) ||''} </b>- 
								<b> {(dataLottery&&dataLottery[31]) ||''}</b>
							</span>
						</td>
					</tr>
					<tr className="db">
						<td className="txt-giai">ĐB</td>
						<td className="v-giai number "><span data-nc={5} className={"v-gdb" + (dataLottery&&dataLottery[32] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[32]) ||''}</span></td>
					</tr>
					<tr>
						<td className="txt-giai">Giải 1</td>
						<td className="v-giai number">
							<span data-nc={5} className={"v-g1" + (dataLottery&&dataLottery[0] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[0]) ||''}</span>
						</td>
					</tr>
					<tr className="bg_ef">
						<td className="txt-giai">Giải 2</td>
						<td className="v-giai number">
							<span data-nc={5} className={"v-g2-0" + (dataLottery&&dataLottery[1] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[1]) ||''}</span>
							<span data-nc={5} className={"v-g2-1" + (dataLottery&&dataLottery[2] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[2]) ||''}</span>
						</td>
					</tr>
					<tr>
						<td className="txt-giai">Giải 3</td>
						<td className="v-giai number">
							<span data-nc={5} className={"v-g3-0" + (dataLottery&&dataLottery[3] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[3]) ||''}</span>
							<span data-nc={5} className={"v-g3-1" + (dataLottery&&dataLottery[4] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[4]) ||''}</span>
							<span data-nc={5} className={"v-g3-2" + (dataLottery&&dataLottery[5] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[5]) ||''}</span>
							<span data-nc={5} className={"v-g3-3" + (dataLottery&&dataLottery[6] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[6]) ||''}</span>
							<span data-nc={5} className={"v-g3-4" + (dataLottery&&dataLottery[7] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[7]) ||''}</span>
							<span data-nc={5} className={"v-g3-5" + (dataLottery&&dataLottery[8] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[8]) ||''}</span>
							
						</td>
					</tr>
					<tr className="bg_ef">
						<td className="txt-giai">Giải 4</td>
						<td className="v-giai number">
							<span data-nc={4} className={"v-g4-0" + (dataLottery&&dataLottery[9] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[9]) ||''}</span>
							<span data-nc={4} className={"v-g4-1" + (dataLottery&&dataLottery[10] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[10]) ||''}</span>
							<span data-nc={4} className={"v-g4-2" + (dataLottery&&dataLottery[11] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[11]) ||''}</span>
							<span data-nc={4} className={"v-g4-3" + (dataLottery&&dataLottery[12] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[12]) ||''}</span>
							
						</td>
					</tr>
					<tr>
						<td className="txt-giai">Giải 5</td>
						<td className="v-giai number">
							<span data-nc={4} className={"v-g5-0" + (dataLottery&&dataLottery[13] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[13]) ||''}</span>
							<span data-nc={4} className={"v-g5-1" + (dataLottery&&dataLottery[14] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[14]) ||''}</span>
							<span data-nc={4} className={"v-g5-2" + (dataLottery&&dataLottery[15] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[15]) ||''}</span>
							<span data-nc={4} className={"v-g5-3" + (dataLottery&&dataLottery[16] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[16]) ||''}</span>
							<span data-nc={4} className={"v-g5-4" + (dataLottery&&dataLottery[17] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[17]) ||''}</span>
							<span data-nc={4} className={"v-g5-5" + (dataLottery&&dataLottery[18] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[18]) ||''}</span>
							
						</td>
					</tr>
					<tr className="bg_ef">
						<td className="txt-giai">Giải 6</td>
						<td className="v-giai number">
							<span data-nc={3} className={"v-g6-0" + (dataLottery&&dataLottery[19] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[19]) ||''}</span>
							<span data-nc={3} className={"v-g6-1" + (dataLottery&&dataLottery[20] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[20]) ||''}</span>
							<span data-nc={3} className={"v-g6-2" + (dataLottery&&dataLottery[21] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[21]) ||''}</span>
						</td>
					</tr>
					<tr className="g7">
						<td className="txt-giai">Giải 7</td>
						<td className="v-giai number">
							<span data-nc={2} className={"v-g7-0" + (dataLottery&&dataLottery[22] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[22]) ||''}</span>
							<span data-nc={2} className={"v-g7-1" + (dataLottery&&dataLottery[23] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[23]) ||''}</span>
							<span data-nc={2} className={"v-g7-2" + (dataLottery&&dataLottery[24] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[24]) ||''}</span>
							<span data-nc={2} className={"v-g7-3" + (dataLottery&&dataLottery[25] ? '' : ' imgloadig')}>{(dataLottery&&dataLottery[25]) ||''}</span>
						</td>
					</tr>
				</tbody>
			</table>
		// </div>
	);
}