
import React, { Component } from "react";
import TableLotteryRealTimeContainer from "../containers/TableLotteryRealTimeContainer";






class LotteryRealTime extends Component {
    render () {
        return (
            <TableLotteryRealTimeContainer />
        );
    }
}


export default LotteryRealTime

