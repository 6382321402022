
import React, { Component, useEffect, useState } from "react";
import TableLotteryContainer from "../containers/TableLotteryContainer";
import TableLotteryRealTimeContainer from "../containers/TableLotteryRealTimeContainer";
import { checkHourlyRealTimeLottery } from "../helpers/lotteryRealTime";

// function renderTableLottery(isHourlyRealTimeLotteryInterval) {
//     if(isHourlyRealTimeLotteryInterval) {
//         return <TableLotteryRealTimeContainer />
//     } else {
//         return <TableLotteryContainer />
//     }
// }

const domainId = 1; // Miền Bắc

export default function Home() {
    const [isHourlyRealTimeLotteryInterval, setIsHourlyRealTimeLotteryInterval] = useState(false)
    const [currentTableLottery, setCurrentTableLottery] = useState(()=> {
        return <TableLotteryContainer />
        // renderTableLottery(checkHourlyRealTimeLottery(domainId)) // chậm.
    })

    /**
     * Khi component được gọi, kiểm tra thời gian -> đến thời điểm bắt đầu xổ số thì dừng.
     */
    useEffect(()=>{
        try {
            let interval;
            if(!isHourlyRealTimeLotteryInterval) {
                interval = setInterval(() => {
                    if(checkHourlyRealTimeLottery(domainId)) {
                        setIsHourlyRealTimeLotteryInterval(true)
                    }
                }, 1000)
            } 
            else {
                interval && clearInterval(interval)
            }

            return () => {
                interval && clearInterval(interval)
            };

        } catch (error) {
            console.log(error);
        }
    }, [isHourlyRealTimeLotteryInterval])


    useEffect(() => {
        try {
            // đến giờ xổ số trực tiếp -> gán component xổ số trực tiếp
            if(checkHourlyRealTimeLottery(domainId) && isHourlyRealTimeLotteryInterval) {
                setCurrentTableLottery(<TableLotteryRealTimeContainer />)
            }
        } catch (error) {
            console.log(error);
        }
    }, [isHourlyRealTimeLotteryInterval])
    
    return (
        <>
            {/* <TableLotteryContainer /> */}
            { currentTableLottery }
        </>
    );
}