import * as Types from '../constants/Lottery';
var data = localStorage.getItem('Statistical');
data && (data = JSON.parse(data));


// var initialState = data ? data : [];
var initialState = [];

const Statistical = (state = initialState, action) => {
	switch (action.type) {
		case Types.FETCH_STATISTICAL: {
			return state
		}
		case Types.FETCH_STATISTICAL_SUCCESS: {
			const { data } = action.payload.data;
			state = data
			// localStorage.setItem('Statistical', JSON.stringify(state));
			return state
		}
		case Types.FETCH_STATISTICAL_FAILED: {
			const { error } = action.payload;
			console.log(error)
            state = []
			return state
		}
		default: return state
	}
}

export default Statistical;