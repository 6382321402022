
export const checkHourlyRealTimeLottery = (domain_id) => {
    let result = false;
    const PageToDate = new Date(); // lấy giá trị thời gian tại thời điểm gọi hàm.

    // MB: 18h05 -> 18h35
    if (domain_id == '1' && (PageToDate.getHours() >= 18 && PageToDate.getMinutes() >= 5) && (PageToDate.getHours() <= 18 && PageToDate.getMinutes() <= 35)) {
        result = true
    }

    // MT: 17h10 -> 17h40
    if (domain_id == '2' && (PageToDate.getHours() >= 17 && PageToDate.getMinutes() >= 10) && (PageToDate.getHours() <= 17 && PageToDate.getMinutes() <= 40)) {
        result = true
    }
    // MN: 16h10 -> 16h40
    if (domain_id == '3' && (PageToDate.getHours() >= 16 && PageToDate.getMinutes() >= 10) && (PageToDate.getHours() <= 16 && PageToDate.getMinutes() <= 40)) {
        result = true
    }

    return result;
}