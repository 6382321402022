import React, { useEffect, useState } from "react";
import moment from 'moment'
import DatePicker, { registerLocale } from "react-datepicker";
import { createUrlTo } from "../../helpers/check";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi"; 
import addDays from 'date-fns/addDays'

registerLocale("vi", vi); 
const BreadCrumbs = ({DataLottery, redirectUrl , BreadCrumbsDate }) => {
	let currentDateUpdate
	if(DataLottery[0]){
		currentDateUpdate = {
			days:DataLottery[0].day,
			months:DataLottery[0].month,
			years:DataLottery[0].year,
		}
	}else{
		currentDateUpdate = BreadCrumbsDate
	}
	const currentDate = new Date(currentDateUpdate.years, currentDateUpdate.months-1, currentDateUpdate.days) 
	
	
	const onChange = (date) => {
	
		let options = {
			location_id: BreadCrumbsDate.location_id,
			days: date.getDate(),
			months: date.getMonth()+1,
			years: date.getFullYear(),
			domain_id: BreadCrumbsDate.domain_id,
			pathName:BreadCrumbsDate.pathName
		}
		redirectUrl(options)
	};

	const prevLink = createUrlTo({
		location_id: BreadCrumbsDate.location_id,
		day: Number(currentDateUpdate.day)-1,
		month: currentDateUpdate.month,
		year: currentDateUpdate.year,
		domain_id: BreadCrumbsDate.domain_id,
        pathName:BreadCrumbsDate.pathName
		
	})
	const nextLink = createUrlTo({
		location_id: BreadCrumbsDate.location_id,
		day: Number(currentDateUpdate.day)+1,
		month: currentDateUpdate.month,
		year: currentDateUpdate.year,
		domain_id: BreadCrumbsDate.domain_id,
        pathName:BreadCrumbsDate.pathName
	})
	const weekdays = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy']
	const urlDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
	const toDay = moment(urlDate).format('DD/MM/YYYY') 
	const weekday = weekdays[urlDate.getDay()]
	
	return (
		<div className="box-top">
			<DatePicker
				selected={currentDate}
				onChange={(date) => onChange(date)}
				inline
				locale="vi"
				maxDate={addDays(new Date(), 0)}
			/>
			
			{/* <div className="bg_gray">
				<div className=" opt_date_full clearfix">
					<a href={prevLink}
						className="ic-pre fl" title="KQXSBN ngày 01/09/2021" />
					<label>
						<strong> {weekday} - {toDay} </strong><span className="ic ic-calendar" />
					</label>
					<a href={nextLink}
						className="ic-next" title="KQXSBN ngày 15/09/2021" />
				</div>
			</div> */}
		</div>
	);

}



export default BreadCrumbs;
