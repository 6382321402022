import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

const DatepickerInput = ({ ...props }, ref) => <input type="text" {...props} readOnly />;

export function DatePickerField(props) {
    const {date, setDate} = props;
    // const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <DatePicker 
                // dateFormat="dd/MM/yyyy"
                dateFormat="dd-MM-yyyy"
                maxDate={new Date()}
                selected={date} 
                onChange={(date) => {
                    setDate(date)
                    // setDate(moment(date).format('DD-MM-YYYY')) // set date for form filter params
                }} 
                customInput={React.createElement(React.forwardRef(DatepickerInput))}
            />
        </>
    );
}
