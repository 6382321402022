export function filterLotteryByDigits(arrayData, digitNumber) {
  let DataFilter = [];
  let reg = /[a-zA-Z]/;
  try {
      arrayData.map(e=> {
          let item = e.toString();
          
          if(!reg.test(item)){
              if(item.length > digitNumber) {
                  DataFilter.push(item.substr(item.length-digitNumber, digitNumber));
              } else {
                  DataFilter.push(item);
              }
          } else {
              DataFilter.push(item);
          }
      });
      
  } catch (error) {
      console.log(error);
  }
  return DataFilter;
}

export function filterTableLottery(DataLottery, showDigits) {
  let filterData = [];
  try {
      switch(showDigits){
          case '2': filterData = filterLotteryByDigits(DataLottery, 2);
              break;
          case '3': filterData = filterLotteryByDigits(DataLottery, 3);
              break;
          default: filterData = DataLottery;
              break;
      }
  } catch (error) {
      console.log(error);
  }
  return filterData
}

// for data Lottery from api
export function getLotteryFromHtmlString(htmlString){
  let arrayLottery = [];
  try {
    let span = document.createElement('span');
    span.innerHTML = htmlString;
    

    // madb
    // let stringMadb = document.querySelector('.v-madb');
    // stringMadb = stringMadb.outerText.split(/[-]+/);
    // let madb = [];
    // stringMadb.map(e=>{
    // 	madb.push(e.trim())
    // })
    

    let arrayHTMLLottery = span.querySelectorAll('[data-nc]');

    arrayHTMLLottery && arrayHTMLLottery.forEach(item=>{
      arrayLottery.push(item.outerText)
    })

  } catch (error) {
    console.log(error);
  }
  
  return arrayLottery;
}

// for data Lottery from api
export function setLotteryInnerHtml(arrayLottery, showDigits){
  try {
    // arrayLottery: array string number
    if(arrayLottery && arrayLottery.length > 0) {
      let filterData = filterTableLottery(arrayLottery, showDigits);
      let idArrayHTMLLottery = document.getElementById('load_kq_mb_0');
      let arrayHTMLLottery = idArrayHTMLLottery.querySelectorAll('[data-nc]');

      arrayHTMLLottery.forEach(function(item, index) {
        item.innerHTML = filterData[index]
      })
    }

  } catch (error) {
    console.log(error);
  }
}