import { STATISTICAL_ARRAY, STATISTICAL_OBJECT, POPUP } from "../constants/Statistical";

export function getStatistical(slug, is_popup){
    let statistical;
    try {
        if (is_popup) {
            statistical =  POPUP;
        } else {
            STATISTICAL_ARRAY.some(e=>{
                if(e.slug.indexOf(slug) !== -1) {
                    statistical = e;
                    return true
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
    return statistical;
}