import React, { Component, useEffect, useState } from "react";
import { useToast } from "react-toastify";
import { renderLotteryHTMLToJSX } from "../../../helpers";
import { showStatisticalByFilterNumberSelector } from "../helpers";
import { PROVINCE_OPTIONS } from "../helpers/constans";



export default function Cycle(props) {
    const {Statistical, filterParams, filterNumberData} = props

    const [province, setProvince] = useState(null)

    // set title
    useEffect(()=>{
        if(filterParams) {
            let provinceSelected = PROVINCE_OPTIONS.find(item=>item.id === filterParams.location_id)
            setProvince(provinceSelected)
        }
    },[filterParams])

    useEffect(()=>{
        if(filterNumberData) {
            // update table statistical on filter number
            showStatisticalByFilterNumberSelector(filterNumberData);
        }
    },[filterNumberData])

    
    return (
        <div className="panel panel-default">
            <div className="panel-heading center">
                {/* <h4 className="right-menu-title">Thống kê chu kỳ {province?.name || ''}, tần suất xổ số, loto miền bắc, truyền thống, miền nam</h4> */}
                <h4 className="right-menu-title">Thống kê chu kỳ {province?.name || ''}</h4>
                {/* <h4 className="right-menu-title">{Statistical && Statistical.rows && Statistical.rows[0]?.name || ''}</h4> */}
            </div>

            <div className="panel-body box-statistical-lottery wrap-table-chuky">
                {Statistical && Statistical.rows && renderLotteryHTMLToJSX(Statistical.rows[0]?.content || '')}
            </div>
        </div>
    );
}