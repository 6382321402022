
import qs from 'query-string'
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useLocation } from "react-router-dom"
// import { useHistory  } from "react-router";

import {  
	fetchStatisticalLotteryNearRequest 
} from '../../actions/Statistical'
// import { fetchProvinceRequest } from "../../actions/DataHelpers";
// import { CreateParamsforApi } from "../../helpers";
// import { createFilterFormParams, createUrlTo, createNewFilterParams, findLocationIdFromPathName, filterProvince, createNewFilterParamsLastWeek } from "../../helpers/check";
import LotteryNear from '../../components/statistical/table-statistical/LotteryNear';
import FormFilter from '../../components/statistical/filter/form/FormFilter';
import moment from 'moment';
import { PROVINCE_OPTIONS } from '../../components/statistical/helpers/constans';
import { fetchProvinceRequest } from '../../actions/DataHelpers';


// const toDay = moment(Date.now()).format('DD/MM/YYYY');
const toDayFilter = moment(Date.now()).format('DD-MM-YYYY');

const LotteryNearContainer = ({
	DataProvince,
	GetProvince,
	Statistical,
	ShowStatistical,
	currentStatistical,
}) => {
	// const history = useHistory()
	// const searchQuery = useLocation()


	// const filterParams = { code: "dlt", begin_date: '11-1-2014', end_date: '27-9-2021', day_count: 77};
	const filterParamsInit = { code: PROVINCE_OPTIONS[0].value, begin_date: moment(new Date(2014,1,1)).format('DD-MM-YYYY'), end_date: toDayFilter, day_count: 10 };
	const [filterParams, setFilterParams] = useState(filterParamsInit);

	useEffect(()=>{
		GetProvince()
	},[])

	useEffect(()=>{
		filterParams && ShowStatistical(filterParams)
	},[filterParams])

	return (
		<>
			<div className="panel panel-default">
				<div className="panel-heading center">
					<h4 className="right-menu-title">Thống kê loto Gan</h4>
				</div>
				<div className="panel-body">
					<FormFilter
						filterParams={filterParams} 
						setFilterParams={setFilterParams} 
						currentStatistical={currentStatistical}
					/>
				</div>
			</div>
			<LotteryNear Statistical={Statistical} filterParams={filterParams} />
		</>
	);
}


const mapStateToProp = state => {
	return {
		DataProvince: state.DataProvince,
		Statistical: state.Statistical
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		GetProvince: (params) => {
			dispatch(fetchProvinceRequest(params));
		},
		ShowStatistical:(filterParams) =>{
			dispatch(fetchStatisticalLotteryNearRequest(filterParams))
		}

	}
}

export default connect(mapStateToProp, mapDispatchToProps)(LotteryNearContainer);

