
import React, { Component } from "react";
import TableLotteryTrialContainer from "../containers/TableLotteryTrialContainer";

class LotteryTrial extends Component {
    render () {
        const {match} = this.props
        return (
            <TableLotteryTrialContainer match={match} />
        );
    }
}


export default LotteryTrial

