import React, { Component } from "react";
import TableLoadingMB from "./TableLoadingMB";
import TableLoadingMN from "./TableLoadingMN";
import ReactHtmlParser from 'react-html-parser';
import { LayoutTableLoadingMB, LayoutTableLoadingMN } from "../constants/TableLoadingHTML";


class TableLottery extends Component {
    
	checkValueRow = (data,i) =>{
		let result = ''
		try {
			result = data.LotteryResultItems[i].value
		} catch (error) {
			
		}
		return result 
	}
	checkClassLoading = (data,i) =>{
		let result = 'imgloadig'
		try {
			if(data.LotteryResultItems[i].value)
			result = ''
		} catch (error) {
			
		}
		return result 
	}
	
	showMN = (data) =>{
		let result = <TableLoadingMN />
		if(data.length>0){
			result = (
				<table className="kqmb extendable kqtinh">
					<tbody>
						<tr className="g8">
							<td className="txt-giai">Giải 8</td>
							<td className="v-giai number">
								<span className={`v-g8 ${this.checkClassLoading(data[0],0)}`}>{this.checkValueRow(data[0],0)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 7</td>
							<td className="v-giai number">
								<span  className={`v-g7 ${this.checkClassLoading(data[1],0)}`}>{this.checkValueRow(data[1],0)}</span>
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 6</td>
							<td className="v-giai number">
								<span  className={`v-g6-0 ${this.checkClassLoading(data[2],0)}`}>{this.checkValueRow(data[2],0)}</span>
								<span  className={`v-g6-1 ${this.checkClassLoading(data[2],1)}`}>{this.checkValueRow(data[2],1)}</span>
								<span  className={`v-g6-2 ${this.checkClassLoading(data[2],2)}`}>{this.checkValueRow(data[2],2)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 5</td>
							<td className="v-giai number">
								<span className={`v-g5 ${this.checkClassLoading(data[3],0)}`}>{this.checkValueRow(data[3],0)}</span>
							</td>
						</tr>
						<tr className="g4">
							<td className="titgiai">Giải 4</td>
							<td className="v-giai number">
								<span className={`v-g4-0 ${this.checkClassLoading(data[4],0)}`}>{this.checkValueRow(data[4],0)}</span>
								<span className={`v-g4-1 ${this.checkClassLoading(data[4],1)}`}>{this.checkValueRow(data[4],1)}</span>
								<span className={`v-g4-2 ${this.checkClassLoading(data[4],2)}`}>{this.checkValueRow(data[4],2)}</span>
								<span className={`v-g4-3 ${this.checkClassLoading(data[4],3)}`}>{this.checkValueRow(data[4],3)}</span>
								<span className={`v-g4-4 ${this.checkClassLoading(data[4],4)}`}>{this.checkValueRow(data[4],4)}</span>
								<span className={`v-g4-5 ${this.checkClassLoading(data[4],5)}`}>{this.checkValueRow(data[4],5)}</span>
								<span className={`v-g4-6 ${this.checkClassLoading(data[4],6)}`}>{this.checkValueRow(data[4],6)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 3</td>
							<td className="v-giai number">
								<span className={`v-g3-0 ${this.checkClassLoading(data[5],0)}`}>{this.checkValueRow(data[5],0)}</span>
								<span className={`v-g3-1 ${this.checkClassLoading(data[5],1)}`}>{this.checkValueRow(data[5],1)}</span>
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 2</td>
							<td className="v-giai number">
								<span className={`v-g2 ${this.checkClassLoading(data[6],0)}`}>{this.checkValueRow(data[6],0)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 1</td>
							<td className="v-giai number">
								<span className={`v-g1 ${this.checkClassLoading(data[7],0)}`}>{this.checkValueRow(data[7],0)}</span>
							</td>
						</tr>
						<tr className="gdb db">
							<td className="txt-giai">ĐB</td>
							<td className="v-giai number">
								<span className={`v-gdb ${this.checkClassLoading(data[8],0)}`}>{this.checkValueRow(data[8],0)}</span>
							</td>
						</tr>
					</tbody>
				</table>
			)
		}
		return result 
	}
	showMB = (data) =>{
		let result = <TableLoadingMB />
		if(data.length>0){
			result = (
				<table className="kqmb extendable ">
					<tbody>
						<tr>
							<td colSpan="13" className="v-giai madb">
								<span className={`v-madb ${this.checkClassLoading(data[0],0)}`}>{this.checkValueRow(data[0],0)}</span>
							</td>
						</tr>
						<tr className="gdb db">
							<td className="txt-giai">ĐB</td>
							<td className="v-giai number">
								<span className={`v-gdb ${this.checkClassLoading(data[8],0)}`}>{this.checkValueRow(data[8],0)}</span>
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 1</td>
							<td className="v-giai number">
								<span className={`v-g1 ${this.checkClassLoading(data[1],0)}`}>{this.checkValueRow(data[1],0)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 2</td>
							<td className="v-giai number">
								<span className={`v-g2-0 ${this.checkClassLoading(data[1],0)}`}>{this.checkValueRow(data[2],0)}</span>
								<span className={`v-g2-1 ${this.checkClassLoading(data[2],1)}`}>{this.checkValueRow(data[2],1)}</span>
							</td>
						</tr>
						<tr>
							<td className="txt-giai">Giải 3</td>
							<td className="v-giai number">
								<span className={`v-g3-0 ${this.checkClassLoading(data[3],0)}`}>{this.checkValueRow(data[3],0)}</span>
								<span className={`v-g3-1 ${this.checkClassLoading(data[3],1)}`}>{this.checkValueRow(data[3],1)}</span>
								<span className={`v-g3-2 ${this.checkClassLoading(data[3],2)}`}>{this.checkValueRow(data[3],2)}</span>
								<span className={`v-g3-3 ${this.checkClassLoading(data[3],3)}`}>{this.checkValueRow(data[3],3)}</span>
								<span className={`v-g3-4 ${this.checkClassLoading(data[3],4)}`}>{this.checkValueRow(data[3],4)}</span>
								<span className={`v-g3-5 ${this.checkClassLoading(data[3],5)}`}>{this.checkValueRow(data[3],5)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="titgiai">Giải 4</td>
							<td className="v-giai number">
								<span className={`v-g4-0 ${this.checkClassLoading(data[4],0)}`}>{this.checkValueRow(data[4],0)}</span>
								<span className={`v-g4-1 ${this.checkClassLoading(data[4],1)}`}>{this.checkValueRow(data[4],1)}</span>
								<span className={`v-g4-2 ${this.checkClassLoading(data[4],2)}`}>{this.checkValueRow(data[4],2)}</span>
								<span className={`v-g4-3 ${this.checkClassLoading(data[4],3)}`}>{this.checkValueRow(data[4],3)}</span>
							</td>
						</tr>
						<tr >
							<td className="txt-giai">Giải 5</td>
							<td className="v-giai number">
								<span className={`v-g5-1 ${this.checkClassLoading(data[3],0)}`}>{this.checkValueRow(data[3],0)}</span>
								<span className={`v-g5-2 ${this.checkClassLoading(data[3],1)}`}>{this.checkValueRow(data[3],1)}</span>
								<span className={`v-g5-3 ${this.checkClassLoading(data[3],2)}`}>{this.checkValueRow(data[3],2)}</span>
								<span className={`v-g5-4 ${this.checkClassLoading(data[3],3)}`}>{this.checkValueRow(data[3],3)}</span>
								<span className={`v-g5-5 ${this.checkClassLoading(data[3],4)}`}>{this.checkValueRow(data[3],4)}</span>
								<span className={`v-g5-6 ${this.checkClassLoading(data[3],5)}`}>{this.checkValueRow(data[3],5)}</span>
							</td>
						</tr>
						<tr className="bg_ef">
							<td className="txt-giai">Giải 6</td>
							<td className="v-giai number">
								<span  className={`v-g6-0 ${this.checkClassLoading(data[2],0)}`}>{this.checkValueRow(data[2],0)}</span>
								<span  className={`v-g6-1 ${this.checkClassLoading(data[2],1)}`}>{this.checkValueRow(data[2],1)}</span>
								<span  className={`v-g6-2 ${this.checkClassLoading(data[2],2)}`}>{this.checkValueRow(data[2],2)}</span></td>
						</tr>
						<tr className="g7">
							<td className="txt-giai">Giải 7</td>
							<td className="v-giai number">
								<span  className={`v-g7-0 ${this.checkClassLoading(data[1],0)}`}>{this.checkValueRow(data[1],0)}</span>
								<span  className={`v-g7-1 ${this.checkClassLoading(data[1],1)}`}>{this.checkValueRow(data[1],1)}</span>
								<span  className={`v-g7-2 ${this.checkClassLoading(data[1],2)}`}>{this.checkValueRow(data[1],2)}</span>
								<span  className={`v-g7-3 ${this.checkClassLoading(data[1],3)}`}>{this.checkValueRow(data[1],3)}</span>
							</td>
						</tr>
					</tbody>
				</table>
			)
		}
		
		return result 
	}
	showLottery = (DataLottery,filterParams) =>{
		if(filterParams.domain_id === '1'){
			return this.showMB(DataLottery)
		}else{
			return this.showMN(DataLottery)
		}
	}
	showData(DataLottery,filterParams){
		let result
			if(DataLottery[0]){
				result = DataLottery[0].xoso
			} else{
				if(filterParams.domain_id === 1){
					result = LayoutTableLoadingMB;
				}else{
					result = LayoutTableLoadingMN;
				}
			}
		return {__html: result };
	}
	
	render() {
		const { DataLottery,filterParams} = this.props
		return (
			<>
				{/* {this.showData(DataLottery,filterParams)} */}
				{/* {this.showLottery(DataLottery,filterParams)} */}
				<div dangerouslySetInnerHTML={this.showData(DataLottery,filterParams)}></div>
			</>
		);
	}

}



export default TableLottery;
