export const FETCH_TABLE = "FETCH_TABLE";
export const FETCH_TABLE_SUCCESS = "FETCH_TABLE_SUCCESS";
export const FETCH_TABLE_FAILED = "FETCH_TABLE_FAILED";

export const FETCH_TABLE_REALTIME = "FETCH_TABLE_REALTIME";
export const FETCH_TABLE_REALTIME_SUCCESS = "FETCH_TABLE_REALTIME_SUCCESS";
export const FETCH_TABLE_REALTIME_FAILED = "FETCH_TABLE_REALTIME_FAILED";

export const FETCH_TABLE_START = "FETCH_TABLE_START";
export const FETCH_TABLE_START_SUCCESS = "FETCH_TABLE_START_SUCCESS";
export const FETCH_TABLE_START_FAILED = "FETCH_TABLE_START_FAILED";

export const FETCH_TABLE_END = "FETCH_TABLE_END";
export const FETCH_TABLE_END_SUCCESS = "FETCH_TABLE_END_SUCCESS";
export const FETCH_TABLE_END_FAILED = "FETCH_TABLE_END_FAILED";

export const FETCH_TABLE_REALTIME_START = "FETCH_TABLE_REALTIME_START";
export const FETCH_TABLE_REALTIME_START_SUCCESS = "FETCH_TABLE_REALTIME_START_SUCCESS";
export const FETCH_TABLE_REALTIME_START_FAILED = "FETCH_TABLE_REALTIME_START_FAILED";

export const FETCH_TABLE_REALTIME_END = "FETCH_TABLE_REALTIME_END";
export const FETCH_TABLE_REALTIME_END_SUCCESS = "FETCH_TABLE_REALTIME_END_SUCCESS";
export const FETCH_TABLE_REALTIME_END_FAILED = "FETCH_TABLE_END_FAILED";

export const FETCH_STATISTICAL = "FETCH_STATISTICAL";
export const FETCH_STATISTICAL_SUCCESS = "FETCH_STATISTICAL_SUCCESS";
export const FETCH_STATISTICAL_FAILED = "FETCH_STATISTICAL_FAILED";

export const FETCH_STATISTICAL_DETAIL = "FETCH_STATISTICAL_DETAIL";
export const FETCH_STATISTICAL_DETAIL_SUCCESS = "FETCH_STATISTICAL_DETAIL_SUCCESS";
export const FETCH_STATISTICAL_DETAIL_FAILED = "FETCH_STATISTICAL_DETAIL_FAILED";