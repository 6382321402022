import React, { useEffect, useState } from "react";


export function PopupTableLotteryMB(props) {
	const { StatisticalDetail, dateObj } = props
	const [data, setData] = useState(null)

	useEffect(() => {
		if (StatisticalDetail) {
			setData(StatisticalDetail)
		}
	}, [StatisticalDetail])

	return (
		<>
			<table
				className="table table-condensed kqcenter kqvertimarginw table-kq-border table-kq-hover-div table-bordered kqbackground table-kq-bold-border tb-phoi-border watermark table-striped"
				id="result_tab_mb"
			>
				<thead>
					<tr className="title_row">
						<td className="color333" colSpan={2}>
							<div className="col-sm-10">
								<span className="chu15" id="result_date">
									{dateObj.weekday} ngày {dateObj.date}
								</span>
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Ký tự
						</td>
						<td style={{ padding: 0 }} className="hover">
							<div className="row-no-gutters text-center">
								<div
									id="rs_8_0"
									style={{
										width: "100%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu18 gray need_blank hover"
									rs_len={4}
									data-pattern="[0-9]{1,2}[A-Z]{2}"
									data-sofar="15LS-13LS-14LS"
									data-rolling-special={1}
									data-group-count={6}
								>
									{ (data&&data[8]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16,
								color: "red"
							}}
						>
							Đặc biệt
						</td>
						<td style={{ padding: 0 }}>
							<div className="row-no-gutters text-center">
								<div
									id="rs_0_0"
									style={{
										width: "100%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam phoi-size chu30 maudo stop-reload hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={17948}
								>
									{ (data&&data[0]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải nhất
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_1_0"
									style={{
										width: "100%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={56052}
								>
									{ (data&&data[1]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải nhì
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_2_0"
									style={{
										width: "50%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={'07854'}
								>
									{ (data&&data[2][0]) || '' }
								</div>
								<div
									id="rs_2_1"
									style={{
										width: "50%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={72825}
								>
									{ (data&&data[2][1]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải ba
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_3_0"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={47349}
								>
									{ (data&&data[3][0]) || '' }
								</div>
								<div
									id="rs_3_1"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={79435}
								>
									{ (data&&data[3][1]) || '' }
								</div>
								<div
									id="rs_3_2"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={'09013'}
								>
									{ (data&&data[3][2]) || '' }
								</div>
								<div
									id="rs_3_3"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={'01083'}
								>
									{ (data&&data[3][3]) || '' }
								</div>
								<div
									id="rs_3_4"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={12595}
								>
									{ (data&&data[3][4]) || '' }
								</div>
								<div
									id="rs_3_5"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={5}
									data-pattern="[0-9]{5}"
									// data-sofar={12432}
								>
									{ (data&&data[3][5]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải tư
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_4_0"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={2292}
								>
									{ (data&&data[4][0]) || '' }
								</div>
								<div
									id="rs_4_1"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={6414}
								>
									{ (data&&data[4][1]) || '' }
								</div>
								<div
									id="rs_4_2"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={'0880'}
								>
									{ (data&&data[4][2]) || '' }
								</div>
								<div
									id="rs_4_3"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={9850}
								>
									{ (data&&data[4][3]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải năm
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_5_0"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={6065}
								>
									{ (data&&data[5][0]) || '' }
								</div>
								<div
									id="rs_5_1"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={'0869'}
								>
									{/* 79<span className="chu23 mauxanh">03</span> */}
									{ (data&&data[5][1]) || '' }
								</div>
								<div
									id="rs_5_2"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-bottom hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={2696}
								>
									{ (data&&data[5][2]) || '' }
								</div>
								<div
									id="rs_5_4"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={4301}
								>
									{ (data&&data[5][3]) || '' }
								</div>
								<div
									id="rs_5_3"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={5726}
								>
									{ (data&&data[5][4]) || '' }
								</div>
								<div
									id="rs_5_5"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={4}
									data-pattern="[0-9]{4}"
									// data-sofar={1244}
								>
									{ (data&&data[5][5]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải sáu
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_6_0"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={3}
									data-pattern="[0-9]{3}"
									// data-sofar={354}
								>
									{ (data&&data[6][0]) || '' }
								</div>
								<div
									id="rs_6_1"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={3}
									data-pattern="[0-9]{3}"
									// data-sofar={960}
								>
									{ (data&&data[6][1]) || '' }
								</div>
								<div
									id="rs_6_2"
									style={{
										width: "33.333333333333%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={3}
									data-pattern="[0-9]{3}"
									// data-sofar={277}
								>
									{ (data&&data[6][2]) || '' }
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td
							className="hover"
							style={{
								width: "22%",
								verticalAlign: "middle",
								fontSize: 16
							}}
						>
							Giải bảy
						</td>
						<td style={{ padding: 0 }} >
							<div className="row-no-gutters text-center">
								<div
									id="rs_7_0"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={2}
									data-pattern="[0-9]{2}"
									// data-sofar={16}
								>
									{ (data&&data[7][0]) || '' }
								</div>
								<div
									id="rs_7_1"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={2}
									data-pattern="[0-9]{2}"
									// data-sofar={14}
								>
									{ (data&&data[7][1]) || '' }
								</div>
								<div
									id="rs_7_2"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam border-right hover"
									rs_len={2}
									data-pattern="[0-9]{2}"
									// data-sofar={73}
								>
									{ (data&&data[7][2]) || '' }
								</div>
								<div
									id="rs_7_3"
									style={{
										width: "25%",
										position: "relative",
										float: "left"
									}}
									className="phoi-size chu22 gray need_blank vietdam hover"
									rs_len={2}
									data-pattern="[0-9]{2}"
									// data-sofar={89}
								>
									{ (data&&data[7][3]) || '' }
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
}
