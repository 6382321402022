
// Bảng chọn số Đầu, Đuôi
export const FIRST_END_ARRAY = [0,1,2,3,4,5,6,7,8,9]; // dùng để duyệt Đầu: 0->9, Đuôi: 0->9
export const DATA_CHECKBOX_FIRST_END = () =>{
	let array = [];
	FIRST_END_ARRAY.map(numFirst => {
		FIRST_END_ARRAY.map(numEnd => {
			const indexString = `${numFirst}${numEnd}`;
			const index = parseInt(indexString);

			array.push({ id: index, value: indexString, checked: true, first: numFirst, end: numEnd})
		})
	})
	return array;
}

export const DATA_FILTER_ALL_NUMBER_FIRST_END = () =>{
	let array = [];
	FIRST_END_ARRAY.map(numFirst => {
		FIRST_END_ARRAY.map(numEnd => {
			const indexString = `${numFirst}${numEnd}`;

			array.push(indexString)
		})
	})
	return array;
}


// Bảng chọn cặp số
export const CHAM_ARRAY = [0,1,2,3,4,5,6,7,8,9]; // dùng để duyệt Chạm: 0->9

export const DATA_FILTER_ALL_NUMBER_LOTTERY_PAIR = [
    "00-55",
    "01-10",
    "02-20",
    "03-30",
    "04-40",
    "05-50",
    "06-60",
    "07-70",
    "08-80",
    "09-90",
    "11-66",
    "12-21",
    "13-31",
    "14-41",
    "15-51",
    "16-61",
    "17-71",
    "18-81",
    "19-91",
    "22-77",
    "23-32",
    "24-42",
    "25-52",
    "26-62",
    "27-72",
    "28-82",
    "29-92",
    "33-88",
    "34-43",
    "35-53",
    "36-63",
    "37-73",
    "38-83",
    "39-93",
    "44-99",
    "45-54",
    "46-64",
    "47-74",
    "48-84",
    "49-94",
    "56-65",
    "57-75",
    "58-85",
    "59-95",
    "67-76",
    "68-86",
    "69-96",
    "78-87",
    "79-97",
    "89-98",
]

export const DATA_CHECKBOX_LOTTERY_PAIR = () => {
	let array = [];
	DATA_FILTER_ALL_NUMBER_LOTTERY_PAIR.map((item, index) => {
		array.push({ id: index, value: item, checked: true})
	})
	return array;
}

// Bảng chu kỳ
/**
 * DATA_FILTER_ALL_NUMBER_SET:
 * Type: Array of string
 * Values: ["00", "01", "02", ..., "99"]
 */ 
export const DATA_FILTER_ALL_NUMBER_SET = () => {
	let array = [];
	for(let i = 0; i <= 99; i++) {
        if(i < 10) {
            array.push(`0${i}`)
        } else {
            array.push(`${i}`)
        }
    }
	return array;
}


/**
 * Form filter
 */
export const PROVINCE_OPTIONS = [
    {
        id: 25,
        "value": "mb",
        "name": "Truyền Thống"
    },
    // {
    //     "value": "123",
    //     "name": "Điện Toán 123"
    // },
    // {
    //     "value": "636",
    //     "name": "Điện Toán 6x36"
    // },
    // {
    //     "value": "tt4",
    //     "name": "Thần Tài"
    // },
    {
        id: 16,
        "value": "ag",
        "name": "An Giang"
    },
    {
        id: 10,
        "value": "bl",
        "name": "Bạc Liêu"
    },
    {
        id: 19,
        "value": "bdu",
        "name": "Bình Dương"
    },
    {
        id: 38,
        "value": "bdi",
        "name": "Bình Định"
    },
    {
        id: 4,
        "value": "bp",
        "name": "Bình Phước"
    },
    {
        id: 17,
        "value": "bth",
        "name": "Bình Thuận"
    },
    {
        id: 11,
        "value": "btr",
        "name": "Bến Tre"
    },
    {
        id: 8,
        "value": "cm",
        "name": "Cà Mau"
    },
    {
        id: 13,
        "value": "ct",
        "name": "Cần Thơ"
    },
    {
        id: 22,
        "value": "dlt",
        "name": "Đà Lạt"
    },
    {
        id: 33,
        "value": "dna",
        "name": "Đà Nẵng"
    },
    {
        id: 36,
        "value": "dlc",
        "name": "Đắc Lắc"
    },
    {
        id: 31,
        "value": "dno",
        "name": "Đắc Nông"
    },
    {
        id: 14,
        "value": "dni",
        "name": "Đồng Nai"
    },
    {
        id: 9,
        "value": "dt",
        "name": "Đồng Tháp"
    },
    {
        id: 41,
        "value": "gl",
        "name": "Gia Lai"
    },
    {
        id: 5,
        "value": "hg",
        "name": "Hậu Giang"
    },
    {
        id: 7,
        "value": "hcm",
        "name": "Hồ Chí Minh"
    },
    {
        id: 23,
        "value": "kg",
        "name": "Kiên Giang"
    },
    {
        id: 44,
        "value": "kh",
        "name": "Khánh Hoà"
    },
    {
        id: 43,
        "value": "kt",
        "name": "Kon Tum"
    },
    {
        id: 6,
        "value": "la",
        "name": "Long An"
    },
    {
        id: 42,
        "value": "nt",
        "name": "Ninh Thuận"
    },
    {
        id: 34,
        "value": "py",
        "name": "Phú Yên"
    },
    {
        id: 39,
        "value": "qb",
        "name": "Quảng Bình"
    },
    {
        id: 37,
        "value": "qna",
        "name": "Quảng Nam"
    },
    {
        id: 32,
        "value": "qng",
        "name": "Quảng Ngãi"
    },
    {
        id: 40,
        "value": "qt",
        "name": "Quảng Trị"
    },
    {
        id: 15,
        "value": "st",
        "name": "Sóc Trăng"
    },
    {
        id: 18,
        "value": "tn",
        "name": "Tây Ninh"
    },
    {
        id: 35,
        "value": "tth",
        "name": "Thừa Thiên Huế"
    },
    {
        id: 24,
        "value": "tg",
        "name": "Tiền Giang"
    },
    {
        id: 20,
        "value": "tv",
        "name": "Trà Vinh"
    },
    {
        id: 21,
        "value": "vl",
        "name": "Vĩnh Long"
    },
    {
        id: 12,
        "value": "vt",
        "name": "Vũng Tàu"
    },
    // {
    //     "value": "mg645",
    //     "name": "Vietlott Mega 6/45"
    // },
    // {
    //     "value": "mg4d",
    //     "name": "Vietlott Max 4D"
    // },
    // {
    //     "value": "mg3d",
    //     "name": "Vietlott Max 3D"
    // },
    // {
    //     "value": "mg655",
    //     "name": "Vietlott Power 6/55"
    // },
    // {
    //     "value": "mgkeno",
    //     "name": "Vietlott Keno"
    // }
]